import React, { useCallback, useMemo, useState } from "react";
import { fetch as router, fetchAbsolute as routerAbsolute } from "helpers/router";
import useSegmentTracking from "hooks/useSegmentTracking";
import PropTypes from "prop-types";
import useGraphQLQuery from "hooks/useGraphQLQuery";
import ShareStepModal from "../ShareStepModal";
import PrelaunchModal from "./PrelaunchModal";
import { LaunchModal } from "./LaunchModal";
import GET_CAMPAIGN from "./queries/getOnboardingCompleteCampaignData.gql";
import "../_style.scss";

const url = window.location.href;

const OnboardingCompleteMultiStepModal = ({ campaignId, isOpen }) => {
  const { loading, data } = useGraphQLQuery(GET_CAMPAIGN, { id: campaignId });

  const [currentStep, setCurrentStep] = useState(1);
  const { track } = useSegmentTracking();
  const nextStep = (step) => {
    setCurrentStep(() => step + 1);
  };
  const editorUrl = useMemo(() => router("web.projectEditor", {
    campaign: `${campaignId}`,
    route: "/launch",
  }), [campaignId]);

  const evalStep = useCallback(
    (stepNum) => +currentStep === stepNum,
    [currentStep],
  );
  if (!isOpen || !data || loading) {
    return null;
  }
  const campaign = data.campaign;
  const campaignUrl = routerAbsolute("web.campaign.view", { campaign: campaign.slug });
  const handleClose = () => {
    window.location.href = editorUrl;
    track("onboarding_complete_modal_closed", null, {
      filteredUrl: url,
      step: currentStep,
      source: "onboarding_complete_modal",
    });
  };

  const stepCount = 3;

  return (
    <>
      <PrelaunchModal
        currentStep={currentStep}
        handleClose={handleClose}
        isOpen={evalStep(1)}
        nextStep={nextStep}
        stepCount={stepCount}
      />
      <ShareStepModal
        campaignId={campaign.id}
        campaignUrl={campaignUrl}
        currentStep={currentStep}
        heading="Share your campaign"
        handleClose={handleClose}
        isOpen={evalStep(2)}
        nextStep={() => nextStep(currentStep)}
        stepCount={stepCount}
        trackingSource="onboarding_complete_share_modal"
      />
      <LaunchModal
        campaign={campaign}
        currentStep={currentStep}
        handleClose={handleClose}
        isOpen={evalStep(3)}
        stepCount={stepCount}
      />
    </>
  );
};

OnboardingCompleteMultiStepModal.propTypes = {
  campaignId: PropTypes.number,
  isOpen: PropTypes.bool,
};

export default OnboardingCompleteMultiStepModal;
