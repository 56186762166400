import { useQuery } from '@apollo/client';
import { GET_CAMPAIGN_UPDATES } from 'queries/hooks';
import { useCallback, useState } from 'react';
const useGetCampaignUpdates = ({ campaignId, offSet, limit = 3, }) => {
    const [updates, setUpdates] = useState([]);
    const [metaData, setMetaData] = useState({});
    const orderUpdatesDesc = useCallback((data) => ([...data].sort((a, b) => {
        const dateA = new Date(a.node.createdAt).getTime();
        const dateB = new Date(b.node.createdAt).getTime();
        return dateB - dateA;
    })), []);
    const { loading, error, fetchMore } = useQuery(GET_CAMPAIGN_UPDATES, {
        variables: {
            campaignId,
            first: limit,
            before: offSet !== null && offSet !== void 0 ? offSet : null,
            sort: [
                {
                    field: 'Recent',
                    direction: 'Descending',
                },
            ],
        },
        onCompleted: (data) => {
            setMetaData(data.campaignUpdates);
            setUpdates((prev) => [...prev, ...orderUpdatesDesc(data.campaignUpdates.edges)]);
        },
        onError: (err) => {
            console.error(err);
        },
    });
    return {
        metaData,
        updates,
        fetchMore,
        loading,
        error,
    };
};
export default useGetCampaignUpdates;
