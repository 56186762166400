/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon";

import "../_style.scss";

Icon.propTypes = {
  children: PropTypes.node.isRequired,
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  target: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Icon.defaultProps = {
  fill: "currentColor",
  viewBox: "0 0 32 32",
};

export const IconStateComplete = (props) => (
  <Icon {...props}>
    <g>
      <path d="M50,0c27.61,0,50,22.39,50,50s-22.39,50-50,50S0,77.61,0,50h0C0,22.39,22.39,0,50,0Zm-15.97,43.44l9.5,9.1,21.45-21.69c1.42-1.44,2.3-2.59,4.07-.81l5.7,5.79c1.86,1.84,1.76,2.92,0,4.63l-27.99,27.58c-3.69,3.62-3.05,3.84-6.8,.13l-15.46-15.38c-.81-.81-.69-1.7,.16-2.55l6.57-6.81c.98-1.03,1.77-.98,2.8,0h0Z" />
    </g>
  </Icon>
);
export const IconStateLive = (props) => (
  <Icon {...props}>
    <g>
      <path d="M50,0c27.61,0,50,22.39,50,50s-22.39,50-50,50S0,77.61,0,50,22.39,0,50,0h0ZM27.2,33.71v25.89h5.36v6.46h-14.27V33.71h8.91Zm16.45,0v32.35h-8.91V33.71h8.91Zm24.66,0l-4.51,32.35h-13.47l-5.16-32.35h9.39c1.06,8.92,1.83,16.47,2.31,22.64,.48-6.24,.97-11.77,1.46-16.6l.59-6.03h9.39Zm1.53,0h14.84v6.46h-5.92v6.18h5.54v6.13h-5.54v7.12h6.53v6.46h-15.44V33.71h0Z" />
    </g>
  </Icon>
);
export const IconStateArchived = (props) => (
  <Icon {...props}>
    <g>
      <path d="M50,0c27.61,0,50,22.39,50,50s-22.39,50-50,50S0,77.61,0,50,22.39,0,50,0h0ZM23.87,26.27h52.28c.27,0,.5,.22,.5,.5v12.31c0,.27-.22,.5-.5,.5H23.87c-.27,0-.5-.22-.5-.5v-12.31c0-.27,.22-.5,.5-.5h0Zm2.63,15.95h47.02c.23,0,.42,.19,.42,.42v30.93c0,.23-.19,.42-.42,.42H26.51c-.23,0-.42-.19-.42-.42v-30.93c0-.23,.19-.42,.42-.42h0Zm16.38,5.09h14.25c1.53,0,2.78,1.25,2.78,2.78h0c0,1.53-1.25,2.78-2.78,2.78h-14.25c-1.53,0-2.78-1.25-2.78-2.78h0c0-1.53,1.25-2.78,2.78-2.78h0Z" />
    </g>
  </Icon>
);

export const IconStatePending = (props) => (
  <Icon {...props}>
    <g>
      <path d="M50,0c27.61,0,50,22.39,50,50s-22.39,50-50,50S0,77.61,0,50,22.39,0,50,0h0Zm-5.88,30.64c0-7.67,11.69-7.68,11.69,.02v20.96l13.21,6.99c.07,.03,.13,.07,.19,.12l.11,.08c6.14,4.02,.43,13.68-6.13,9.89l-.02-.02-15.98-8.56c-1.87-1-3.08-2.99-3.08-5.12h0V30.64h0Z" />
    </g>
  </Icon>
);
export const IconStateDraft = (props) => (
  <Icon {...props}>
    <g>
      <path d="M50,0C22.39,0,0,22.39,0,50s22.39,50,50,50,50-22.39,50-50S77.61,0,50,0Zm-8.13,51.77l-2.51,8.51c-.12,.6,.27,1.19,.87,1.32,.14,.02,.29,.04,.43,0l8.33-2.56c.53-.11,1.03-.37,1.4-.76l26.58-27.17c1.07-1.11,1.07-2.87,0-3.97l-3.89-3.97c-1.04-1.07-2.75-1.1-3.83-.06l-.06,.06-26.58,27.16c-.39,.4-.64,.9-.75,1.45Zm33.16-1.38c-1.53,.02-2.76,1.28-2.75,2.81v19.64H28.37V27.96h19.21c1.54-.04,2.78-1.33,2.73-2.88-.04-1.5-1.24-2.69-2.73-2.73H25.62c-1.52,.01-2.75,1.27-2.74,2.8v50.51c-.01,1.53,1.22,2.79,2.75,2.81h49.4c1.53-.02,2.76-1.28,2.75-2.81v-22.44c.01-1.53-1.22-2.79-2.75-2.81Z" />
    </g>
  </Icon>
);
export const IconStateDenied = (props) => (
  <Icon {...props}>
    <g>
      <path d="M50,0C22.39,0,0,22.39,0,50s22.39,50,50,50,50-22.39,50-50S77.61,0,50,0Zm16.09,72.38l-16-15.59-15.59,16-6.89-6.71,15.59-16-16-15.59,6.71-6.89,16,15.59,15.59-16,6.88,6.71-15.59,16,16,15.59-6.71,6.89Z" />
    </g>
  </Icon>
);

export const IconStateRedacted = (props) => (
  <Icon {...props}>
    <g>
      <path d="M50,0C22.39,0,0,22.39,0,50s22.39,50,50,50,50-22.39,50-50S77.61,0,50,0Zm16.09,72.38l-16-15.59-15.59,16-6.89-6.71,15.59-16-16-15.59,6.71-6.89,16,15.59,15.59-16,6.88,6.71-15.59,16,16,15.59-6.71,6.89Z" />
    </g>
  </Icon>
);
