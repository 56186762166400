import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import CommentForm from "components/CommentForm";
import CommentItem from "components/CommentItem";
import CommentList from "components/CommentList";

import "./_style.scss";

import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import {
  createComment as createCommentAction,
  fetchComments as fetchCommentsAction,
  replyComment as replyCommentAction,
  resendEmailLink as resendEmailLinkAction,
  sendEmailLink as sendEmailLinkAction,
  setModalStatus as setModalStatusAction,
  setModalStep as setModalStepAction,
} from "./state";

const Comments = ({
  campaignId,
  campaignTitle,
  commentModalOpen,
  commentModalStep,
  createComment,
  isResendingLink,
  isResendLinkFail,
  isResendLinkSent,
  pendingComment,
  replyComment,
  resendEmailLink,
  sendEmailLink,
  setModalStatus,
  setModalStep,
  campOwner,
  challengerId = null,
  comments = [],
  errorMessage,
  isCreating = false,
  isError = false,
  isLoading = true,
  replyToId = null,
  replyToName = null,
  user = {},
  fetchComments,
}) => {
  useEffect(() => {
    fetchComments(campaignId, challengerId);
  }, [campaignId, challengerId, fetchComments]);

  const { translate: t } = useLanguageProvider();
  return (
    <div className="comments --v2">
      <h2 className="--large">
        {t("Comments")}
        <span className="comments__amount">
          (
          {comments.length}
          )
        </span>
      </h2>

      <div className="comments__form --v2">
        <CommentForm
          campaignId={campaignId}
          campaignTitle={campaignTitle}
          challengerId={challengerId}
          commentModalStep={commentModalStep}
          errorMessage={errorMessage}
          formKey="temp"
          isCommentModalOpen={commentModalOpen}
          isError={isError}
          isPosting={(replyToId !== null) ? false : isCreating}
          resendEmailAction={resendEmailLink}
          sendEmailAction={sendEmailLink}
          setModalStatusAction={setModalStatus}
          setModalStepAction={setModalStep}
          submitAction={createComment}
          user={user}
        />
      </div>
      {(isLoading === true)
          && (
          <CommentItem
            commentText="Loading..."
            id={0}
            isDisabled
            isLoading
            name="Loading"
            timestamp={(new Date()).toISOString()}
          />
          )}
      {(isLoading !== true && pendingComment !== null)
          && (
          <CommentItem
            campaignId={campaignId}
            commentText={pendingComment.content}
            id={pendingComment.id}
            isPending
            isResendingLink={isResendingLink}
            isResendLinkFail={isResendLinkFail}
            isResendLinkSent={isResendLinkSent}
            name="Pending Comment"
            resendEmailAction={resendEmailLink}
            timestamp={pendingComment.created_at}
          />
          )}
      {(isLoading !== true)
          && (
          <CommentList
            campaignId={campaignId}
            campaignTitle={campaignTitle}
            challengerId={challengerId}
            commentModalStep={commentModalStep}
            comments={comments}
            isCommentModalOpen={commentModalOpen}
            isCreating={isCreating}
            replyAction={replyComment}
            replyToId={replyToId}
            replyToName={replyToName}
            resendEmailAction={resendEmailLink}
            sendEmailAction={sendEmailLink}
            setModalStatusAction={setModalStatus}
            setModalStepAction={setModalStep}
            submitAction={createComment}
            user={user}
            v2
            campOwner={campOwner}
          />
          )}
    </div>
  );
};

Comments.propTypes = {
  campaignId: PropTypes.number.isRequired,
  campaignTitle: PropTypes.string.isRequired,
  challengerId: PropTypes.number,
  commentModalOpen: PropTypes.bool.isRequired,
  commentModalStep: PropTypes.oneOf(["default", "signup", "login", "existing", "thankyou"]),
  comments: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  isCreating: PropTypes.bool,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  pendingComment: PropTypes.object,
  replyToId: PropTypes.number,
  replyToName: PropTypes.string,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  commentModalOpen: state.comments.commentModalOpen,
  commentModalStep: state.comments.commentModalStep,
  comments: state.comments.comments,
  errorMessage: state.comments.errorMessage,
  isCreating: state.comments.isCreating,
  isError: state.comments.isError,
  isLoading: state.comments.isLoading,
  isResendingLink: state.comments.isResendingLink,
  isResendLinkFail: state.comments.isResendLinkFail,
  isResendLinkSent: state.comments.isResendLinkSent,
  pendingComment: state.comments.pendingComment,
  replyToId: state.comments.replyToId,
  replyToName: state.comments.replyToName,
});

const mapDispatchToProps = (dispatch) => ({
  fetchComments: (campaignId, challengerId) => dispatch(fetchCommentsAction(campaignId, challengerId)),
  createComment: (data) => dispatch(createCommentAction(data)),
  resendEmailLink: (data) => dispatch(resendEmailLinkAction(data)),
  replyComment: (replyToId, replyToName) => dispatch(replyCommentAction(replyToId, replyToName)),
  sendEmailLink: (data) => dispatch(sendEmailLinkAction(data)),
  setModalStatus: (isModalOpen) => dispatch(setModalStatusAction(isModalOpen)),
  setModalStep: (stepName) => dispatch(setModalStepAction(stepName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
