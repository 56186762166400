import React from "react";
import LinkButton from "components/LinkButton";
import Modal from "components/Modal";
import ModalClose from "components/ModalClose";
import ModalContent from "components/ModalContent";
import { modalStyleProp } from "helpers/modalStyles";
import NumberedSteps from "components/NumberedSteps";
import { fetch as router } from "helpers/router";
import PropTypes from "prop-types";
import ShareButtons from "components/ShareButtons";
import "./_style.scss";

const ShareStepModal = ({
  heading,
  isOpen,
  nextStep,
  handleClose,
  heroDescription,
  campaignId,
  campaignUrl,
  currentStep,
  stepCount,
  trackingSource,
}) => {
  const isFinalStep = stepCount === currentStep;
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Sharing"
      customStyle={modalStyleProp()}
      data-test="share-modal"
    >
      <ModalClose closeAction={handleClose} />
      <ModalContent
        body={(
          <>
            {stepCount && (
            <NumberedSteps
              orientation="landscape"
              stepCount={stepCount}
              currentStep={currentStep}
            />
            )}
            <div className="campaign-container__modal-share">
              {heading && <h1>{heading}</h1>}
              <h4>
                Use the sharing options below to spread the word about your
                campaign!
              </h4>
              <ShareButtons
                url={campaignUrl}
                campaignId={campaignId}
                heading=""
                round={false}
                quote={heroDescription}
                emailShareText="Email"
                emailShareSubject="Have you heard about this?"
                emailShareBody="Hi! I thought you might be interested in this campaign -"
                messengerShareText="Messenger"
                whatsappShareText="WhatsApp"
                facebookShareText="Facebook Post"
                twitterShareText="Twitter"
                copyText="Copy Url"
                showHTMLEmbed
                trackingEvent="campaign_shared"
                trackingSource={trackingSource}
                type="full-width"
              />
            </div>
          </>
      )}
        action={
          isFinalStep ? <DoneButton /> : (
            <LinkButton href="#" look="primary" isBold onClick={() => nextStep()}>
              Next
            </LinkButton>
          )
}
      />
    </Modal>
  );
};

const DoneButton = () => (
  <LinkButton href={router("web.dashboard.campaigns")} look="primary" isBold>
    Done
  </LinkButton>
);

ShareStepModal.propTypes = {
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  nextStep: PropTypes.func,
  handleClose: PropTypes.func,
  heroDescription: PropTypes.string,
  campaignUrl: PropTypes.string,
  campaignId: PropTypes.string,
  currentStep: PropTypes.number,
  stepCount: PropTypes.number,
  trackingSource: PropTypes.string,
};

export default ShareStepModal;
