import { useMemo, useState } from "react";
import fetchShares from "../helpers";

const useGetShareCount = (campaignId) => {
  const [shareCount, setShareCount] = useState(0);
  useMemo(
    () =>
      fetchShares(campaignId).then(
        (value) => {
          if (value?.engagement?.share_count) {
            setShareCount(value.engagement.share_count);
          }
        },
        (reason) => {
          console.error(reason);
        },
      ),
    [campaignId],
  );

  return shareCount;
};

export default useGetShareCount;
