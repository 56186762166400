import { useQuery } from "@apollo/client";

const useGraphQLQuery = (query, variables) => {
  const {
    loading, error, data, refetch,
  } = useQuery(query, {
    variables: { ...variables },
  });
  return {
    loading,
    error,
    data,
    refetch,
  };
};
export default useGraphQLQuery;
