import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_style.scss';
import { getIconAriaData, getIconDimensions } from 'helpers/icon';
const Icon = ({ children, className, dataTestId, fill, fillRule, height, label, viewBox, width, }) => {
    const aria = getIconAriaData(label);
    const dimensions = getIconDimensions(height, width);
    return (_jsx("svg", { className: classNames('icon', className), fill: fill, fillRule: fillRule, height: dimensions.height, preserveAspectRatio: "xMidYMid meet", viewBox: viewBox, width: dimensions.width, ...aria, "data-testid": dataTestId, children: children }));
};
Icon.propTypes = {
    children: PropTypes.node.isRequired,
    fill: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    target: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Icon.defaultProps = {
    fill: 'currentColor',
    viewBox: '0 0 32 32',
};
export default Icon;
