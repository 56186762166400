import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import Button from '../Button';
import './_style.scss';
const ViewMore = ({ display, onClick, expandText, ariaExpanded, sluggedText = 'view-more', children, }) => (_jsxs("div", { className: classNames('view-more', 'view-more-container', '--top', {
        '--isDisplay': display,
    }), children: [children, _jsx("div", { className: classNames('view-more__button', {
                '--isDisplay': display,
            }), "data-testid": "view-more-wrapper", children: _jsx(Button, { onClick: onClick, "aria-expanded": ariaExpanded, className: "link-button", look: "normal", isRounded: true, "data-testid": `${sluggedText}-button`, children: expandText !== null && expandText !== void 0 ? expandText : 'View more' }) })] }));
export default ViewMore;
