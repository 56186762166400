import PropTypes from "prop-types";
import React from "react";

import moment from "moment";
import classNames from "classnames";
import useCampaignStatusUpdater from "hooks/useCampaignStatusIconUpdater";
import {
  IconGreyArrow,
} from "../Icon";
import "./_style.scss";

const campaignStatusInformation = [
  {
    name: "archived",
    text: "Campaign is hidden from public view",
    displayDate: false,
  },
  {
    name: "completed",
    text: "Campaign completed",
    displayDate: true,
  },
  {
    name: "redacted",
    text: "Campaign redacted",
    displayDate: true,
  },
  {
    name: "approved",
    text: "Campaign is ready to launch",
    displayDate: false,
  },
  {
    name: "denied",
    text: "Campaign has been denied",
    displayDate: false,
  },
  {
    name: "draft",
    text: "Campaign is in draft mode",
    displayDate: false,
  },
  {
    name: "pending-approval",
    text: "Campaign is pending approval",
    displayDate: false,
  },
  {
    name: "payment-issues",
    text: "Campaign has payment issues",
    displayDate: false,
  },
];

const CampaignStatus = (props) => {
  const {
    isAdmin,
    campaignId,
    campaignStatus,
    finishDate,
  } = props;

  const campaignStatusToLower = props.campaignStatus.toLowerCase();
  const statusDisplay = campaignStatusInformation.filter((i) => i.name === campaignStatusToLower)[0];
  const { campaignStatusIcon } = useCampaignStatusUpdater(campaignStatus);

  if (campaignStatusToLower === "live") {
    return null;
  }
  if (!statusDisplay) {
    console.error(`couldn't get status component for : ${campaignStatus}`);
    return null;
  }
  return (
    <div className="campaign-container__status">
      <div className={classNames("campaign-status", `--${campaignStatus?.toLowerCase()}`)} data-test="campaign-status">
        <div>
          {campaignStatusIcon && (
          <span className="campaign-status__status-icon">
            {campaignStatusIcon}
          </span>
          )}
        </div>
        <div>
          <p className="--notification">{statusDisplay.text}</p>
          {statusDisplay.displayDate && <p>{moment(finishDate).utc().format("YYYY-MM-DD")}</p>}
          {isAdmin && (statusDisplay.name === "approved-notlaunched" ? (
            <a className="campaign-status__link --regular" href={`/edit/project/${campaignId}/launch`}>
              Launch campaign
              {" "}
              <IconGreyArrow />
            </a>
          ) : (
            <a className="campaign-status__link --regular" href={`/edit/project/${campaignId}`}>
              Edit campaign
              {" "}
              <IconGreyArrow />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

CampaignStatus.propTypes = {
  campaignId: PropTypes.number,
  status: PropTypes.string,
  finishDate: PropTypes.string,
};

export default CampaignStatus;
