import { campaignEndTimeDisplay } from "helpers/campaignEndTimeDisplay";
import DaysRemaining from "pages/campaign/components/DaysRemaining";
import React from "react";
import PropTypes from "prop-types";
import differenceDateTime from "helpers/dateTime";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const ShareBar = ({
  totalSupporters, campaign, shareCount, shareButton,
}) => {
  const { translate: t, translateChoice: tChoice } = useLanguageProvider();
  const { dateString } = differenceDateTime({ targetDate: campaign.duration.finishAt });

  return (
    <div className="campaign-container__share-button-container campaign-container__separator">
      <div className="campaign-container__share-values-container">
        {totalSupporters > 0 && (
        <div className="campaign-container__share-values">
          <h3>{totalSupporters}</h3>
          <p className="campaign-container__share-values-subtitle --small">
            {tChoice("Supporter|Supporters", totalSupporters)}
          </p>
        </div>
        )}
        {shareCount > 0 && (
        <div
          className="campaign-container__share-values"
          data-test="campaign-stats-share-count"
        >
          <h3>{shareCount}</h3>
          <p className="campaign-container__share-values-subtitle --small">
            {t("Shares")}
          </p>
        </div>
        )}
        {!campaign.duration.isInfinityMode && dateString !== null && campaignEndTimeDisplay(
          campaign.duration.isInfinityMode,
          campaign.status,
          t(dateString),
        )
        && <DaysRemaining date={campaign.duration.finishAt} />}
      </div>
      {shareButton}
    </div>
  );
};

ShareBar.propTypes = {
  totalSupporters: PropTypes.number.isRequired,
  campaign: PropTypes.object.isRequired,
  shareCount: PropTypes.number.isRequired,
};

export default ShareBar;
