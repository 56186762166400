const prepareBody = (method, body) => {
    if (method === 'DELETE') {
        return JSON.stringify({ _method: 'DELETE' });
    }
    return body ? JSON.stringify(body) : undefined;
};
const request = (url, method = 'GET', headers = undefined, body = undefined) => new Request(url, {
    method,
    credentials: 'include',
    redirect: 'follow',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
    },
    body: prepareBody(method, body),
});
export default request;
