import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm, } from 'react-hook-form';
import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
import loginSchema, { signupSchema } from './validation';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import './_style.scss';
const CommentModalAuthenticate = ({ commentText, createComment, isPosting, step, campaignId, challengerId, parentCommentId, }) => {
    const { translate } = useLanguageProvider();
    const validationSchemas = {
        signup: signupSchema,
        login: loginSchema,
    };
    const methods = useForm({
        resolver: yupResolver(validationSchemas[step]),
    });
    const { handleSubmit } = methods;
    const onSubmit = useCallback((data) => {
        const formData = {
            ...data,
            campaignId,
            challengerId,
            content: commentText,
            replyToId: parentCommentId,
            step,
        };
        createComment(formData);
    }, [campaignId, challengerId, commentText, createComment, parentCommentId, step]);
    const formComponents = {
        signup: SignupForm,
        login: LoginForm,
    };
    const FormComponent = formComponents[step];
    return (_jsx(FormProvider, { ...methods, children: _jsx(FormComponent, { onSubmit: handleSubmit(onSubmit), translate: translate, isPosting: isPosting }) }));
};
export default CommentModalAuthenticate;
