import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
import ViewMore from 'components/ViewMore/ViewMore';
import CampaignUpdateTimeline from 'pages/campaign/components/CampaignUpdates/components/CampaignUpdateTimeline/CampaignUpdateTimeline';
const CampaignUpdates = ({ totalUpdates, updates, fetchMore, loading, hasNextPage, }) => {
    const { translate: t, translateChoice: tChoice } = useLanguageProvider();
    if (loading) {
        return _jsx("div", { children: "Loading..." });
    }
    if (updates.length === 0 || totalUpdates === 0) {
        return null;
    }
    return (_jsx(ViewMore
    // eslint-disable-next-line react/no-children-prop
    , { 
        // eslint-disable-next-line react/no-children-prop
        children: (_jsx("div", { className: "campaign-container__story-container", children: _jsxs("div", { className: "campaign-container__updates-container", "data-testid": "campaign-updates", children: [_jsxs("h2", { className: "campaign-container__updates-container-title --large", children: [tChoice('Update|Updates', totalUpdates), _jsxs("span", { className: "campaign-container__updates-container-total", "data-test": "campaign-page-updates-container", children: ["(", totalUpdates, ")"] })] }), _jsx(CampaignUpdateTimeline, { updates: updates })] }) })), expandText: t('View more updates'), onClick: fetchMore, display: hasNextPage }));
};
export default CampaignUpdates;
