import PropTypes from "prop-types";
import React from "react";

import classNames from "classnames";
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";

import "./_style.scss";

const LoadingIndicator = ({ size = "25px", ...other }) => (
  <span
    data-test="loading-indicator"
    className={
      classNames(
        "loading-indicator",
        other.className,
      )
    }
    style={{
      ...other.style,
      width: size,
      height: size,
    }}
  >
    <FaSpinner
      width={size}
      height={size}
      color={other.style && other.style.color}
    />
  </span>
);

LoadingIndicator.propTypes = {
  size: PropTypes.string,
};

export default LoadingIndicator;
