import { createContext } from 'react';
import stringInterpolator from './utils/stringInterpolator';
import pluralization from './utils/pluralization';
import getCookie from './utils/cookies';
const locale = getCookie('locale') || 'en';
const Context = createContext({
    getLocale: () => '',
    setLocale: () => '',
    translate: (text, replacements) => stringInterpolator(text, replacements),
    translateChoice: (text, count, replacements) => stringInterpolator(pluralization(text, count, locale), {
        ...replacements,
        count: count.toString(),
    }),
});
export default Context;
