import { trimEnd } from "lodash";
import routes from "./routes.json";

const replaceParam = (uri, paramName, param) => uri.replace(paramName, param);

export const isUrl = (val) => {
  const matches = val.match(/^(?:[a-z+]+:)?/);
  return matches[0] !== "";
};

export const resolveRoute = async (uri) => {
  let hostname = "";
  let pathname = "";
  let pathParts = "";

  if (isUrl(uri)) {
    const url = new URL(uri);
    hostname = url.hostname;
    pathname = url.pathname;
    pathParts = pathname.split("/");
  } else {
    pathParts = uri.split("/");
  }

  if (pathParts[0] === "") {
    pathParts.shift();
  }

  const filteredUri = await getFilteredUri(pathParts);
  if (!filteredUri.uri) {
    return;
  }

  const name = Object.keys(routes).find((key) => routes[key] === filteredUri);
  return {
    uri: filteredUri.uri,
    name,
    hostname,
    pathname,
    params: getParams(filteredUri.uri, pathParts),
  };
};

export const getFilteredUri = async (pathParts) => {
  const routeMatches = Object.values(routes).filter((element) => element.uri.includes(pathParts[0]));
  if (routeMatches.length <= 1) {
    return routeMatches;
  }
  return filterRoutes(routeMatches, pathParts);
};

const filterRoutes = (routeMatches, pathParts) =>
  new Promise((resolve) => {
    let iteration = 0;
    let matches = 0;
    let results = resolveUriParts(routeMatches, pathParts, iteration);
    let tempResults = [...results];

    while (results.length > 1) {
      results = resolveUriParts(results, pathParts, iteration);
      if (matches > 0 && results.length === 0) {
        // results are empty due to unmatched pathPart ( i.e. {param} )
        const unMatchedParameter = pathParts[iteration];
        pathParts = pathParts.filter((item) => item !== unMatchedParameter);
        results = [...tempResults];
      } else {
        tempResults = [...results];
        iteration++;
      }
      matches = results.length;
    }

    if (results.length === 1) {
      resolve(results[0]);
    }
  });

const resolveUriParts = (routeMatches, pathParts, iteration = 0) => {
  if (pathParts.length === iteration + 1) {
    const result = routeMatches.filter((item) => pathParts.every((part) => item.uri.includes(part)));
    if (result.length === 1) {
      return result;
    }

    const reducer = result.filter((item) => item.uri === pathParts.join("/"));
    if (reducer.length === 1) {
      return reducer;
    }
  }

  let searchParams = "";
  for (let i = 0; i <= iteration; i++) {
    searchParams += pathParts[i] + (i !== pathParts.length ? "/" : "");
  }
  return Object.values(routeMatches).filter((element) => element.uri.includes(searchParams));
};

const getParams = (uri, pathParts) => {
  const params = {};
  const uriParts = uri.split("/");
  const regex = /\{([a-zA-Z_]+)(\??)\}/g;
  const urlExpectedParams = uri.match(regex);

  if (!urlExpectedParams) {
    return params;
  }
  urlExpectedParams.forEach((element) => {
    const index = uriParts.indexOf(element);
    params[element.replace(/[{\}]/g, "")] = pathParts[index];
  });

  return params;
};

export const fetch = (name, params) => {
  if (routes.hasOwnProperty(name)) {
    return uriParams(`/${routes[name].uri}`, params);
  }
  throw `Unknown route ${name}`;
};

export const fetchAbsolute = (name, params) => {
  if (params) {
    return window.location.origin + fetch(name, params);
  }

  return window.location.origin + fetch(name);
};

export const uriParams = (uri, params) => {
  const baseUri = uri;
  params = params ?? {};
  const regex = /\{([a-zA-Z_]+)(\??)\}/g;
  const urlExpectedParams = uri.match(regex);

  if (urlExpectedParams !== null && urlExpectedParams !== undefined) {
    urlExpectedParams.forEach((paramName) => {
      const isOptional = paramName.indexOf("?") > 0;
      const filteredName = paramName.slice(1, isOptional ? -2 : -1);

      if (params[filteredName] === undefined && !isOptional) {
        throw `Missing param: ${filteredName} in ${baseUri}`;
      }
      uri = replaceParam(uri, paramName, params[filteredName] ?? "");
    });
  }

  const finalUri = trimEnd(uri, "/");
  if (!finalUri) {
    return "/";
  }

  return finalUri;
};
