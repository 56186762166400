import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Modal from 'components/Modal';
import ModalClose from 'components/ModalClose';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import './_style.scss';
const ImageAsset = ({ images }) => {
    const [modalOpen, setModalOpen] = React.useState(false);
    const modalStyles = {
        content: {
            background: 'none',
            maxWidth: '900px',
            overflow: 'auto',
            textAlign: 'left',
            width: '95%',
        },
        overlay: {
            backgroundColor: 'rgba(51,51,51,.8)',
            overflowY: 'auto',
            transform: 'translate3d(0, 0, 0)',
            zIndex: 210,
        },
    };
    const onClose = () => {
        setModalOpen(false);
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "img-gallery-thumbs", "data-testid": "image-asset-thumbs", children: images.map((image) => (_jsx("button", { type: "button", className: "img-gallery-thumb", onClick: toggleModal, children: _jsx("img", { src: image.publicUrl, alt: "campaign update" }, image.publicUrl) }, image.publicUrl))) }), _jsx(Modal, { isOpen: modalOpen, contentLabel: "Update Images", customStyle: modalStyles, children: _jsxs("div", { children: [_jsx(ModalClose, { closeAction: onClose, display: "--secondary" }), _jsx(Carousel, { showArrows: true, showIndicators: false, showThumbs: true, useKeyboardArrows: false, selectedItem: 0, infiniteLoop: true, children: images.map((image) => (_jsx("img", { src: image.publicUrl, alt: "campaign update" }, image.publicUrl))) })] }) })] }));
};
export default ImageAsset;
