import differenceDateTime from "helpers/dateTime";
import React from "react";
import PropTypes from "prop-types";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const DaysRemaining = ({ date }) => {
  const { translate: t } = useLanguageProvider();
  const { dateUnit, dateCount } = differenceDateTime({ targetDate: date });
  return (
    <div className="campaign-container__share-values" data-test="campaign-days-left">
      <h3>{dateCount}</h3>
      <p data-test="duration-type" className="campaign-container__share-values-subtitle --small">
        {t(":unit left", { unit: t(dateUnit) })}
      </p>
    </div>
  );
};

DaysRemaining.propTypes = {
  date: PropTypes.any.isRequired,
};

export default DaysRemaining;
