import { useEffect, useMemo, useState } from 'react';
import * as enData from 'lang/en.json';
import * as frData from 'lang/fr.json';
import * as deData from 'lang/de.json';
import * as itData from 'lang/it.json';
export function mapTranslationFiles(locales, translations) {
    Object.keys(locales).forEach((locale) => {
        translations.set(locale, {
            ...(translations.get(locale) || {}),
            ...locales[locale],
        });
    });
    return translations;
}
const useGetTranslationFiles = () => {
    const [filesLoading, setFilesLoading] = useState(true);
    const translations = useMemo(() => new Map(), []);
    const locales = {
        en: enData,
        de: deData,
        fr: frData,
        it: itData,
    };
    mapTranslationFiles(locales, translations);
    useEffect(() => {
        if (translations.has('en')) {
            setFilesLoading(false);
        }
    }, [setFilesLoading, translations]);
    return {
        translations,
        filesLoading,
    };
};
export default useGetTranslationFiles;
