import React from "react";
import PropTypes from "prop-types";
import Tooltip from "react-tooltip";
import classNames from "classnames";
import reactCSS from "reactcss";

import LoadingIndicator from "../LoadingIndicator";

import "./_style.scss";

const Button = ({
  isDisabled = false,
  isIcon = false,
  isLoading = false,
  isSelected = false,
  isRounded,
  loadingMessage = "Loading",
  children,
  onClick,
  type = "button",
  look,
  showTooltip = false,
  errorMessage = "",
  ...other
}) => {
  const style = reactCSS({
    default: {
      loadingIndicator: {
        marginLeft: ".5em",
        color: "currentColor",
      },
    },
    small: {
      loadingIndicator: {
        marginLeft: "0",
        color: "#1FA8DF",
      },
    },
  }, look);

  const triggerClick = (e) => {
    if (isDisabled) {
      e.preventDefault();
      return;
    }
    if (typeof onClick === "function") {
      onClick(e);
    }
  };

  return (
    <>
      <button
        {...other}
        type={type}
        onClick={triggerClick}
        className={
          classNames(
            "button",
            other.className,
            {
              "--disabled": isDisabled,
              "--icon": isIcon,
              "--loading": isLoading,
              "--selected": isSelected,
              "--rounded": isRounded,
            },
            // support combinations e.g.: look="secondary small"
            look ? look.split(" ").map((l) => `--${l}`) : undefined,
          )
        }
        data-tip
        data-multiline
        data-for={errorMessage ? "buttonErrorMessage" : ""}
      >
        {
          isLoading
            ? (
              <span>
                {loadingMessage}
                <LoadingIndicator
                  style={style.loadingIndicator}
                  size={look === "small" ? "18px" : "25px"}
                  className="button__loading-indicator"
                />
              </span>
            )
            : children
        }
      </button>
      {
        showTooltip && errorMessage
          ? (
            <Tooltip id="buttonErrorMessage" type="info">
              {errorMessage}
            </Tooltip>
          )
          : null
      }
    </>
  );
};

Button.propTypes = {
  isDisabled: PropTypes.bool,
  isIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSelected: PropTypes.bool,
  isRounded: PropTypes.bool,
  loadingMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  look: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showTooltip: PropTypes.bool,
};

export default Button;
