import { gql } from '@apollo/client';
import getCampaignBasics from './getCampaignBasics.gql';
import getFundraiser from './getFundraiser.gql';
import getAuthUser from './userAuth.gql';
import getCampaignUpdates from './getCampaignUpdates.gql';
const GET_AUTH_USER = gql `${getAuthUser}`;
export default GET_AUTH_USER;
export const GET_CAMPAIGN_BASICS = gql `${getCampaignBasics}`;
export const GET_FUNDRAISER = gql `${getFundraiser}`;
export const GET_CAMPAIGN_UPDATES = gql `${getCampaignUpdates}`;
