import { useLazyQuery } from '@apollo/client';
import GET_CAMPAIGN_SHARE_URL from 'queries/getCampaignShareUrl.gql';
import GET_FUNDRAISER_SHARE_URL from 'queries/getFundraiserShareUrl.gql';
import { useCallback, useState } from 'react';
const useGetShareContext = () => {
    const [loading, setLoading] = useState(true);
    const [campaignQueryResult] = useLazyQuery(GET_CAMPAIGN_SHARE_URL);
    const [fundraiserQueryResult] = useLazyQuery(GET_FUNDRAISER_SHARE_URL);
    const executeQuery = useCallback(async (type, context) => {
        const variables = {
            id: +context.id,
            legacy: context === null || context === void 0 ? void 0 : context.legacy,
            socialPlatform: context.socialPlatform,
            source: context.source,
        };
        if (type === 'campaign') {
            return campaignQueryResult({ variables });
        }
        return fundraiserQueryResult({ variables });
    }, [campaignQueryResult, fundraiserQueryResult]);
    const getShareUrl = useCallback(async (props) => {
        var _a, _b, _c, _d, _e;
        setLoading(true);
        const { type, context } = props;
        try {
            const result = await executeQuery(type, context);
            if (result.data) {
                const baseUrl = (_b = (_a = result.data.campaign) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : (_c = result.data.fundraiser) === null || _c === void 0 ? void 0 : _c.url;
                const params = new URLSearchParams({
                    utm_campaign: ((_d = context.source) === null || _d === void 0 ? void 0 : _d.toLowerCase()) || '',
                    utm_id: context.id.toString(),
                    utm_medium: ((_e = context.socialPlatform) === null || _e === void 0 ? void 0 : _e.toLowerCase()) || '',
                    utm_source: 'chuffedreferral',
                });
                return `${baseUrl}${params.toString() ? `?${params}` : ''}`;
            }
        }
        catch (error) {
            console.error('Error fetching share URL:', error);
            return null; // Explicitly return null on error
        }
        setLoading(false);
        return '';
    }, [executeQuery]);
    return {
        getShareUrl,
        loading,
    };
};
export default useGetShareContext;
