import PropTypes from "prop-types";
import React from "react";
import Toggle from "react-toggle";

import classNames from "classnames";
import InfoHelper from "../InfoHelper";

import "react-toggle/style.css";
import "./_style.scss";

const Checkbox = ({
  onChange,
  checked,
  checkedLabel,
  dataTestId,
  infoText,
  isNoTransition,
  isToggleOnly,
  labelAlign,
  labelSize,
  name,
  uncheckedLabel,
  spacing,
  style,
  className,
  disabled = false,
}) => (
  <div
    data-test="checkbox"
    data-testid={dataTestId ?? "checkbox"}
    className={
    classNames(
      className,
      "Checkbox",
      {
        "--toggle-only": isToggleOnly,
        [`--align-${labelAlign}`]: labelAlign,
        [`--label-${labelSize}`]: labelSize,
        [`--spacing-${spacing}`]: spacing,
        "--no-transition": isNoTransition,
      },
    )
  }
    style={style}
  >
    <label>
      <Toggle disabled={disabled} checked={checked} onChange={onChange} name={name} />
      <span>{checked ? checkedLabel : uncheckedLabel}</span>
      {
        infoText
          ? (
            <InfoHelper
              className="Checkbox__info-helper"
              name={checkedLabel.replace(" ", "")}
            >
              {infoText}
            </InfoHelper>
          )
          : null
      }
    </label>
  </div>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  checkedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  infoText: PropTypes.string,
  isInvalid: PropTypes.bool,
  isNoTransition: PropTypes.bool,
  isToggleOnly: PropTypes.bool,
  labelAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
  labelSize: PropTypes.oneOf(["small", "regular", "large"]),
  onChange: PropTypes.func,
  uncheckedLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default Checkbox;
