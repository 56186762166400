/* eslint "import/no-import-module-exports": "off" */
import {
  applyMiddleware, combineReducers, compose, createStore,
} from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import thunkMiddleware from "redux-thunk";
import commentsReducer from "widgets/Comments/state";
import perksReducer from "widgets/Perks/state";

export default function configureStore(initialState = {}) {
  const rootReducer = combineReducers({
    form: reduxFormReducer,
    comments: commentsReducer,
    perks: perksReducer,
  });

  return createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunkMiddleware),
      // redux chrome devtools:
      // https://github.com/zalmoxisus/redux-devtools-extension
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );
}
