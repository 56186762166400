import React from "react";
import Modal from "components/Modal";
import ModalClose from "components/ModalClose";
import ModalContent from "components/ModalContent";
import { modalStyleProp } from "helpers/modalStyles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./_style.scss";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import Default from "./components/Default";
import Thankyou from "./components/Thankyou";
import Existing from "./components/Existing";
import Authenticate from "./components/Authenticate";

const parseCommentText = (commentText) => {
  const doc = new DOMParser().parseFromString(commentText, "text/html");
  const parsedText = doc.body ? doc.body.textContent.trim() || "" : "";
  return parsedText.replace(/(?:\r\n|\r|\n)/g, "<br />");
};

const CommentModal = ({
  campaignId,
  campaignTitle,
  challengerId,
  commentText,
  createComment,
  errorMessage,
  isResendingLink,
  isResendLinkFail,
  isResendLinkSent,
  isSendingLink,
  isModalOpen,
  isPosting,
  parentCommentId,
  pendingComment,
  resendEmailAction,
  sendEmailAction,
  setModalStatus,
  setModalStep,
  signupEmail,
  signupName,
  step,
}) => {
  const { translate: t } = useLanguageProvider();

  const handleSwitch = (type) => {
    setModalStep(type);
  };

  const handleClose = () => {
    setModalStatus(false);
  };

  const resendEmailHandler = (pendingCommentId) => {
    const formData = {
      campaign_id: campaignId,
      comment_id: pendingCommentId,
    };

    resendEmailAction(formData);
  };

  const sendEmailHandler = () => {
    const formData = {
      campaign_id: campaignId,
      challenger_id: challengerId,
      content: commentText,
      email: signupEmail,
      name: signupName,
      reply_to_id: parentCommentId,
    };

    sendEmailAction(formData);
  };

  const createCommentMarkup = () => ({
    __html: `&ldquo;${parseCommentText(commentText)}&rdquo;`,
  });

  const commonProps = {
    campaignId,
    commentText,
    createComment,
    challengerId,
    handleSwitch,
    errorMessage,
    isPosting,
    parentCommentId,
    step,
  };
  const thankYouProps = {
    isResendingLink,
    isResendLinkFail,
    isResendLinkSent,
    pendingComment,
    resendEmailHandler,
  };

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel={t("Signup/Login to post your comment")}
      customStyle={modalStyleProp()}
      data-testid="comment-modal"
    >
      <ModalClose closeAction={handleClose} />
      <ModalContent
        body={(
          <div data-testid="modal-content-body">
            {step === "default" && <Default campaignTitle={campaignTitle} handleSwitch={handleSwitch} createCommentMarkup={createCommentMarkup} />}
            {(step === "signup" || step === "login") && <Authenticate {...commonProps} /> }
            {step === "thankyou" && <Thankyou thankYouProps={thankYouProps} />}
            {step === "existing" && <Existing isSendingLink={isSendingLink} sendEmailHandler={sendEmailHandler} handleSwitch={handleSwitch} />}

          </div>
        )}
      />
    </Modal>
  );
};

CommentModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  campaignTitle: PropTypes.string.isRequired,
  challengerId: PropTypes.number,
  commentText: PropTypes.string.isRequired,
  createComment: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  isModalOpen: PropTypes.bool.isRequired,
  isPosting: PropTypes.bool,
  parentCommentId: PropTypes.number,
  resendEmailAction: PropTypes.func.isRequired,
  sendEmailAction: PropTypes.func.isRequired,
  setModalStatus: PropTypes.func.isRequired,
  setModalStep: PropTypes.func.isRequired,
  step: PropTypes.oneOf(["default", "signup", "login", "existing", "thankyou"]),
};

const mapStateToProps = (state) => ({
  pendingComment: state.comments.pendingComment,
  comments: state.comments.comments,
  isLoading: state.comments.isLoading,
  isResendingLink: state.comments.isResendingLink,
  isResendLinkFail: state.comments.isResendLinkFail,
  isSendingLink: state.comments.isSendingLink,
  isResendLinkSent: state.comments.isResendLinkSent,
  signupEmail: state.comments.signupEmail,
  signupName: state.comments.signupName,
});

export default connect(mapStateToProps)(CommentModal);
