/* istanbul ignore file */
import React from "react";
import PropTypes from "prop-types";
import Icon from "../icon";

import "../_style.scss";
import "./uistyle.scss";

Icon.propTypes = {
  children: PropTypes.node.isRequired,
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  target: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Icon.defaultProps = {
  fill: "currentColor",
  viewBox: "0 0 32 32",
};

export const Celebrate = (props) => (
  <Icon {...props} viewBox="0 0 68.03 68.13">
    <g>
      <path d="m11.92,33.34c.23-1.13.89-2.07,1.38-3.09.61-1.27,1.37-2.46,2.07-3.68.24.15.15.34.09.55-.62,2.27.02,4.44.76,6.53.68,1.95,1.82,3.69,2.95,5.42,1.52,2.33,3.41,4.38,5.42,6.28,2.72,2.57,5.71,4.83,9.22,6.26.95.39,1.85.94,2.92,1.04.33.03.75-.06,1.04.24-.26,0-.53.03-.78-.01-2.71-.43-5.16.34-7.56,1.53-1.24.61-2.27,1.59-3.64,1.95-.1-.01-.19-.03-.29-.04-.2-.21-.37-.46-.6-.61-2.27-1.53-4.09-3.55-5.87-5.59-1.92-2.2-3.48-4.67-4.76-7.3.02-.05.03-.1.02-.15.16-.61.57-1.1.87-1.63.71-1.27,1.44-2.52,1.36-4.05-.2-.93-.63-1.15-1.26-.63-1,.58-1.41,1.63-2.02,2.51-.14.2-.22.32-.45.3-.11-.31-.28-.61-.33-.93-.25-1.62-.59-3.24-.52-4.89Z" fill="#ffcb05" />
      <path d="m37.76,52.89c-.29-.3-.71-.21-1.04-.24-1.08-.1-1.97-.65-2.92-1.04-3.51-1.43-6.49-3.68-9.22-6.26-2.01-1.9-3.9-3.94-5.42-6.28-1.13-1.73-2.27-3.47-2.95-5.42-.73-2.1-1.37-4.26-.76-6.53.06-.21.15-.4-.09-.55.81-1.21,1.75-2.26,3.3-2.5.17-.03.34-.11.5-.17.79.08,1.61-.12,2.39.15,1.62.06,3.1.61,4.54,1.3.65.31,1.27.66,1.9.99-.01.32-.28.43-.47.6-2.29-1.19-4.64-2.13-7.31-1.93-1.15.09-2.14.37-2.79,1.4-.42.38-.61.87-.63,1.43-.24.9-.25,1.8,0,2.69h0c.16,1.05.36,2.08.93,3,.08.3.12.62.25.9.93,2.05,2.14,3.93,3.5,5.72,1.34,1.77,2.93,3.32,4.54,4.84.69.65,1.35,1.36,2.26,1.75.08.33.32.46.64.48.3.47.72.74,1.28.8.37.81,1.14,1,1.88,1.25,1.33.84,2.71,1.54,4.26,1.86.52.14,1.01.38,1.57.33.79.24,1.58.26,2.37,0,.17-.01.36,0,.49-.15,1.19-.4,2.03-1.14,2.29-2.42.2-.97.33-1.95.1-2.94-.13-.56-.11-1.06.7-.97l.06.05c.35,1.48.7,2.97.29,4.49-.44,1.6-1.36,2.79-2.97,3.37-.1-.15-.25-.2-.41-.15-1.01.29-2.05.08-3.07.14h0Z" fill="#ffdc21" />
      <path d="m30.2,48.03c-.55-.06-.98-.33-1.28-.8.29-.27,0-.66.17-.95.33-.4.84-.5,1.27-.72,1.56-.79,3.17-1.46,4.84-1.97.24.05.45-.05.66-.12,1.27.23,2.52.1,3.77-.18.33-.4.8.02,1.28-.18-.79-.32-1.55.08-2.22-.27,1.08-.24,2.19-.31,3.29-.37.34-.01.7.17,1-.14.39.32.85.13,1.28.16,3.81.28,7.49,1.01,10.76,3.14.45.29.88.61,1.31.93.51.37.27.92.29,1.39.01.24-.07.51-.17.74-.19.42-.38.78-.96.36-1.75-1.27-3.67-2.23-5.69-2.94-1.38-.48-2.79-.94-4.29-.94-.54,0-1.07-.25-1.62-.13,0,0-.06-.05-.06-.05-.12-.21-.34-.14-.49-.12-1.46.19-2.94.13-4.41.16-.25,0-.45.08-.6.28-.41.08-.85-.07-1.24.16-.18.02-.38,0-.54.07-1,.45-2.11.51-3.12,1-.98.48-2.11.67-2.95,1.45-.1.01-.21.03-.31.04Z" fill="#ed1163" />
      <path d="m48.02,3.65c.08.77-.58,1.08-1,1.49-1.03,1.03-1.07,1-.56,2.31.11.29.21.59.36.85.26.45.36.98-.05,1.27-.41.3-.97.45-1.52.15-.42-.23-.91-.38-1.29-.67-.55-.42-.94-.18-1.35.18-.41.36-.83.7-1.28,1.01-.42.3-.78.36-1.21.12-.46-.26-.6-.73-.45-1.14.2-.56.05-1.13.25-1.68.15-.41.03-.79-.42-1.03-.5-.26-.98-.59-1.46-.88-.51-.31-.7-.8-.57-1.34.12-.52.57-.68,1.07-.71.46-.03.92-.12,1.37-.19.78-.12,1.16-.57,1.16-1.37,0-.51.28-.98.39-1.48.14-.65,1.29-.73,1.68-.15.34.51.62,1.07.91,1.61.23.44.47.73,1.05.66.62-.07,1.26-.02,1.88-.02.83,0,1.03.19,1.02,1Z" fill="#ffe200" />
      <path d="m25.78,56.36c1.37-.36,2.4-1.34,3.64-1.95,2.4-1.19,4.85-1.97,7.56-1.53.26.04.52.01.78.01,0,0,0,0,0,0,.2.24.48.15.73.16.86.01,1.72,0,2.58,0-3.21,1.76-6.51,3.34-9.81,4.9-.55.26-1.12.49-1.69.74-1.3-.73-2.65-1.37-3.8-2.33Z" fill="#faa51a" />
      <path d="m20.13,18.41c-1.1-.45-1.46-1.5-1.69-2.46-.83-3.53.5-5.41,4.1-7,1.65-.73,3.39-1.21,5.16-1.51.71-.12,1.46-.06,2.19-.07.24,0,.38.14.46.36.14.36.3.71.41,1.07.27.91.02,1.19-.95,1.25-3.23.21-6.24,1.11-8.84,3.07-.8.6-1.44,1.45-1.31,2.59-.03.85-.07,1.71.63,2.37,0,.13-.03.25-.15.33Z" fill="#ed1163" />
      <path d="m27.53,26.93c.19-.17.46-.29.47-.6.98-.76,1.53-1.85,2.15-2.87.38-.63.75-1.28,1-2,.2-.55.35-1.08.33-1.65.27-.4.05-.85.14-1.26.26.04.3-.24.48-.32,1.16,2.11.91,4.19,0,6.32-.47,1.11-1.15,2.09-1.72,3.14-.1.25-.51.27-.48.62-.59.33-.79.99-1.25,1.44-.58.26-1.02.68-1.3,1.25-.18.1-.29.26-.32.47-.56.2-.86.69-1.24,1.1-.46.21-.77.6-1.15.91q-1.13.93-2.02-.27c-.2-.76-.25-1.46.58-1.91.11-.06.17-.19.25-.29.71-.24,1.42-.56,2.22.02-.33-.36-.15-.58.03-.85.16-.23.13-.41-.23-.28l-.17-.33c.43-.51.85-1.02,1.28-1.53.48-.22.84-.55.94-1.1Z" fill="#ed1163" />
      <path d="m42.14,25.79c-.8-.02-.86.64-.88,1.14-.08,1.45-.05,2.9.28,4.35.25,1.08.43,2.2-.3,3.23-.42.58-.96.94-1.61,1.18-.21.08-.48.06-.6.31-.22.02-.44.05-.66.07-.39-.01-.78-.01-1.16-.04-.71-.04-1.44.14-2.12-.18.43-.27.91-.07,1.36-.12.26-.03.6.11.71-.29.11-.02.22-.04.32-.06.26-.19.72-.11.77-.58.97-.88.71-2.02.5-3.05-.31-1.57-.45-3.13-.25-4.72.16-1.21.82-1.9,1.97-2.07.99-.15,2-.26,2.98.08-.32.25-.74.15-1.09.3-.25.11-.33.22-.22.46Z" fill="#3cb1e5" />
      <path d="m58.89,8.13c.6.91.87,1.96,1.21,2.97.14.42-.01.83-.35,1.13-1.12,1.01-2.43,1.57-3.93,1.77-1.24.16-1.66-.71-2.15-1.52-.46-.76-.55-1.65-.75-2.5-.11-.46.21-.6.6-.73,1.01-.31,2.11-.41,3-1.06.14-.03.24.04.35.12.93.72,1.21.7,2.02-.18Z" fill="#ffe200" />
      <path d="m31.63,18.54c-.08.42.14.87-.14,1.26-.64-.91-1.6-1.18-2.63-1.25-1.64-.1-3.23.24-4.84.53-1.31.24-2.75.41-3.89-.68.12-.07.15-.19.15-.33.26-.23,0-.34-.1-.5-.31-.44-.51-.89-.22-1.42,1.27.64,2.57.39,3.88.13,1.9-.39,3.81-.73,5.73-.09.07.02.12.09.18.13-.2.21.02.27.13.32.53.24.97.61,1.42.97.11.31.21.61.32.92Z" fill="#bd1d70" />
      <path d="m67.2,40.42c-.1.01-.21.03-.31.03-1.19.02-1.28.08-1.67,1.18-.06.17-.15.34-.18.51-.04.29-.11.5-.46.51-.3,0-.58-.03-.76-.3-.11-.17-.19-.37-.25-.57-.25-.84-.8-1.18-1.67-1.02-.15.03-.32-.01-.47,0-.39.05-.59-.24-.73-.5-.15-.28.02-.59.22-.82.15-.17.33-.31.52-.43.58-.37.72-.84.41-1.47-.1-.21-.11-.46-.21-.66-.14-.28-.21-.59,0-.8.24-.25.67-.36.94-.15,1.09.86,2.03.63,3.03-.2.49-.4.99-.02.96.64-.01.25-.09.5-.15.75-.21,1.02-.1,1.23.93,1.76.71.8.7.92-.16,1.52Z" fill="#ffe200" />
      <path d="m54.15,54.76c.01.41-.29.57-.5.72-1.18.84-2.14,1.9-3.08,2.98-.37.42-.58.55-1,.05-.61-.73-1.32-1.38-1.97-2.08-.22-.23-.36-.54-.15-.85.84-1.22,1.8-2.33,3.09-3.1.66-.4,1.32-.34,1.92.21.61.55,1.23,1.08,1.57,1.86.04.09.09.18.12.22Z" fill="#74c044" />
      <path d="m5.16,48.6c-.07-.69.35-1.24.57-1.82.77-2.04,1.67-4.04,2.52-6.05.07-.17.15-.33.38-.33.12.08.14.2.16.33.14,1.41.7,2.68,1.28,3.95-.01.06-.03.11-.04.17-.94,1.68-1.91,3.34-2.8,5.04-.29.56-.76,1.06-.8,1.74-.04,0-.08,0-.12,0-.43-.98-.83-1.98-1.14-3.01Z" fill="#ffcb06" />
      <path d="m10.06,44.67c-.58-1.27-1.13-2.54-1.28-3.95-.01-.13-.03-.26-.16-.33-.3-.3.1-.46.17-.65.81-2.18,1.93-4.22,2.91-6.33.02-.05.14-.05.21-.08-.07,1.66.26,3.27.52,4.89.05.32.22.62.33.93.03.12.06.24.09.37-.99,1.68-2.08,3.32-2.8,5.15Z" fill="#ed1163" />
      <path d="m47.64,27.66c.87-1.05,1-2.32,1.16-3.62.15-1.24.32-2.52,1.41-3.41,1.14-.92,2.47-.9,3.8-.76.36.04.79-.05,1.12.23-.34,0-.72-.07-1.01.05-.34.15-.97-.1-.97.61-.44-.12-.66.13-.85.49-.47.9-.58,1.88-.69,2.86-.12,1.11-.31,2.21-.97,3.15-.36.52-.84.92-1.54.9-.49-.17-.97-.34-1.46-.51Z" fill="#3cb1e5" />
      <path d="m24.69,60.79c-2.38,1.17-4.86,2.12-7.37,2.97-.69-.47-1.39-.94-2.08-1.41-.06-.06-.11-.12-.17-.17.86.04,1.43-.58,2.14-.89,1.33-.58,2.52-1.4,3.83-2,.24-.11.38-.27.32-.55.1.05.21.1.31.15h0c.49.56,1.24.72,1.74,1.25.35.25.65.59,1.12.6.05.02.1.03.15.05Z" fill="#faa51a" />
      <path d="m24.69,60.79c-.05-.01-.1-.03-.15-.05.13-.28.21-.62-.24-.53-.33.06-.58-.17-.88-.07-.5-.53-1.25-.7-1.74-1.24.96-.48,1.81-1.13,2.8-1.56.44-.19.95-.42,1.02-1.03.1.01.19.03.29.04,1.15.96,2.5,1.6,3.8,2.33-1.64.68-3.21,1.53-4.9,2.1Z" fill="#ed1943" />
      <path d="m12.99,15.87c.33-.03.48.04.55.44.19,1.07.65,2.06,1.25,2.97.25.38.22.68-.23.87-.68.29-1.37.56-2.05.87-.35.16-.69.03-.85-.18-.63-.79-1.24-1.59-1.32-2.7-.07-.95.23-1.51,1.1-1.87.51-.21,1.03-.29,1.55-.41Z" fill="#74c044" />
      <path d="m52.94,35.29c-.32.11-.32-.13-.37-.38-.09-.56-.14-1.14-.34-1.66-.24-.6-.11-.88.47-1.14,1.24-.56,2.54-.54,3.83-.46.72.05.74.84.91,1.38.09.28.05.6.11.91.05.27,0,.83-.49.82-1.39-.03-2.73.27-4.12.53Z" fill="#ed1163" />
      <path d="m6.46,67.52c-1.13.71-2.23.88-3.42.03-1.34-.96-2.45-2.01-2.98-3.65-.2-.64.13-1.06.24-1.56.13-.55.13-1.18.6-1.62.17.33.34.66.5,1,0,.05,0,.1.01.16-.06.2-.15.39-.18.6-.11.72.16.91.9.64.42.58.85,1.16,1.27,1.74-.21,0-.3.11-.3.31.07.67.44,1.03,1.1,1.1.23,0,.32-.13.33-.34.39.23.58.73,1.07.83.12.44.61.46.85.76Z" fill="#ed1163" />
      <path d="m58.89,8.13c-.81.88-1.09.91-2.02.18-.11-.08-.21-.15-.35-.12-.11-.24-.39-.18-.58-.34-1.39-1.2-1.3-2.62.24-3.72.4-.29.68-.27,1.02.07.67.68,1.42,1.26,2.32,1.63.49.2.54.55.25,1-.29.44-.81.72-.88,1.3Z" fill="#ed1164" />
      <path d="m59.18,23.3c1.79-.65,1.77-2.13,1.43-3.69-.12-.52-.07-.69.42-.81.65-.16,1.34-.2,1.94-.04.54.15.7.83.77,1.38.17,1.33-.56,2.46-2,3.23-.51.27-1.04.45-1.61.56-.9,0-1.79,0-2.69,0-.27.01-.52-.03-.64-.32.8-.03,1.63.13,2.38-.3Z" fill="#3cb1e5" />
      <path d="m61.84,50.5c-.01-1.21.57-1.81,1.69-1.89,1.3-.09,2.2.8,2.26,2.23.05,1.04-.75,1.86-1.77,1.87-1.22.02-2.17-.94-2.18-2.22Z" fill="#3db1e5" />
      <path d="m5.16,48.6c.31,1.03.71,2.03,1.14,3.01-.9,1.28-1.22,2.85-2.1,4.14-.58-.59-.48-1.48-.96-2.11.44-1.76.98-3.48,1.91-5.05Z" fill="#ed1263" />
      <path d="m3.24,53.65c.47.63.38,1.52.96,2.11.01.05.02.1.03.15-.78,1.62-1.58,3.24-2.33,4.87-.15.32-.17.7-.48.94-.17-.33-.34-.66-.5-1,.06-1.17.56-2.22.9-3.31.33-1.05.68-2.09,1.05-3.13.08-.23.12-.51.39-.64Z" fill="#feca0a" />
      <path d="m47.64,27.66c.49.17.97.34,1.46.51-.71.43-1.5.52-2.29.45-1.34-.13-2.39-.87-3.38-1.72-.43-.37-.86-.74-1.3-1.11-.11-.24-.03-.35.22-.46.35-.15.77-.05,1.09-.3,1.46.17,2.33,1.3,3.35,2.15.26.22.5.43.84.49Z" fill="#2f6db6" />
      <path d="m59.18,23.3c-.75.42-1.58.26-2.38.3-.74-.02-1.16-.6-1.66-1.01-.69-.57-1.23-1.31-1.99-1.81,0-.7.63-.45.97-.61.29-.13.67-.04,1.01-.05.93.26,1.49.99,2.12,1.64.58.59,1.2,1.14,1.93,1.55Z" fill="#2e6db6" />
      <path d="m6.46,67.52c-.24-.31-.73-.33-.85-.76,1.05-.43,2.14-.79,3.14-1.31.8-.41,1.74-.55,2.4-1.22.1.04.19.09.29.13.53.34.94.86,1.58,1.01-1.67.76-3.42,1.3-5.17,1.83-.46.14-.87.48-1.4.33Z" fill="#f9a51b" />
      <path d="m14.24,7.69c.99,0,1.79.79,1.79,1.75,0,.82-.8,1.73-1.5,1.72-.82-.01-1.84-1.14-1.81-1.99.03-.68.85-1.48,1.52-1.48Z" fill="#ffe200" />
      <path d="m44.22,16.66c-.02,1.02-.8,1.75-1.85,1.74-.86-.01-1.47-.62-1.46-1.45,0-.94.98-1.91,1.86-1.85.88.05,1.46.69,1.45,1.56Z" fill="#74c044" />
      <path d="m38.24,34.79c-.04.47-.51.39-.77.58-.77-1.15-1.66-2.18-2.69-3.1-1.57-1.4-3.17-2.75-4.88-3.97-.03-.36.38-.37.48-.62,1.78,1.25,3.46,2.63,5.03,4.13.99.94,1.89,1.98,2.83,2.98Z" fill="#ffdb27" />
      <path d="m13.02,65.37c-.64-.16-1.06-.67-1.58-1.01.24-.33.65-.39.97-.6.98-.24,1.9-.6,2.68-1.26.05-.05.1-.1.16-.15.69.47,1.39.94,2.08,1.41-1.39.65-2.84,1.15-4.3,1.61Z" fill="#ed1943" />
      <path d="m38.36,36.06c.22-.02.44-.05.66-.07,1.35,1.43,2.32,3.11,3.26,4.82.27.49.49,1.01.73,1.52-.3.31-.66.12-1,.14.04-.31-.12-.55-.25-.81-.91-1.77-1.89-3.49-3.12-5.05-.13-.17-.27-.31-.27-.54Z" fill="#ffdc1d" />
      <path d="m41.08,53.05c-.86,0-1.72,0-2.58,0-.25,0-.53.08-.73-.16,1.02-.06,2.06.15,3.07-.14.16-.04.31,0,.41.15-.05.05-.11.1-.16.15Z" fill="#ffcb05" />
      <path d="m19.96,16.17c-.29.53-.09.98.22,1.42.11.15.36.27.1.5-.7-.66-.65-1.51-.63-2.37.1.15.21.3.31.45Z" fill="#c22063" />
      <path d="m31.31,17.62c-.45-.35-.89-.72-1.42-.97-.12-.05-.34-.12-.13-.32.63.14,1.07.59,1.54.98,0,.1.01.21.02.31Z" fill="#cd1b62" />
      <path d="m31.31,17.62c0-.1-.01-.21-.02-.31.42.17.62.54.82.91-.18.08-.22.36-.48.32-.11-.31-.21-.61-.32-.92Z" fill="#ea2671" />
      <path d="m57.44,23.91c.9,0,1.79,0,2.69,0-.9.26-1.79.25-2.69,0Z" fill="#9cd6f4" />
      <path d="m67.2,40.42c.85-.6.86-.72.16-1.52.51.05.7.38.67.84-.03.49-.33.72-.83.68Z" fill="#fdef8f" />
      <path d="m21.56,24.04c-.78-.27-1.59-.07-2.39-.15.81-.15,1.62-.41,2.39.15Z" fill="#ffefa7" />
      <path d="m25.49,56.31c-.07.61-.58.84-1.02,1.03-.99.42-1.84,1.08-2.8,1.56,0,0,0-.01,0-.01-.2-.82-.97-.94-1.57-1.28-.75-.33-1.07-1.14-1.74-1.55.3-.42.6-.55.89.21-.01-1.15-.4-1.32-1.51-.86-.43-.19-.58-.67-.95-.92.68-.86.82.02,1.15.45.23-.65-.15-.88-.54-1.08-.45-.23-.65.04-.77.44-.98-.88-1.69-2-2.52-3.01-.94-1.15-1.78-2.4-2.66-3.6,0-.05,0-.1.02-.15.52-.59.81-1.33,1.21-1.99.54-.9.86-1.92,1.56-2.72,1.28,2.63,2.83,5.1,4.76,7.3,1.78,2.03,3.6,4.06,5.87,5.59.23.16.4.41.6.61Z" fill="#ed1163" />
      <path d="m14.26,42.82c-.7.81-1.02,1.82-1.56,2.72-.4.67-.68,1.4-1.21,1.99-.49-.9-.98-1.8-1.48-2.7.01-.06.03-.11.04-.17.72-1.83,1.81-3.46,2.8-5.15.45,1.06.72,2.19,1.42,3.14,0,.05,0,.1-.02.15Z" fill="#f16c94" />
      <path d="m14.28,42.66c-.7-.94-.97-2.08-1.42-3.14-.03-.12-.06-.24-.09-.37.23.02.31-.1.45-.3.61-.89,1.03-1.94,2.02-2.51.58-.11.97.17,1.26.63.08,1.53-.65,2.78-1.36,4.05-.3.54-.71,1.02-.87,1.63Z" fill="#ffdf67" />
      <path d="m16.5,36.98c-.29-.46-.68-.74-1.26-.63.63-.52,1.06-.3,1.26.63Z" fill="#ffd946" />
      <path d="m22.62,33.2q.88,1.2,2.02.27c.38-.31.69-.7,1.15-.91.6.67,1.26,1.2,2.09,1.64.96.51,1.68,1.46,2.63,2.07.12.08.25.22.29.36.1.37.31.57.67.69.33.11.55.37.65.73.05.2.14.4.34.51.87.48,1.41,1.41,1.7,2.15.4,1.04.63,2.03,1.72,2.54-.25.04-.52.05-.65.32-1.67.51-3.28,1.18-4.84,1.97-.43.22-.94.32-1.27.72-.33.05-.56.28-.81.47-.91-.38-1.57-1.09-2.26-1.75-1.61-1.52-3.2-3.07-4.54-4.84-1.36-1.79-2.57-3.67-3.5-5.72-.13-.28-.17-.6-.25-.9.7-.16.44-.73.48-1,1.36-.32,2.78.25,4.1-.48.28.29-.68.98.3,1.15Z" fill="#faa61a" />
      <path d="m26.59,28.03c-.43.51-.85,1.02-1.28,1.53-.26.21-.6.32-.79.62-.1.02-.2.03-.3.05-.4-.38-.39-.37-.72.02-.04.04-.14.06-.2.05-.36-.05-.74-.04-1.06-.17-1.38-.54-2.69-.24-3.95.33-.53.24-.97.29-1.47.07,0,0,0,0,0,0,0-.9,0-1.8,0-2.69.01-.56.21-1.04.63-1.43.1.68.53.97,1.19.97.86,0,1.7-.14,2.56-.18,1.46-.06,2.89.35,4.36.14.24-.03,1.05-.06,1.05.7Z" fill="#f5921f" />
      <path d="m30.51,47.99c.84-.78,1.97-.96,2.95-1.45,1.01-.5,2.12-.55,3.12-1,.16-.07.36-.05.54-.07-.3.55-.58,1.08-.48,1.76.08.57.04,1.14-.25,1.68-.1.18-.09.48,0,.69.19.53.1,1.03-.04,1.54-1.55-.33-2.94-1.03-4.26-1.86-.36-.63-1.21-.66-1.57-1.29Z" fill="#f9a21b" />
      <path d="m36.35,51.14c.14-.51.23-1.01.04-1.54-.08-.21-.09-.51,0-.69.29-.54.33-1.12.25-1.68-.1-.68.19-1.21.48-1.76.39-.22.83-.08,1.24-.16.87-.09,1.62.32,2.4.61-.33.03-.65.05-.82.06-.03,1.34.23,2.64-.15,3.9-.36,1.18-.26,1.42.98,1.44-.13.16-.31.14-.49.15-.79,0-1.58,0-2.37,0-.56.05-1.05-.2-1.57-.33Z" fill="#f6961e" />
      <path d="m40.77,51.32c-1.23-.02-1.34-.25-.98-1.44.39-1.26.12-2.56.15-3.9.17-.01.49-.04.82-.06-.79-.28-1.54-.7-2.4-.61.15-.2.35-.28.6-.28,1.47-.03,2.94.03,4.41-.16.16-.02.37-.09.49.12-.81-.1-.83.4-.7.97.23.99.1,1.97-.1,2.94-.26,1.28-1.1,2.02-2.29,2.42Z" fill="#f38c20" />
      <path d="m26.59,28.03c0-.75-.81-.73-1.05-.7-1.47.21-2.9-.2-4.36-.14-.86.04-1.7.17-2.56.18-.65,0-1.08-.29-1.19-.97.65-1.04,1.64-1.32,2.79-1.4,2.67-.2,5.02.74,7.31,1.93-.1.55-.46.88-.94,1.1Z" fill="#f38920" />
      <path d="m16.81,30.52c.5.22.95.17,1.47-.07,1.26-.57,2.57-.87,3.95-.33.33.13.71.12,1.06.17.07,0,.16,0,.2-.05.33-.39.33-.39.72-.02-.22.3-.65.38-.77.77-.08.1-.15.23-.25.29-.84.45-.78,1.16-.58,1.91-.97-.17-.02-.86-.3-1.15-1.32.72-2.75.15-4.1.48-.04.28.22.84-.48,1-.56-.92-.77-1.96-.93-3Z" fill="#f89b1d" />
      <path d="m30.51,47.99c.37.62,1.21.66,1.57,1.29-.74-.25-1.51-.44-1.88-1.25.1-.01.21-.03.31-.04Z" fill="#f4b61c" />
      <path d="m28.29,46.74c.25-.19.48-.42.81-.47-.17.3.12.68-.17.95-.32-.02-.55-.15-.64-.48Z" fill="#ef3955" />
      <path d="m16.81,27.83c0,.9,0,1.8,0,2.69-.26-.9-.25-1.8,0-2.69Z" fill="#f89b1d" />
      <path d="m37.92,51.47c.79,0,1.58,0,2.37,0-.79.26-1.58.24-2.37,0Z" fill="#f8b518" />
      <path d="m38.36,36.06c0,.22.14.37.27.54,1.23,1.57,2.22,3.29,3.12,5.05.13.25.29.5.25.81-1.1.06-2.21.13-3.29.37-.06-.02-.11-.04-.17-.05-.22-.54-.68-.26-1.02-.27-.47,0-.63-.09-.57-.6.07-.62.08-1.42-.32-1.78-.9-.8-1.14-1.98-1.93-2.79-.47-.48-.41-1.21-.91-1.66-.06-.05.13-.1.22-.13.37.03.64.45,1.06.3.68.32,1.41.14,2.12.18.39.02.78.02,1.16.04Z" fill="#f5931f" />
      <path d="m39.65,43.28c-1.25.27-2.5.41-3.77.18.66.05,1.23-.35,1.87-.35.65-.08,1.26.13,1.9.17Z" fill="#ed0c6e" />
      <path d="m39.65,43.28c-.64-.03-1.25-.25-1.9-.17,0-.1.01-.19.02-.29.26-.02.52-.03.78-.05.06.02.11.04.17.05.67.35,1.42-.05,2.22.27-.48.2-.95-.22-1.28.18Z" fill="#ed165a" />
      <path d="m37.77,42.82c0,.1-.01.19-.02.29-.65,0-1.22.4-1.87.35-.22.07-.43.17-.66.12.13-.28.4-.28.65-.32.65-.07,1.23-.44,1.9-.43Z" fill="#ef434c" />
      <path d="m37.77,42.82c-.67-.01-1.25.36-1.9.43-1.1-.51-1.32-1.5-1.72-2.54-.28-.74-.83-1.67-1.7-2.15-.2-.11-.29-.3-.34-.51-.09-.36-.32-.62-.65-.73-.36-.12-.57-.33-.67-.69-.04-.14-.16-.28-.29-.36-.95-.61-1.67-1.56-2.63-2.07-.83-.44-1.49-.98-2.09-1.64.38-.4.68-.9,1.24-1.1.42-.09.68,1.04,1.19.09.16.29.02.65.38.76,1.13.34,1.95,1.24,3.03,1.68-.05.29.13.38.36.48.36.17.69.39,1.04.59.26.31.74.15,1,.48-.09.02-.28.08-.22.13.5.45.44,1.19.91,1.66.8.81,1.04,2,1.93,2.79.4.36.4,1.17.32,1.78-.06.51.1.6.57.6.34,0,.81-.27,1.02.27-.26.02-.52.03-.78.05Z" fill="#f89c1c" />
      <path d="m31.63,33.99c-1.08-.44-1.9-1.34-3.02-1.68-.37-.11-.22-.48-.38-.76-.5.94-.77-.18-1.19-.09.03-.21.14-.36.32-.47.62-.22,1.01-.69,1.3-1.25.46-.44.66-1.11,1.25-1.44,1.71,1.22,3.32,2.57,4.88,3.97,1.03.92,1.92,1.95,2.69,3.1-.11.02-.21.04-.32.06-.06,0-.12,0-.18,0-.57-.29-1.26-.47-1.69-.9-1.12-1.1-2.35-.94-3.65-.53Z" fill="#f6931e" />
      <path d="m23.46,31c.12-.39.55-.48.77-.77.1-.02.2-.03.3-.05.57.72.67-.08.95-.29.36-.13.38.05.23.28-.18.26-.36.49-.03.85-.8-.58-1.51-.26-2.22-.02Z" fill="#ed1a5a" />
      <path d="m28.65,29.74c-.29.56-.68,1.03-1.3,1.25.28-.57.72-.99,1.3-1.25Z" fill="#ef4044" />
      <path d="m25.48,29.89c-.28.21-.39,1.01-.95.29.19-.3.53-.41.79-.62.06.11.11.22.17.33Z" fill="#ed0e6a" />
      <path d="m36.97,35.43c.06,0,.12,0,.18,0-.11.4-.45.26-.71.29-.45.06-.93-.15-1.36.12-.42.15-.69-.27-1.06-.3-.25-.33-.73-.17-1-.48.29-.29.53-.01.78.08,1.03.37,2.08.62,3.17.28Z" fill="#4098cf" />
      <path d="m10.02,44.84c.49.9.98,1.8,1.48,2.7-.02.05-.03.1-.02.15-.57.93-1.15,1.85-1.71,2.78-.7,1.18-1.39,2.36-2.08,3.54-.04-.01-.08-.02-.13-.02-.02-.19-.14-.32-.22-.48-.33-.62-.88-1.13-.92-1.89.04-.68.5-1.18.8-1.74.89-1.7,1.86-3.36,2.8-5.04Z" fill="#ffdf67" />
      <path d="m6.42,51.62c.03.76.59,1.27.92,1.89.08.16.2.29.22.48-.04.23-.03.45-.16.67-.66,1.07-1.29,2.15-1.93,3.23-.51-.6-.89-1.28-1.23-1.98,0-.05-.02-.1-.03-.15.88-1.29,1.2-2.86,2.1-4.14.04,0,.08,0,.12,0Z" fill="#f16c94" />
      <path d="m7.68,54.01c.69-1.18,1.38-2.37,2.08-3.54.56-.93,1.14-1.85,1.71-2.78.88,1.2,1.71,2.45,2.66,3.6.83,1.01,1.53,2.13,2.52,3.01.05.06.1.13.15.19.37.25.52.73.95.92.15.27.37.47.61.65.67.41,1,1.22,1.74,1.55.35.45.83.76,1.26,1.13.05.28-.08.44-.32.55-1.31.6-2.5,1.43-3.83,2-.71.31-1.28.93-2.14.89-.3-.22-.62-.43-.9-.67-2.52-2.18-4.82-4.56-6.49-7.49Z" fill="#ffcb05" />
      <path d="m7.68,54.01c1.66,2.93,3.96,5.31,6.49,7.49.28.24.6.45.9.67.06.06.11.11.17.17-.05.05-.1.1-.16.15-.2.04-.43-.26-.57-.03-.32.54-.91.54-1.39.75-.29.13-.59.21-.72.54-.31.21-.73.27-.97.6-.1-.04-.2-.09-.29-.13-.04-.06-.07-.15-.13-.19-1.39-.95-2.47-2.23-3.61-3.44-.71-.75-1.2-1.67-1.94-2.38,0-.11,0-.22,0-.33.64-1.08,1.28-2.16,1.93-3.23.14-.22.12-.45.16-.67.04,0,.09.01.13.02Z" fill="#ed1163" />
      <path d="m21.37,58.73c-.43-.37-.91-.68-1.26-1.13.6.34,1.36.46,1.57,1.28-.1-.05-.21-.1-.31-.15Z" fill="#ee2c55" />
      <path d="m23.41,60.14c.3-.1.55.13.88.07.46-.09.37.25.24.53-.47-.02-.78-.35-1.12-.6Z" fill="#ee3134" />
      <path d="m5.46,58.22c.74.71,1.24,1.63,1.94,2.38,1.14,1.22,2.22,2.49,3.61,3.44.06.04.09.12.13.19-.66.67-1.6.81-2.4,1.22-1,.52-2.09.88-3.14,1.31-.49-.11-.67-.6-1.07-.83-.37-.36-.75-.72-1.12-1.07-.42-.58-.85-1.16-1.27-1.74,0-.05,0-.11,0-.16.54-.71,1.11-1.4,1.6-2.16.56-.87.91-1.88,1.72-2.59Z" fill="#ffcb05" />
      <path d="m5.46,58.22c-.81.7-1.16,1.72-1.72,2.59-.49.76-1.06,1.45-1.6,2.16-.24-.36-.48-.72-.71-1.08,0-.05,0-.1-.01-.16.32-.24.34-.62.48-.94.75-1.64,1.55-3.25,2.33-4.87.34.7.72,1.38,1.23,1.98,0,.11,0,.22,0,.33Z" fill="#ffdf67" />
      <path d="m1.42,61.88c.24.36.48.72.71,1.08,0,.05,0,.11,0,.16-.74.26-1,.08-.9-.65.03-.2.12-.4.18-.6Z" fill="#f16a92" />
      <path d="m3.41,64.86c.37.36.75.72,1.12,1.07,0,.21-.1.34-.33.34-.3-.43-.67-.8-1.1-1.1,0-.2.09-.32.3-.31Z" fill="#ed0c6f" />
      <path d="m3.11,65.17c.43.3.8.67,1.1,1.1-.66-.07-1.03-.44-1.1-1.1Z" fill="#ed1558" />
      <path d="m12.41,63.76c.13-.33.43-.41.72-.54.48-.21,1.07-.21,1.39-.75.14-.23.37.07.57.03-.78.66-1.7,1.02-2.68,1.26Z" fill="#ed1653" />
      <path d="m18.37,56.05c-.25-.18-.47-.38-.61-.65,1.11-.46,1.49-.29,1.51.86-.29-.76-.59-.63-.89-.21Z" fill="#ee1d5a" />
      <path d="m16.8,54.49c-.05-.06-.1-.13-.15-.19.11-.4.32-.66.77-.44.39.2.77.43.54,1.08-.33-.43-.47-1.31-1.15-.45Z" fill="#ed1659" />
      <path d="m36.97,35.43c-1.1.34-2.14.09-3.17-.28-.25-.09-.49-.36-.78-.08-.34-.2-.68-.42-1.04-.59-.23-.11-.41-.19-.36-.48,1.3-.41,2.53-.57,3.65.53.44.43,1.12.61,1.69.9Z" fill="#2e6eb6" />
    </g>
  </Icon>
);
export const IconCopy = (props) => (
  <Icon {...props}>
    <g>
      <path className="cls-2" d="m49.29,0c-65.72,1.12-65.71,98.88,0,100,65.72-1.12,65.71-98.88,0-100Z" />
      <path fill="#fff" className="cls-1" d="m60.84,80.28H25.79c-1.1,0-2-.9-2-2v-45.41c0-1.1.9-2,2-2h9.95v-9.15c0-1.1.9-2,2-2h35.05c1.1,0,2,.9,2,2v45.41c0,1.1-.9,2-2,2h-9.95v9.15c0,1.1-.9,2-2,2Zm-33.05-4h31.05v-7.15h-21.1c-1.1,0-2-.9-2-2v-32.25h-7.95v41.41Zm35.05-11.15h7.95V23.72h-31.05v7.15h21.1c1.1,0,2,.9,2,2v32.25Zm-23.1,0h19.1v-30.25h-19.1v30.25Z" />
    </g>
  </Icon>
);
export const IconBolt = (props) => (
  <Icon {...props} viewBox="0 0 58.03 97.28">
    <g id="Layer_1-2" data-name="Layer 1">
      <path fill="#1fa7df" d="m39.54,95.14C26.55,77.29,13.54,59.44.6,41.55c-.09-.13-.18-.26-.25-.4-.95-1.67.15-3.82,2.05-4.08.21-.03.41-.05.62-.05,4.8-.11,9.6-.03,14.4-.05,4.17-.02,4.44-.41,2.99-4.17-3.68-9.55-7.36-19.1-11.03-28.65C8.03.65,8.41.07,12.21.05c3.95-.03,7.89,0,11.84,0,3.95,0,7.9.09,11.84-.04,2.14-.07,3.28.71,4.02,2.74,5.77,15.82,11.64,31.6,17.46,47.4,1.27,3.45.9,3.96-2.73,3.97-7.04.02-14.08,0-21.12,0-4.8,0-5.04.36-3.26,4.99,4.55,11.83,13.67,35.49,13.67,35.49,0,0,.04.12.1.34.53,1.85-1.81,3.15-3.1,1.72l-1.38-1.53Z" />
    </g>
  </Icon>
);
export const IconUILightbulb = (props) => (
  <Icon {...props}>
    <g>
      <g id="b">
        <g id="c">
          <g id="d">
            <g id="e">
              <path
                className="st0"
                d="M46.8,2.7c24.4,0,44.1,19.8,44.1,44.1S71.2,90.9,46.8,90.9S2.7,71.2,2.7,46.8l0,0 C2.7,22.5,22.5,2.7,46.8,2.7z"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        className="st1"
        d="M57.5,24.5c0.8-1.4,1.6-2.7,2.4-4.1c0.3-0.5,0.8-0.7,1.3-0.5c0.5,0.2,0.7,0.5,0.8,1.1 c-0.1,0.1-0.1,0.4-0.2,0.6c-0.8,1.3-1.5,2.7-2.3,4c-0.4,0.7-1,0.8-1.6,0.5C57.3,25.8,57.2,25.1,57.5,24.5z"
      />
      <path
        className="st1"
        d="M46.1,17.4c0-0.7,0.4-1.2,1.1-1.2c0.6,0,1.1,0.5,1.1,1.2c0,0.8,0,1.6,0,2.4c0,0,0,0,0,0c0,0.8,0,1.5,0,2.3 c0,0.8-0.4,1.2-1.1,1.2c-0.7,0-1-0.4-1.1-1.2C46.1,20.5,46.1,18.9,46.1,17.4z"
      />
      <path
        className="st1"
        d="M33,20c0.6-0.3,1.2-0.2,1.6,0.5c0.8,1.3,1.6,2.7,2.3,4c0.1,0.2,0.2,0.4,0.2,0.5c0,0.6-0.3,1-0.8,1.1 c-0.5,0.2-1,0-1.3-0.5c-0.3-0.5-0.6-1.1-1-1.7c-0.5-0.8-1-1.6-1.4-2.5C32.3,20.9,32.4,20.3,33,20z"
      />
      <path
        className="st1"
        d="M18.8,44.5c0-0.6,0.5-1.1,1.2-1.1c0.8,0,1.6,0,2.3,0c0.8,0,1.5,0,2.3,0c0.8,0,1.2,0.5,1.3,1.1 c0,0.6-0.5,1.1-1.2,1.1c-1.5,0-3.1,0-4.6,0C19.3,45.7,18.8,45.2,18.8,44.5z"
      />
      <path
        className="st1"
        d="M28.4,56.6c-1.4,0.9-2.9,1.7-4.4,2.5c-0.1,0.1-0.3,0.1-0.5,0.1c0,0-0.1,0.1-0.1,0.1c-0.3-0.3-0.7-0.5-0.9-0.8 c-0.2-0.4-0.1-1,0.3-1.2c1.5-0.9,3-1.8,4.5-2.6c0.5-0.3,1.1,0,1.3,0.5C29,55.7,28.9,56.4,28.4,56.6z"
      />
      <path
        className="st1"
        d="M27.2,34.3c-1.4-0.8-2.8-1.6-4.2-2.4c-0.5-0.3-0.7-0.9-0.4-1.5c0.3-0.5,0.9-0.7,1.5-0.4c1,0.5,1.9,1.1,2.9,1.6 c0.5,0.3,0.9,0.5,1.4,0.8c0.3,0.2,0.5,0.6,0.7,0.9C28.9,34.2,28,34.7,27.2,34.3z"
      />
      <path
        className="st1"
        d="M47.8,81.6c-0.2,0-0.4,0-0.6,0c0,0,0,0,0,0c-0.2,0-0.4,0-0.7,0c-0.6-0.1-1.1-0.6-1.1-1.2 c0-0.6,0.4-1.1,1.1-1.2c0.4,0,0.9,0,1.3,0c0.7,0,1.1,0.6,1.1,1.2C48.9,81,48.5,81.5,47.8,81.6z"
      />
      <path
        className="st1"
        d="M49.8,78.1c-1.7,0-3.4,0-5.2,0c-0.8,0-1.3-0.5-1.3-1.2c0-0.7,0.5-1.2,1.3-1.2c0.9,0,1.8,0,2.6,0 c0.8,0,1.7,0,2.5,0c0.8,0,1.3,0.5,1.3,1.2C51,77.6,50.5,78.1,49.8,78.1z"
      />
      <path
        className="st1"
        d="M52,74.7c-1.6,0-3.2,0-4.9,0c-1.6,0-3.2,0-4.8,0c-0.8,0-1.3-0.5-1.3-1.2c0-0.7,0.5-1.2,1.4-1.2 c3.2,0,6.5,0,9.7,0c0.8,0,1.4,0.5,1.4,1.2C53.4,74.2,52.8,74.7,52,74.7z"
      />
      <path
        className="st1"
        d="M57.6,57.5c-1.8,1.8-2.7,3.9-3.2,6.3c-0.3,1.8-0.5,3.6-0.7,5.3c-0.1,1.2-0.7,1.9-2,1.9c-1.5,0-3,0-4.5,0 c-1.5,0-3,0-4.5,0c-1.3,0-2-0.7-2-2c-0.1-3-0.5-5.9-1.8-8.6c-0.5-1.1-1.3-2.1-2.1-3c-4-4.3-5.4-9.3-3.6-14.9 c1.7-5.5,5.7-9,11.4-10.1c8.2-1.6,16.1,4.1,17.2,12.3C62.5,49.8,61.1,54,57.6,57.5z"
      />
      <path
        className="st1"
        d="M66,32.5c1.4-0.9,2.9-1.7,4.4-2.5c0.5-0.3,1.1-0.1,1.4,0.4c0.3,0.5,0.2,1.2-0.4,1.5c-1.4,0.8-2.9,1.7-4.3,2.5 c-0.1,0.1-0.3,0.1-0.4,0.1c-0.6,0-0.9-0.3-1.1-0.7C65.4,33.2,65.5,32.7,66,32.5z"
      />
      <path
        className="st1"
        d="M71.8,58.8c-0.3,0.5-0.9,0.7-1.5,0.4c-1.4-0.8-2.8-1.6-4.2-2.4c-0.5-0.3-0.6-0.7-0.5-1.3 c0.1-0.5,0.5-0.7,1.1-0.8c0.1,0.1,0.4,0.1,0.6,0.2c1.4,0.8,2.7,1.5,4,2.3C71.9,57.6,72.1,58.2,71.8,58.8z"
      />
      <path
        className="st1"
        d="M74.3,45.7c-0.8,0-1.5,0-2.3,0c-0.8,0-1.5,0-2.3,0c-0.8,0-1.3-0.5-1.3-1.1c0-0.6,0.5-1.1,1.2-1.1 c1.5,0,3.1,0,4.6,0c0.7,0,1.3,0.5,1.2,1.1C75.6,45.2,75.1,45.6,74.3,45.7z"
      />
    </g>
  </Icon>

);
export const IconSecure = (props) => (
  <Icon {...props} viewBox="0 0 94.69 114.04">
    <g id="Layer_1-2" data-name="Layer 1">
      <path fill="#1fa7df" d="m.06,16.91c17.58-.99,33.77-5.25,47.45-16.91,13.5,10.58,29.59,14.4,46.13,16.79.12.19.23.29.23.39.3,14.18.9,28.36.8,42.54-.07,10.35-4.38,19.43-10.67,27.51-9.39,12.04-21.75,20.21-35.38,26.66-.46.22-1.22.2-1.67-.02-11.62-5.84-22.75-12.39-31.7-22.07-7.9-8.55-12.49-18.69-14.41-30.1-.32-1.91-.4-3.88-.43-5.82C.25,43.34.13,30.81,0,18.27c0-.42.04-.84.06-1.36Zm48.93,30.46c-5.44,0-10.88,0-16.32,0-2.09,0-2.72.53-2.73,2.53-.04,7.4-.04,14.79,0,22.19,0,1.93.65,2.51,2.57,2.51,10.88.01,21.76.01,32.64,0,2,0,2.66-.62,2.67-2.63.03-7.33.03-14.67,0-22,0-2-.67-2.59-2.69-2.59-5.38-.01-10.76,0-16.14,0Zm11.95-1.47c0-1.67.09-3.25-.02-4.81-.33-4.74-2.49-8.41-6.81-10.52-4.04-1.97-8.1-1.69-11.82.85-4.82,3.3-5.94,7.84-5.04,14.47h3.9c0-1.44,0-2.77,0-4.1.03-4.7,3.35-8.23,7.74-8.24,4.42-.01,7.67,3.45,7.71,8.21.01,1.37,0,2.74,0,4.13h4.32Z" />
    </g>
  </Icon>
);
export const IconSend = (props) => (
  <Icon {...props} viewBox="0 0 57.74 61.82">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path fill="#1fa7df" d="m2.67,35.75l10.02,5.26c1.55.66,3.17.44,4.19-.56L53.97,1.68c1.32-1.44.22-1.85-.21-1.63L2,29.58c-2.82,1.44-2.71,4.75.68,6.17Z" />
        <path fill="#1fa7df" d="m27.57,47.47l-.98-.52c-.8-.42-1.05-1.45-.52-2.19L55.99,2.67c1.12-1.64,1.86-.41,1.74.05l-9.21,49.71c-.71,2.78-2.09,4.78-8.96,1.16" />
        <path fill="#1fa7df" d="m43.91,15.19l-26.42,29.06c-.62.69-1.62.89-2.46.5l-4.09-1.89,5.21,17.25c.57,1.87,3.02,2.32,4.21.77l7.38-9.62-5.18-2.97c-1.11-.63-1.41-2.1-.64-3.12l22.32-29.72c.16-.21-.14-.46-.32-.26Z" />
      </g>
    </g>
  </Icon>
);
export const IconHero = (props) => (
  <Icon {...props} viewBox="0 0 64.77 62.1">
    <g>
      <path fill="#1fa7df" d="m46.19,21.11c.22,6.49.31,12.78.68,19.05.35,6,1,11.98,1.46,17.97.17,2.23-1.16,3.8-3.1,3.91-2.11.12-3.46-1.23-3.66-3.57-.57-6.83-1.16-13.66-1.74-20.47-1.15-.46-1.29.12-1.36,1-.53,6.56-1.09,13.11-1.66,19.66-.21,2.35-1.66,3.63-3.77,3.41-1.98-.21-3.15-1.94-2.95-4.22.63-7.23,1.24-14.46,1.85-21.7.04-.45,0-.9,0-1.58-2.92,1.5-4.43-.16-5.8-2.33-1.12-1.77-2.43-3.42-3.64-5.14-1.39-1.97-1.38-3.02.23-4.86,3.99-4.57,9.14-6.93,15.1-7.1,7.88-.23,15.78-.09,23.67-.07,1.93,0,3.24,1.26,3.26,2.97.02,1.77-1.3,3.03-3.36,3.05-4.94.04-9.87.01-15.22.01Zm-14.27,8.75v-7.27c-1.31.92-2.37,1.67-3.48,2.45,1.2,1.66,2.23,3.09,3.48,4.82Z" />
      <path fill="#1fa7df" d="m23.98,17.6c-1.36,1.43-2.44,2.45-3.37,3.58-1.68,2.04-1.79,4.59-.29,6.77,1.67,2.43,3.3,4.91,5.18,7.17.96,1.15,2.45,1.86,4.03,3-.45,2.44.14,5.48-1.75,8.28-1.57,2.33-2.61,5.02-3.83,7.57-.84,1.75-2.02,2.44-3.93,2.18-4.73-.66-9.09-4.43-10.12-9.13-.97-4.44-3.47-7.64-7.13-10.15-3.65-2.5-3.66-2.91-.2-5.71,6.14-4.98,12.73-9.28,19.8-12.84.39-.2.8-.35,1.6-.71Z" />
      <path fill="#1fa7df" d="m39.18,13.33c-3.72,0-6.74-3-6.74-6.68C32.44,2.98,35.46,0,39.19,0c3.71,0,6.75,3,6.74,6.67-.01,3.68-3.03,6.67-6.75,6.66Z" />
    </g>
  </Icon>

);
export const IconSnap = (props) => (
  <Icon {...props} viewBox="0 0 49.28 61.71">
    <g>
      <path fill="#1fa7df" d="m14.35,56.17c3.49,3.23,7.3,5.47,12.07,5.54,4.96.08,9.16-1.91,12.84-5.08,3.19-2.75,4.66-6.24,4.14-10.45-.37-2.98-1.68-5.61-3.27-8.12-.19-.3-.43-.7-.39-1.02.38-3.01,1.07-5.92,2.87-8.46,1.35-1.9,3.15-3.3,5.09-4.55,1.14-.74,1.79-1.79,1.52-3.17-.27-1.38-1.07-2.4-2.51-2.76-2.17-.54-4.2-.15-6.05,1.07-2.19,1.45-4.06,3.23-5.59,5.39-.49.7-1.1,1.35-1.77,1.88-1.6,1.25-3.05,1.07-4.55-.32-3.03-2.8-5.96-5.75-9.2-8.29-3.73-2.92-7.73-5.5-11.68-8.12-.74-.49-1.84-.71-2.73-.62-1.51.14-2.61,1.38-2.79,2.69-.23,1.68.4,2.86,1.78,3.78,2.68,1.78,5.4,3.52,7.93,5.51,2.67,2.1,5.21,4.38,7.66,6.73,3.11,2.97,6.15,6.02,9.08,9.16,2.1,2.25,1.61,5.66-.97,7.19-1,.59-2.3.69-3.58,1.05.05,1.34-.32,2.82-1.55,3.96-1.21,1.12-2.69,1.46-4.3,1.27.16,3.02-1.16,4.88-4.04,5.74Z" />
      <path fill="#1fa7df" d="m9.84,28.76c.07,1.15.57,2.06,1.5,2.8,1.92,1.54,3.87,3.05,5.65,4.75,2.03,1.94,3.87,4.07,5.8,6.11,1.05,1.11,2.55,1.39,3.89.76,1.29-.61,2.21-2.03,1.88-3.38-.22-.89-.74-1.8-1.37-2.48-2.11-2.3-4.24-4.59-6.52-6.72-1.76-1.66-3.71-3.12-5.64-4.59-1.05-.8-2.28-.77-3.43-.15-1.13.61-1.68,1.6-1.76,2.9Z" />
      <path fill="#1fa7df" d="m19.73,49.01c1.04-.09,2.05-.64,2.65-1.84.66-1.32.5-2.59-.45-3.68-1.16-1.34-2.3-2.71-3.61-3.89-2.82-2.56-5.68-5.09-8.65-7.48-1.46-1.17-3.51-.63-4.56.89-.99,1.43-.65,3.43.78,4.55.44.34.93.62,1.33,1,3.19,3.04,6.38,6.08,9.52,9.15.78.76,1.6,1.3,2.98,1.3Z" />
      <path fill="#1fa7df" d="m13.52,54.65c1.5.05,2.48-.67,3.09-1.86.65-1.26.45-2.52-.46-3.59-1.13-1.31-2.26-2.64-3.53-3.82-2.41-2.24-4.85-4.45-7.4-6.53-1.45-1.18-3.53-.7-4.6.77-.99,1.36-.78,3.5.57,4.5,3.67,2.73,6.84,5.97,9.87,9.36.68.76,1.5,1.22,2.46,1.15Z" />
      <path fill="#1fa7df" d="m33.92,11.67c.12.07.23.15.35.22.48-.47,1.01-.9,1.42-1.43,1.33-1.73,2.63-3.48,3.91-5.25.7-.96.52-2-.35-2.5-.92-.52-1.83-.18-2.33.95-.83,1.9-1.64,3.81-2.39,5.73-.28.72-.41,1.51-.6,2.26Z" />
      <path fill="#1fa7df" d="m28.83,10.41c.14,0,.27-.02.41-.02.14-.42.35-.83.4-1.26.32-2.43.64-4.87.88-7.31.12-1.21-.5-1.84-1.58-1.81-1.02.03-1.66.79-1.54,1.91.18,1.72.36,3.44.64,5.15.18,1.13.52,2.24.79,3.35Z" />
      <path fill="#1fa7df" d="m24.07,11.88c.03-.38.08-.52.04-.63-1.07-2.69-2.12-5.39-3.27-8.04-.35-.8-1.46-.94-2.18-.47-.75.49-.98,1.49-.39,2.27,1.48,1.96,3,3.89,4.54,5.81.3.37.74.63,1.26,1.06Z" />
      <path fill="#1fa7df" d="m14.35,56.17c2.87-.86,4.2-2.71,4.04-5.74,1.61.19,3.09-.15,4.3-1.27,1.23-1.14,1.6-2.62,1.55-3.96,1.28-.36,2.58-.46,3.58-1.05,2.58-1.53,3.07-4.94.97-7.19-2.93-3.15-5.97-6.19-9.08-9.16-2.46-2.35-4.99-4.63-7.66-6.73-2.53-1.98-5.25-3.72-7.93-5.51-1.38-.92-2.01-2.1-1.78-3.78.18-1.31,1.28-2.54,2.79-2.69.89-.08,1.99.13,2.73.62,3.95,2.62,7.95,5.2,11.68,8.12,3.24,2.54,6.17,5.48,9.2,8.29,1.5,1.39,2.95,1.57,4.55.32.67-.52,1.28-1.18,1.77-1.88,1.53-2.16,3.39-3.94,5.59-5.39,1.85-1.22,3.88-1.61,6.05-1.07,1.44.36,2.24,1.38,2.51,2.76.27,1.37-.38,2.43-1.52,3.17-1.94,1.25-3.73,2.65-5.09,4.55-1.8,2.53-2.49,5.45-2.87,8.46-.04.32.19.71.39,1.02,1.6,2.5,2.9,5.14,3.27,8.12.53,4.22-.94,7.71-4.14,10.45-3.68,3.17-7.88,5.15-12.84,5.08-4.77-.07-8.58-2.31-12.07-5.54Z" />
      <path fill="#1fa7df" d="m9.84,28.76c.08-1.3.63-2.29,1.76-2.9,1.16-.62,2.38-.66,3.43.15,1.93,1.47,3.88,2.94,5.64,4.59,2.27,2.14,4.41,4.42,6.52,6.72.63.68,1.15,1.59,1.37,2.48.33,1.35-.59,2.77-1.88,3.38-1.34.63-2.84.35-3.89-.76-1.93-2.04-3.77-4.17-5.8-6.11-1.77-1.7-3.73-3.21-5.65-4.75-.93-.75-1.43-1.65-1.5-2.8Z" />
      <path fill="#1fa7df" d="m19.73,49.01c-1.38,0-2.2-.54-2.98-1.3-3.15-3.08-6.34-6.12-9.52-9.15-.4-.38-.89-.66-1.33-1-1.43-1.12-1.77-3.12-.78-4.55,1.05-1.52,3.11-2.06,4.56-.89,2.97,2.39,5.83,4.92,8.65,7.48,1.31,1.19,2.45,2.55,3.61,3.89.95,1.1,1.11,2.36.45,3.68-.6,1.2-1.62,1.75-2.65,1.84Z" />
      <path fill="#1fa7df" d="m13.52,54.65c-.96.07-1.78-.39-2.46-1.15-3.04-3.39-6.21-6.63-9.87-9.36-1.35-1.01-1.56-3.15-.57-4.5,1.07-1.47,3.15-1.94,4.6-.77,2.55,2.07,4.99,4.29,7.4,6.53,1.27,1.18,2.4,2.5,3.53,3.82.91,1.06,1.11,2.32.46,3.59-.61,1.19-1.58,1.91-3.09,1.86Z" />
      <path fill="#1fa7df" d="m33.92,11.67c.2-.76.32-1.54.6-2.26.76-1.93,1.56-3.84,2.39-5.73.5-1.13,1.41-1.47,2.33-.95.87.5,1.05,1.54.35,2.5-1.28,1.77-2.58,3.52-3.91,5.25-.41.53-.94.95-1.42,1.43-.12-.07-.23-.15-.35-.22Z" />
      <path fill="#1fa7df" d="m28.83,10.41c-.27-1.12-.61-2.22-.79-3.35-.27-1.71-.45-3.43-.64-5.15C27.29.79,27.93.03,28.95,0c1.08-.03,1.7.6,1.58,1.81-.25,2.44-.56,4.88-.88,7.31-.06.43-.27.84-.4,1.26-.14,0-.27.02-.41.02Z" />
      <path fill="#1fa7df" d="m24.07,11.88c-.52-.43-.96-.69-1.26-1.06-1.54-1.92-3.06-3.84-4.54-5.81-.59-.78-.36-1.78.39-2.27.72-.47,1.83-.32,2.18.47,1.15,2.65,2.19,5.35,3.27,8.04.04.11-.01.25-.04.63Z" />
    </g>
  </Icon>
);
export const IconGive = (props) => (
  <Icon {...props} viewBox="0 0 73.59 61.24">
    <g>
      <path fill="#1fa7df" d="m36.94,5.04c2-2.45,4.5-4.08,7.71-4.68,7.15-1.33,12.3,4.28,10.34,11.28-1.15,4.1-3.63,7.31-6.59,10.22-1.87,1.84-3.82,3.59-5.74,5.37-1.92,1.78-3.85,3.55-6.25,5.76-3.74-3.94-7.51-7.91-11.28-11.86-1.48-1.55-3.15-2.94-4.42-4.64-2.22-2.98-3.25-6.34-2.05-10.07C20.74-.03,28.31-2.05,33.99,2.35c1.03.8,1.93,1.75,2.96,2.69Z" />
      <path fill="#1fa7df" d="m10.17,25.85c2.53-1.45,4.41-.37,6.15,1.28,3.89,3.67,7.8,7.27,12.73,9.63,5.19,2.49,7.06,7.1,7.18,12.58.07,3.39.03,6.78.08,10.17.02,1.24-.57,1.72-1.73,1.72-5.18,0-10.35,0-15.53,0-1.21,0-1.7-.63-1.68-1.79.02-2.02-.14-4.06.05-6.06.13-1.37-.32-2.22-1.3-3.02-.92-.75-1.7-1.67-2.62-2.42C6.29,42.12,2.61,34.33.92,25.43c-.28-1.45-.7-2.89-.88-4.35-.27-2.22.93-4.14,2.74-4.63,1.73-.47,3.67.69,4.58,2.79.94,2.16,1.85,4.34,2.81,6.62Zm23.61,25.58c.02-5.15-.59-9.89-5.63-12.33-5.19-2.51-9.37-6.24-13.48-10.12-.75-.71-1.73-2.05-2.82-.97-1.04,1.04.17,2.05.9,2.87,2.6,2.94,5.22,5.87,7.85,8.78.6.66.98,1.43.11,1.92-.45.26-1.45.03-1.92-.34-.92-.74-1.6-1.75-2.43-2.6-4.21-4.33-8.1-8.83-9.72-14.88-.39-1.45-1.3-2.78-2.08-4.1-.22-.38-.94-.82-1.24-.72-.38.14-.66.78-.81,1.26-.12.36-.03.83.07,1.22.72,2.81,1.65,5.58,2.16,8.43.69,3.86,2.16,7.34,4.76,10.19,3.4,3.73,7.05,7.24,10.64,10.79.37.36,1.06.56,1.6.57,3.93.05,7.85.03,12.03.03Z" />
      <path fill="#1fa7df" d="m63.54,25.8c.94-2.21,1.84-4.34,2.74-6.46.96-2.24,2.75-3.33,4.69-2.84,1.85.46,2.91,1.99,2.55,4.48-.39,2.68-1.36,5.28-1.76,7.97-1.26,8.44-6.3,14.49-12.48,19.73-2.3,1.95-3.48,3.98-3,7,.2,1.22-.02,2.5.02,3.75.04,1.19-.47,1.81-1.66,1.81-5.24,0-10.48.04-15.72-.06-.53,0-1.49-.86-1.48-1.3.1-4.81.19-9.63.62-14.41.34-3.83,2.66-6.71,6-8.34,5.18-2.53,9.38-6.23,13.48-10.13,2.11-2,3.3-2.23,6-1.18Zm-23.49,25.6c4.14,0,8.29.02,12.43-.03.41,0,.88-.34,1.21-.65,2.56-2.4,5.13-4.79,7.61-7.28,2.87-2.88,5.48-5.97,6.69-9.96.85-2.77,1.6-5.58,2.34-8.38.35-1.31.68-2.65.77-4,.05-.73-.43-1.49-.67-2.23-.65.5-1.57.87-1.89,1.52-.99,2.02-1.69,4.17-2.61,6.22-.85,1.88-1.59,3.91-2.85,5.5-2.46,3.12-5.25,5.99-7.91,8.95-.62.69-1.31,1.39-2.24.53-.99-.92-.14-1.59.44-2.24,2.26-2.53,4.53-5.04,6.79-7.58.63-.71,1.32-1.4,1.77-2.22.23-.43,0-1.11-.02-1.67-.55.03-1.2-.11-1.63.12-.66.36-1.19.96-1.75,1.49-3.64,3.43-7.32,6.85-11.89,9.01-4.74,2.24-6.91,5.44-6.58,12.89Z" />
    </g>
  </Icon>
);
export const IconLinkOut = (props) => (
  <Icon {...props} viewBox="0 0 98.58 100">
    <g>
      <path d="m49.29,0c-65.72,1.12-65.71,98.88,0,100,65.72-1.12,65.71-98.88,0-100Zm18.54,63.83c-.02,1.77.04,3.56-.16,5.31-.47,4.11-4.27,7.92-8.36,8.51-.81.12-1.64.17-2.46.17-4.84.02-9.68,0-14.53,0v-.04c-5.29,0-10.57.07-15.86-.02-4.65-.08-9-4.19-9.54-8.82-.06-.52-.12-1.05-.12-1.58,0-10.04-.02-20.08,0-30.12.01-4.55,2.79-8.45,7.08-9.87,1.12-.37,2.36-.54,3.55-.55,8.31-.05,16.62-.03,24.93-.02,1.18,0,1.55.37,1.56,1.55,0,.59.02,1.18,0,1.78-.04.91-.43,1.28-1.35,1.32-.19,0-.38,0-.57,0-8.14,0-16.28.01-24.42,0-2.53,0-4.43,1.05-5.53,3.31-.4.82-.58,1.83-.58,2.75-.04,9.87-.03,19.74-.02,29.61,0,2.97,1.62,5.13,4.36,5.86.56.15,1.17.2,1.75.21,9.83.01,19.66.01,29.49,0,3,0,5.2-1.64,5.95-4.44.15-.58.19-1.21.19-1.82.02-3.57,0-7.14,0-10.72,0-1.25.33-1.57,1.57-1.58.51,0,1.01,0,1.52,0,1.17.01,1.52.35,1.52,1.51,0,2.56.02,5.12,0,7.67Zm13.94-20.29c-.39.5-.7,1.12-1.2,1.49-.87.64-2.01.4-2.91-.5-1.9-1.89-3.8-3.79-5.69-5.69-.16-.16-.28-.37-.55-.72-.24.34-.36.57-.54.75-7.54,7.55-15.09,15.1-22.64,22.64-.15.15-.29.31-.46.44-.57.47-1.11.49-1.64-.03-1.38-1.34-2.74-2.7-4.08-4.08-.54-.55-.52-1.11-.04-1.7.15-.18.32-.34.49-.5,7.52-7.52,15.04-15.03,22.56-22.54.18-.18.42-.29.66-.45-.29-.31-.44-.49-.6-.65-1.91-1.91-3.84-3.82-5.74-5.74-1.17-1.18-1.07-2.82.24-3.64.42-.26.99-.4,1.49-.4,6.02-.03,12.05,0,18.07-.03,1.33,0,2.12.66,2.58,1.83v19.53Z" fill="#30a8df" />
    </g>
  </Icon>
);
