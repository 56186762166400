import * as StateIcons from "components/Icon/State";
import { createElement, useEffect, useState } from "react";
import _ from "lodash";

const useCampaignStatusIconUpdater = (campaignStatus) => {
  const [campaignStatusIcon, setCampaignStatusIcon] = useState(null);

  useEffect(() => {
    if (campaignStatus) {
      const newStatusIcon = getStatusIcon(_.kebabCase(campaignStatus));
      setCampaignStatusIcon(newStatusIcon);
    }
  }, [campaignStatus]);

  return {
    campaignStatusIcon,
  };
};

const getStatusIcon = (campaignStatus) => {
  const iconProps = {
    fillRule: "evenodd",
    viewBox: "0 0 100 100",
  };

  let statusIcon;
  switch (campaignStatus?.toLowerCase()) {
    case "draft":
      statusIcon = createElement(StateIcons.IconStateDraft, iconProps);
      break;
    case "pending-approval":
      statusIcon = createElement(StateIcons.IconStatePending, iconProps);
      break;
    case "denied":
      statusIcon = createElement(StateIcons.IconStateDenied, iconProps);
      break;
    case "live":
      statusIcon = createElement(StateIcons.IconStateLive, iconProps);
      break;
    case "completed":
      statusIcon = createElement(StateIcons.IconStateComplete, iconProps);
      break;
    case "archived":
      statusIcon = createElement(StateIcons.IconStateArchived, iconProps);
      break;
    case "redacted":
      statusIcon = createElement(StateIcons.IconStateRedacted, iconProps);
      break;

    default:
      break;
  }
  return statusIcon;
};

export default useCampaignStatusIconUpdater;
