import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
const useGetLocaleTimeUnits = () => {
    const { translate: t } = useLanguageProvider();
    return {
        minutes: t('minutes'),
        hours: t('hours'),
        days: t('days'),
        weeks: t('weeks'),
        months: t('months'),
        years: t('years'),
    };
};
export default useGetLocaleTimeUnits;
