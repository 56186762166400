import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import Avatar from "components/Avatar";
import { IconAlert } from "components/Icon";
import DateDiffForHumans from "helpers/DateDiffForHumans";

import "./_style.scss";

const CommentItem = ({
  id,
  image,
  isAnonymous,
  isDisabled,
  isLoading,
  isPending,
  isResendingLink,
  isResendLinkFail,
  isResendLinkSent,
  name,
  parentCommentId,
  timestamp,
  isCampaignUpdate = 0, replyAction, campaignId, resendEmailAction, commentText,
}) => {
  const { translate: t } = useLanguageProvider();
  const fuzzyTimestamp = DateDiffForHumans(moment(timestamp));

  const replyHandler = (event) => {
    const replyToId = Number.parseInt(event.target.value, 10);
    const replyToName = event.target.getAttribute("data-name");
    replyAction(replyToId, replyToName);
  };

  const resendEmailHandler = (event) => {
    const formData = {
      campaign_id: campaignId,
      comment_id: event.target.value,
    };

    resendEmailAction(formData);
  };

  const createCommentMarkup = () => ({
    __html: commentText,
  });

  const createCommentPreviewMarkup = () => ({
    __html: `&ldquo;${commentText}&rdquo;`,
  });

  return (
    <div
      className={
          classNames({
            "comment-item": true,
            "--is-anonymous": isAnonymous === true,
            "--is-pending": isPending === true,
            "--is-campaign-update": isCampaignUpdate === 1,
            "--v2": true,
          })
        }
      id={id}
    >
      {(isPending === true)
          && (
            <div className="comment-item__pending-header">
              <IconAlert />
              {" "}
              {t("This comment is pending!")}
            </div>
          )}
      <div className="comment-item__body">
        {(isPending === true)
            && (
            <div className="comment-item__pending-message">
              {t("We'd love you to join the conversation, please check your email for a link to post this comment:")}
            </div>
            )}
        <div className="comment-item__comment">
          <div className="comment-item__comment-media">
            <Avatar altText={name} image={image} size="small" v2 />
          </div>
          <div className="comment-item__comment-body">
            <div className="comment-item__bubble">
              {(isPending === true)
                  && (
                  <div className="comment-item__comment-copy">
                    <p
                      className="comment-item__comment-html"
                      dangerouslySetInnerHTML={createCommentPreviewMarkup()}
                    />
                  </div>
                  )}
              {(isPending !== true)
                  && (
                  <div className="comment-item__comment-copy">
                    {(isLoading === true)
                      && (
                      <div className="placeholder">
                        <div className="placeholder__text" />
                        <div className="placeholder__text" />
                        <div className="placeholder__text" />
                      </div>
                      )}
                    {(isLoading !== true)
                      && (
                      <div>
                        <p className="comment-item__name">{name}</p>
                        <p
                          className="comment-item__comment-html"
                          dangerouslySetInnerHTML={createCommentMarkup()}
                        />
                      </div>
                      )}
                  </div>
                  )}
            </div>
            {(isPending !== true && isLoading !== true)
                && (
                <ul className="comment-item__meta">
                  <li className="comment-item__meta-item">
                    <p className="--small" title={`Posted on ${timestamp}`}>{fuzzyTimestamp}</p>
                  </li>
                  <li className="comment-item__meta-item --action-link">
                    <button
                      type="button"
                      className="comment-item__action-link button"
                      disabled={isDisabled}
                      data-name={name}
                      onClick={replyHandler}
                      value={parentCommentId === null ? id : parentCommentId}
                    >
                      {t("Reply")}
                    </button>
                  </li>
                </ul>
                )}
          </div>
        </div>
      </div>
      {(isPending === true
          && isResendingLink === false
          && isResendLinkFail !== true
          && isResendLinkSent !== true)
          && (
          <div className="comment-item__pending-footer">
            {t("Didn't get the link?")}
            <button
              type="button"
              className="comment-item__action-link comment-item__pending-link"
              disabled={isResendingLink}
              onClick={resendEmailHandler}
              value={id}
            >
              {t("Resend")}
            </button>
            &nbsp;the email.
          </div>
          )}
      {(isPending === true && isResendingLink === true)
          && (
          <div className="comment-item__pending-footer">
            {t("Resending the email...")}
          </div>
          )}
      {(isPending === true && isResendingLink === false && isResendLinkSent === true)
          && (
          <div className="comment-item__pending-footer">
            {t("Email with a link to publish your comment has been resent.")}
          </div>
          )}
      {(isPending === true && isResendingLink === false && isResendLinkFail === true)
          && (
          <div className="comment-item__pending-footer">
            {t("There was a problem resending the email. Please reload the page and try again.")}
          </div>
          )}
    </div>
  );
};

CommentItem.propTypes = {
  campaignId: PropTypes.number, // Required for resendEmailAction
  commentText: PropTypes.string.isRequired,
  email: PropTypes.string,
  id: PropTypes.number.isRequired,
  image: PropTypes.string,
  isAnonymous: PropTypes.bool,
  isCampaignUpdate: PropTypes.number,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPending: PropTypes.bool,
  isResendingLink: PropTypes.bool,
  isResendLinkFail: PropTypes.bool,
  isResendLinkSent: PropTypes.bool,
  name: PropTypes.string.isRequired,
  parentCommentId: PropTypes.number,
  replyAction: PropTypes.func,
  resendEmailAction: PropTypes.func,
  timestamp: PropTypes.string.isRequired,
};

export default CommentItem;
