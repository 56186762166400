import React from "react";
import LinkButton from "components/LinkButton";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const Default = ({
  campaignTitle,
  handleSwitch,
  createCommentMarkup,
}) => {
  const { translate: t } = useLanguageProvider();
  return (
    <div className="comment-modal__content">
      <div className="comment-modal__body">
        <div className="comment-modal__preview">
          <div className="comment-modal__preview-intro">
            <div className="comment-modal__campaigner-name" dangerouslySetInnerHTML={{ __html: campaignTitle }} />
            { t("Would love to read your comment")}
            :
          </div>
          <div
            className="comment-modal__preview-comment"
            dangerouslySetInnerHTML={createCommentMarkup()}
          />
          <div className="comment-modal__preview-actions">
            <div className="comment-modal__action-text">
              { t("You can post it live via") }
              :
            </div>
            <div className="comment-modal__action-buttons">
              <LinkButton
                isBold
                look="primary"
                onClick={() => handleSwitch("signup")}
              >
                { t("Sign up") }
              </LinkButton>
              <LinkButton
                isBold
                look="secondary-outline"
                onClick={() => handleSwitch("login")}
              >
                { t("Log in") }
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Default;
