export const modalStyleProp = (type = "dark") => ({
  content: {
    background: "#fff",
    maxWidth: window.innerWidth > 700 ? "900px" : "95vw",
    overflow: "auto",
    textAlign: "left",
    width: "100vw",
    maxHeight: window.innerWidth > 700 ?? "85vh",
    height: "auto",
    padding: "0",
    margin: "0",
    boxShadow: "none",
  },
  overlay: {
    backgroundColor: type === "none" ? "transparent" : "rgba(51,51,51,.8)",
    overflowY: "auto",
    transform: "translate3d(0, 0, 0)",
    zIndex: 210,
  },
});
