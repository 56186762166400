import PropTypes from "prop-types";
import React from "react";
import Avatar from "../Avatar";
import { IconMail } from "../Icon";

import "./_style.scss";

const UserListing = ({
  profileImage,
  fullName,
  contact,
  contactModal,
  createdAt,
  offlineDonation,
  amount,
}) => (
  <div className="user-listing" data-testid="user-listing">
    <div className="user-listing__image">
      <Avatar image={profileImage || null} size="small" altText="User Icon" v2 />
    </div>
    <div className="user-listing__info">
      <p>{fullName}</p>
      <div className="user-listing__info-additional">
        {createdAt && <p>{createdAt}</p>}
        {offlineDonation && <p>{offlineDonation}</p>}
      </div>
    </div>

    <p data-test="user-listing__amount" className="user-listing__amount">
      {amount}
    </p>

    {contact && (
      <a
        className="user-listing__contact-icon"
        onClick={(e) => {
          contactModal(e);
        }}
        data-test="user-listing-contact-button"
      >
        <IconMail />
      </a>
    )}
  </div>
);

UserListing.propTypes = {
  profileImage: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  contact: PropTypes.bool,
  createdAt: PropTypes.string,
  offlineDonation: PropTypes.string,
  amount: PropTypes.string,
};

export default UserListing;
