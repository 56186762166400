import React from "react";

import PropTypes from "prop-types";

import LinkButton from "components/LinkButton";
import LinkButtonGroup from "components/LinkButtonGroup";

import "./_style.scss";

const ModalClose = ({
  closeAction,
  backAction,
  includeBackButton = false,
  display = "--primary",
  size = "regular",
}) => (
  includeBackButton
    ? (
      <div className="modal-close__content modal-close__button-group">
        <LinkButtonGroup>
          <LinkButton
            isDisabled={false}
            look="plain"
            isIcon
            onClick={backAction}
          >
            <label
              className="modal-close__share-label"
              htmlFor="Modal Back"
            >
              <div>
                <span
                  data-test="modal-close-back"
                  className="modal-close__content-share --back"
                >
                  &lt; Back
                </span>
              </div>
            </label>
          </LinkButton>

          <LinkButton
            isDisabled={false}
            look="plain"
            isIcon
            onClick={closeAction}
          >
            <label
              className="modal-close__share-label"
              htmlFor="Modal Close"
            >
              <span className={`modal-close__share-toggle-icon --${size}`} />
              <div>
                <span
                  className={`modal-close__content-share --close ${display} --${size}`}
                >
                  Close
                </span>
              </div>
            </label>
          </LinkButton>
        </LinkButtonGroup>
      </div>
    )
    : (
      <div className="modal-close__content">
        <LinkButton
          isDisabled={false}
          look="plain"
          isIcon
          onClick={closeAction}
        >
          <label
            className="modal-close__share-label"
            htmlFor="Modal Share Label Close"
          >
            <span className={`modal-close__share-toggle-icon ${display} --${size}`} />
            <div>
              <span
                data-test="modal-close"
                className={`modal-close__content-share --close ${display} --${size}`}
              >
                Close
              </span>
            </div>
          </label>
        </LinkButton>
      </div>
    )
);

ModalClose.propTypes = {
  closeAction: PropTypes.func.isRequired,
  backAction: PropTypes.func,
  includeBackButton: PropTypes.bool,
  display: PropTypes.string,
  size: PropTypes.string,
};

export default ModalClose;
