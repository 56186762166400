import React, { useCallback, useRef } from "react";
import { fetchAbsolute as router } from "helpers/router";
import Modal from "components/Modal";
import ModalClose from "components/ModalClose";
import ModalContent from "components/ModalContent";
import LinkButton from "../LinkButton";

import "./_style.scss";

const CampaignEmbed = ({
  campaignId,
  modalStyles,
  embedModalOpen = false,
  shareModalAction = () => {},
  closeEmbedModalOnly,
}) => {
  const textArea = useRef();
  const copyToClipboard = useCallback(() => {
    textArea.current.select();
    document.execCommand("copy");
    textArea.current.focus();
  }, [textArea]);
  const embedRoute = router("web.campaign.view.iframe", {
    campaign: campaignId,
    color: "25aae1",
  });
  const iframeLink = `<iframe width="100%" height="380" src="${embedRoute}" frameborder="0" allowfullscreen style="max-width:310px;border:0px solid #fff;margin:0 auto;"></iframe>`;
  return (
    <section className="campaign-embed">
      <Modal isOpen={embedModalOpen} contentLabel="Embed Label Modal" customStyle={modalStyles} data-test="campaign-page-embed-modal">
        <ModalClose closeAction={shareModalAction} backAction={closeEmbedModalOnly} includeBackButton />

        <ModalContent
          body={(
            <div className="campaign-embed__widget-container">
              <h3>Embed a Chuffed Campaign widget into your website</h3>
              <p>Copy and paste this code snippet into your website:</p>
              <textarea ref={(textarea) => { textArea.current = textarea; }} defaultValue={iframeLink} />
              <LinkButton look="secondary-outline" size="small" onClick={copyToClipboard}>
                Copy HTML Code
              </LinkButton>

              <div className="campaign-embed__widget-preview">
                <p>What the widget will look like</p>

                <div
                  className="campaign-embed__widget-preview-iframe"
                  dangerouslySetInnerHTML={{
                    __html: iframeLink,
                  }}
                />
              </div>
            </div>
)}
          fullWidth
        />
      </Modal>
    </section>
  );
};

export default CampaignEmbed;
