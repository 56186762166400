/* eslint-disable no-underscore-dangle */
const AnimatedNumber = require("./AnimatedNumber");

const AnimatedNumber2 = interopRequireDefault(AnimatedNumber);

function interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

module.exports = AnimatedNumber2.default;
