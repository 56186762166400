import React from "react";
import "./skeleton.scss";

const Skeleton = () => (
  <section>
    <div className="box-placeholder">
      <div className="box-placeholder__main-content">
        <div className="hero-image-placeholder image" />
        <hr className="no-border" />
        <div className="information-sections" />
        <hr className="no-border" />
        <div className="p-4">
          <h1 className="text" />
          <span className="category text link" />
          <h4 className="text line" />
          <h4 className="text" />
        </div>
        <hr />
        <div className="image" />
        <hr />
        <div className="excerpt p-4">
          <div className="text line" />
          <div className="text line" />
          <div className="text" />
        </div>
        <hr />
      </div>
      <div className="box-placeholder__sticky-donate">
        <div className="image sticky-donate-image" />
      </div>
    </div>
  </section>
);

export default Skeleton;
