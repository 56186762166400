import { getPluralIndex } from '../contrib/getPluralIndex';
export default function pluralization(key, count, locale) {
    const keySegments = key.split('|');
    const extractedSegment = extractKeySegmentUsingConditions(keySegments, count);
    if (extractedSegment !== null) {
        return extractedSegment.trim();
    }
    return extractKeySegmentFromCount(keySegments, count, locale);
}
function extractKeySegmentFromCount(keySegments, count, locale) {
    const cleanedSegments = stripSegmentConditions(keySegments);
    const pluralIndex = getPluralIndex(locale, count);
    if (cleanedSegments.length === 1 || !cleanedSegments[pluralIndex]) {
        return cleanedSegments[0];
    }
    return cleanedSegments[pluralIndex];
}
/**
 * Extract a translation string using inline conditions.
 */
function extractKeySegmentUsingConditions(keySegments, count) {
    let result = null;
    keySegments.forEach((segment) => {
        if (result !== null)
            return;
        result = extractSegmentUsingCondition(segment, count);
    });
    return result;
}
function extractSegmentUsingCondition(segment, count) {
    const matches = segment.match(/^[{[]([^[\]{}]*)[}\]](.*)/s) || [];
    if (!(matches === null || matches === void 0 ? void 0 : matches[1]) && !(matches === null || matches === void 0 ? void 0 : matches[2])) {
        return null;
    }
    const condition = matches[1];
    const value = matches[2];
    if (condition.includes(',')) {
        const [from, to] = condition.split(',');
        if ((to === '*' && count >= parseFloat(from))
            || (from === '*' && count <= parseFloat(to))
            || (count >= parseFloat(from) && count <= parseFloat(to))) {
            return value;
        }
    }
    return parseFloat(condition) === count ? value : null;
}
function stripSegmentConditions(segments) {
    return segments.map((part) => part.replace(/^[{[]([^[\]{}]*)[}\]]/, ''));
}
