export const getViewportType = (width) => (width < 400 ? "mobile" : (width < 900 ? "tablet" : "desktop"));

export const imageProfileUrl = (imageUrl, profileName) => {
  if (!imageUrl || !profileName) {
    return imageUrl;
  }

  if (imageUrl.indexOf("?") === -1) {
    return `${imageUrl}?profile=${profileName}`;
  }

  const urlParams = new URLSearchParams(imageUrl.substring(imageUrl.indexOf("?")));
  urlParams.set("profile", profileName);

  return `${imageUrl.substring(0, imageUrl.indexOf("?"))}?${urlParams.toString()}`;
};
