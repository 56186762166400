import React from "react";
import "utils/sentry";
import * as Sentry from "@sentry/react";
import { ApolloProvider } from "@apollo/client";
import { client } from "helpers/apolloClientLink";
import { render } from "react-dom";
import { Provider } from "react-redux";
import LanguageProvider from "components/LanguageProvider/LanguageProvider";
import useGetTranslationFiles from "components/LanguageProvider/utils/getTranslationFiles";
import ErrorFallback from "components/ErrorFallback/ErrorFallback";
import configureStore from "./store/configureStore";
import CampaignPage from "./campaignPage";

const store = configureStore();

const {
  campaignId,
  challengerId,
  paymentUrl,
  reportUrl,
  user,
  teamsLeaderboard,
  fundraiserSignup,
  fundraisersLeaderboard,
  isCommentsEnabled,
} = window.Chuffed.campaignInit;

const type = window.Chuffed.campaignInit.type || null;
const fundraiserUserImage = window.Chuffed.campaignInit.fundraiserUserImage || null;
const fundraiserId = window.Chuffed.campaignInit.challengerId || null;
const Campaign = () => {
  const { translations } = useGetTranslationFiles();
  return (
    <Provider store={store}>
      <LanguageProvider translations={translations}>
        <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
          <CampaignPage
            campaignId={campaignId}
            challengerId={challengerId}
            paymentUrl={paymentUrl}
            reportUrl={reportUrl}
            user={user}
            teamsLeaderboard={teamsLeaderboard}
            fundraiserSignup={fundraiserSignup}
            fundraisersLeaderboard={fundraisersLeaderboard}
            type={type}
            fundraiserUserImage={fundraiserUserImage}
            fundraiserId={fundraiserId}
            isCommentsEnabled={isCommentsEnabled}
          />
        </Sentry.ErrorBoundary>
      </LanguageProvider>
    </Provider>
  );
};

render(<ApolloProvider client={client}><Campaign /></ApolloProvider>, document.getElementById("js-campaign-base"));
