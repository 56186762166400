export const getIconAriaData = (label) => {
    const aria = {};
    if (label) {
        aria['aria-label'] = label;
    }
    else {
        aria['aria-hidden'] = 'true';
    }
    return aria;
};
export const getIconDimensions = (height, width) => {
    if (height && width) {
        return { height, width };
    }
    if (!height && width) {
        return { height: width, width };
    }
    if (height && !width) {
        return { height, width: height };
    }
    return {
        height: '1em',
        width: '1em',
    };
};
