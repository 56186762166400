import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import "./_style.scss";

const Image = (props) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef();

  useEffect(() => {
    const imgSrc = imgRef.current.getAttribute("src");
    const img = new global.Image();
    img.onLoad = onImageLoad;
    img.src = imgSrc;
  });

  const onImageLoad = () => {
    setLoaded(true);
  };

  const { className, ...altProps } = props;

  return (
    <img
      ref={imgRef}
      alt="Description of image"
      {...altProps}
      className={
        classNames(
          "image",
          className,
          {
            "--loaded": loaded,
          },
        )
      }
    />
  );
};

export default Image;
