import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { format as currencyFormat } from 'currency-formatter';
import AnimatedNumber from 'components/AnimatedNumber';
import './_style.scss';
import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
const ProgressMeter = ({ currency = null, currencySymbol = '$', raised = 0, target = 0, }) => {
    const { translate: t } = useLanguageProvider();
    const [width, setWidth] = useState(0);
    const [raisedAmount, setRaisedAmount] = useState(0);
    useEffect(() => {
        setWidth((raised / target) * 100);
        setRaisedAmount(raised);
    }, [raised, target]);
    return (_jsxs("div", { className: classNames({
            'progress-meter': true,
        }), children: [_jsx("div", { className: "progress-meter__bar", "data-testid": "animated-progress-bar", children: _jsx("div", { className: "progress-meter__fill", style: { width: `${width}%` } }) }), _jsxs("div", { className: "progress-meter__values", children: [_jsx("div", { className: "progress-meter__value-raised", "data-testid": "progress-meter-raised", children: _jsx(AnimatedNumber, { duration: 2800, formatValue: (n) => (currency
                                ? `${currencyFormat(n, { code: currency, precision: 0 })}`
                                : t(':total', { total: `${currencyFormat(n, { symbol: currencySymbol, precision: 0 })}` })), stepPrecision: 0, value: raisedAmount }) }), _jsx("div", { className: "progress-meter__value-target", "data-testid": "progress-meter-target", children: t('Raised of :total', {
                            total: currency !== null
                                ? currencyFormat(target, { code: currency, precision: 0 })
                                : `${currencySymbol}${target}`,
                        }) })] })] }));
};
export default ProgressMeter;
