import getCookie from 'components/LanguageProvider/utils/cookies';
import differenceDateTime from './dateTime';
export default function DateDiffForHumans(targetDate, startDate) {
    const locale = getCookie('language');
    let dateCount;
    let dateUnit;
    let isFutureDate;
    if (!targetDate) {
        return null;
    }
    try {
        ({
            dateCount, dateUnit, isFutureDate,
        } = differenceDateTime({ targetDate, startDate }));
    }
    catch (error) {
        console.error(error);
        return null;
    }
    if (dateCount === 0) {
        return null;
    }
    const rtf1 = new Intl.RelativeTimeFormat(locale !== null && locale !== void 0 ? locale : 'en');
    const relativeDateCount = isFutureDate ? dateCount : -dateCount;
    return rtf1.format(relativeDateCount, dateUnit);
}
