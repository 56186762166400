const statuses = {
  live: true,
  "approved-notlaunched": false,
  Completed: false,
  draft: false,
  denied: false,
  "pending-approval": false,
};

export const campaignEndTimeDisplay = (
  infinityMode,
  status,
  endsTime,
) => !infinityMode && endsTime !== null && statuses[status.toLowerCase()];
