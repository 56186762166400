import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren, matchRoutes, useLocation, useNavigationType,
} from "react-router-dom";

const getEnvPropagationTarget = () => [
  ...(process.env.NODE_ENV === "production" ? ["https://chuffed.org", "https://chuffed.org/api"] : []),
  ...(process.env.NODE_ENV === "staging" ? ["https://uat.chuffed.org", "https://uat.chuffed.org/api"] : []),
  ...(process.env.NODE_ENV === "development" ? [] : []),
];

Sentry.init({
  dsn: "https://4e6c807a9fa828c58da9fb87dd771bf1@o4508130513256448.ingest.de.sentry.io/4508130575384656",
  environment: process.env.NODE_ENV,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  replaysOnErrorSampleRate: 1.0,

  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: getEnvPropagationTarget(),
  // of transactions for tracing.
  tracesSampleRate: 1.0,
});

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);
