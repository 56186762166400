import React from "react";
import { fetch as router } from "helpers/router";
import LinkButton from "components/LinkButton";
import Modal from "components/Modal";
import ModalClose from "components/ModalClose";
import ModalContent from "components/ModalContent";
import { modalStyleProp } from "helpers/modalStyles";
import NumberedSteps from "components/NumberedSteps";
import useSegmentTracking from "hooks/useSegmentTracking";
import PropTypes from "prop-types";

const url = window.location.href;

export const LaunchModal = ({
  campaign,
  handleClose,
  isOpen,
  currentStep,
  stepCount,
}) => {
  const { track } = useSegmentTracking();
  const href = router("web.projectEditor", {
    campaign: campaign.id,
    route: "/launch",
  });

  const handleTrackEvent = () => {
    track("campaign_launched", null, {
      filteredUrl: url,
      source: "onboarding_complete_modal",
    });

    window.location.href = href;
  };
  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Step 3"
      customStyle={modalStyleProp()}
      data-test="prelaunch-final-modal"
    >
      <ModalClose closeAction={handleClose} />
      <ModalContent
        body={(
          <>
            <NumberedSteps
              orientation="landscape"
              stepCount={stepCount}
              currentStep={currentStep}
            />
            <div className="campaign-container__modal-share">
              <h1>Your campaign is ready to launch when you are!</h1>
              <h4>
                You can now take advantage of our feature-packed platform before
                going public.
              </h4>
              <p>
                You can add team members, set perks and impact levels, change the
                campaign duration, set up tax deductible receipts, add videos and
                images, finesse your campaign, and more.
              </p>
            </div>
          </>
      )}
        action={(
          <>
            <LinkButton
              onClick={handleTrackEvent}
              look="primary"
              isBold
            >
              I'll go public now
            </LinkButton>
            <LinkButton
              href={router("web.projectEditor", {
                campaign: campaign.id,
                route: "/organisation",
              })}
              look="secondary-outline"
              isBold
            >
              Keep setting up
            </LinkButton>
          </>
      )}
      />
    </Modal>
  );
};

LaunchModal.propTypes = {
  campaign: PropTypes.object,
  currentStep: PropTypes.number,
  handleClose: PropTypes.func,
  isOpen: PropTypes.bool,
  stepCount: PropTypes.number,
};

export default LaunchModal;
