import GET_CAMPAIGN from "queries/getCampaign.gql";
import { useQuery } from "@apollo/client";
import { useState } from "react";

const useGetCampaign = (campaignId) => {
  const [campaign, setCampaign] = useState(null);

  const { loading, error } = useQuery(GET_CAMPAIGN, {
    variables: {
      id: campaignId,
    },
    onCompleted(data) {
      if (data.campaign) {
        setCampaign(data.campaign);
      }
    },
  });

  return {
    loading,
    error,
    campaign,
  };
};

export default useGetCampaign;
