import { jsx as _jsx } from "react/jsx-runtime";
import sluggedText from 'pages/campaign/components/ViewMoreContentWrapper/helpers';
const useGetContent = ({ content, expandText, height, ref, }) => {
    if (typeof content === 'string') {
        return (_jsx("div", { "data-testid": sluggedText(expandText !== null && expandText !== void 0 ? expandText : 'Read more'), style: { height }, className: "story-inner", ref: ref, 
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML: { __html: content } }));
    }
    return (_jsx("div", { "data-testid": sluggedText(expandText !== null && expandText !== void 0 ? expandText : 'Read more'), style: { height }, className: "story-inner", ref: ref, children: content }));
};
export default useGetContent;
