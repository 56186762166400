import React, { useState } from "react";
import { modalStyleProp } from "helpers/modalStyles";
import LinkButton from "components/LinkButton";
import CampaignEmbed from "components/CampaignEmbed";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const EmbedHTML = ({
  campaignId, handleTracking,
}) => {
  const { translate: t } = useLanguageProvider();
  const [embedHtmlView, setEmbedHtmlView] = useState(false);
  const embedHtmlSelected = () => {
    setEmbedHtmlView(!embedHtmlView);
    if (!embedHtmlView) {
      handleTracking("HTML_embed");
    }
  };
  return (
    <>
      <LinkButton isBold look="plain" onClick={embedHtmlSelected}>
        <span className="shareBar__shareText --embedHtml">
          <i className="fa fa-code" />
          {t("Embed HTML")}
        </span>
      </LinkButton>
      {embedHtmlView && (
        <CampaignEmbed
          modalStyles={modalStyleProp("none")}
          embedModalOpen={embedHtmlView}
          shareModalAction={embedHtmlSelected}
          campaignId={campaignId}
          closeEmbedModalOnly={embedHtmlSelected}
        />
      )}
    </>
  );
};

export default EmbedHTML;
