import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import React from "react";

const InputGroupError = ({ errors, name }) => {
  const { translate: t } = useLanguageProvider();
  if (!errors.hasOwnProperty(name)) {
    return null;
  }
  return <span className="error" style={{ color: "red", fontSize: ".8em" }}>{ t(errors[name].message) }</span>;
};

export default InputGroupError;
