import {
  useCallback, useEffect, useState,
} from "react";
import useAuth from "widgets/Onboarding/hooks/useAuth";

const useSegmentTracking = () => {
  // const [user, setUser] = useState();
  const [userIdentified, setUserIdentified] = useState(false);
  const { user, loading: userAuthLoading } = useAuth();

  useEffect(() => {
    if (!userAuthLoading) {
      identifyUser();
    }
  }, [userAuthLoading, identifyUser]);

  const identifyUser = useCallback((id, traits) => {
    if (user) {
      traits = (user.email, { email: user.email });
    }
    if (window.analytics) {
      setUserIdentified(true);
      // empty args here are ok, segment creates an anonymousID and uses it to track the user
      return window.analytics.identify(id, { traits });
    }
  }, [user]);

  const track = (eventName, userData, properties) => {
    if (!window.analytics || !userIdentified) {
      return;
    }
    if (!userData && user) {
      userData = user;
    }

    const combined = {
      ...properties,
      user: { ...userData },
    };

    window.analytics.track(eventName, combined);
  };

  return {
    track,
    user,
  };
};
export default useSegmentTracking;
