import fetch from "isomorphic-fetch";
import { reset } from "redux-form";
import { fetch as router } from "helpers/router";
import request from "utils/request";
import { csrf } from "utils/requestHeaders";
// Helpers ----------------------

// the response either directly returns the data or wraps it in a data property
const getResponseData = (response) => response.data || response;

const getAPIUrl = (campaignId, challengerId) => {
  let apiPath = router("api.v2.campaigns.legacy-comments.index", { campaign: campaignId });

  if (challengerId !== null) {
    apiPath = router("api.v2.fundraisers.legacy-comments.index", { fundraiser: challengerId });
  }

  // Use fake REST API server if viewing component on Storybook
  if (location.hostname === "localhost" && location.port === "9001") {
    return `http://localhost:3000${apiPath}`;
  }

  return apiPath;
};

const createCommentActions = (formData, data) => {
  const actions = [];
  const formDataWithActions = { ...formData };
  const actionPostComment = {
    action: "post_comment",
    details: {
      content: data.content,
      campaign_id: data.campaignId,
      challenger_id: data.challengerId,
      reply_to_id: data.replyToId,
    },
  };
  actions.push(actionPostComment);
  formDataWithActions.actions = JSON.stringify(actions);

  return formDataWithActions;
};

// Initial State ----------------------

const initialState = {
  campaignId: null,
  campaignTitle: null,
  challengerId: null,
  commentModalOpen: false,
  commentModalStep: "default",
  comments: [],
  errorMessage: null,
  isCreating: false,
  isError: false,
  isLoading: false,
  isResendingLink: false,
  isResendLinkFail: false,
  isResendLinkSent: false,
  isSendingLink: false,
  isSendLinkFail: false,
  isSendLinkSent: false,
  pendingComment: null,
  replyToId: null,
  replyToName: null,
  signupEmail: null,
  signupName: null,
  user: {},
};

// Actions ----------------------

export const LOAD = "comments/LOAD";
const load = (campaignId, challengerId) => ({
  type: LOAD,
  data: {
    campaignId: campaignId || null,
    challengerId: challengerId || null,
  },
});

export const LOAD_SUCCESS = "comments/LOAD_SUCCESS";
const loadSuccess = (json) => ({
  type: LOAD_SUCCESS,
  data: json || [],
});

export const LOAD_FAILURE = "comments/LOAD_FAILURE";
const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  error,
});

export const CREATE = "comments/CREATE";
const create = () => ({
  type: CREATE,
});

export const CREATE_SUCCESS = "comments/CREATE_SUCCESS";
const createSuccess = (json) => ({
  type: CREATE_SUCCESS,
  data: json || [],
});

export const CREATE_FAILURE = "comments/CREATE_FAILURE";
const createFailure = (error) => ({
  type: CREATE_FAILURE,
  error,
});

export const LINK = "comments/LINK";
const link = () => ({
  type: LINK,
});

export const LINK_SUCCESS = "comments/LINK_SUCCESS";
const linkSuccess = (json) => ({
  type: LINK_SUCCESS,
  data: json || [],
});

export const LINK_FAILURE = "comments/LINK_FAILURE";
const linkFailure = (error) => ({
  type: LINK_FAILURE,
  error,
});

export const RESENDLINK = "comments/RESENDLINK";
const resendLink = () => ({
  type: RESENDLINK,
});

export const RESENDLINK_SUCCESS = "comments/RESENDLINK_SUCCESS";
const resendLinkSuccess = (json) => ({
  type: RESENDLINK_SUCCESS,
  data: json || [],
});

export const RESENDLINK_FAILURE = "comments/RESENDLINK_FAILURE";
const resendLinkFailure = (error) => ({
  type: RESENDLINK_FAILURE,
  error,
});

export const REPLY = "comments/REPLY";
const reply = (replyToId, replyToName) => ({
  type: REPLY,
  data: {
    replyToId: replyToId || null,
    replyToName: replyToName || null,
  },
});

export const MODAL = "comments/MODAL";
const modal = (isModalOpen) => ({
  type: MODAL,
  data: isModalOpen,
});

export const MODAL_STEP = "comments/MODAL_STEP";
const modalStep = (stepName) => ({
  type: MODAL_STEP,
  data: stepName,
});

export const EXISTINGUSER = "comments/EXISTINGUSER";
const existingUser = (data) => ({
  type: EXISTINGUSER,
  data,
});

export const fetchComments = (campaignId, challengerId) => (dispatch) => {
  dispatch(load(campaignId, challengerId));

  const url = getAPIUrl(campaignId, challengerId);

  return fetch(request(url))
    .then((response) => {
      if (response.ok !== true) {
        throw Error(response.statusText);
      }

      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      const responseData = getResponseData(json);

      dispatch(loadSuccess(responseData));
      return responseData;
    })
    .catch((error) => {
      dispatch(loadFailure(error));
    });
};

export const createComment = (data) => (dispatch) => {
  dispatch(create());

  let url;
  let formData = {};

  if (data.step === "signup") {
    // Comment via sign up modal
    url = router("auth.signup.ajax");

    formData.name = data.name;
    formData.email = data.email;
    formData.password = data.password;

    formData = createCommentActions(formData, data);
  } else if (data.step === "login") {
    // Comment via log in modal
    url = router("auth.login.ajax");

    formData.email = data.email;
    formData.password = data.password;

    formData = createCommentActions(formData, data);
  } else {
    // Comment when logged in
    url = router("web.user.postComment", { campaign: data.campaign_id });
    formData = data;
  }

  const req = request(url, "POST", csrf(document), formData);

  return new Promise((resolve, reject) => {
    fetch(req)
      .then((response) => {
        if (!response.ok) {
          throw Error("You\'re unable to post a comment at this time");
        }
        return response;
      })
      .then((response) => response.json())
      .then((json) => {
        const responseData = getResponseData(json);
        if (responseData.success === true) {
          // Reload page if user is on login modal
          if (data.step === "login") {
            if (window.location.href.indexOf("#comments") < 0) {
              window.location.href = `${window.location.href}#comments`;
            }

            window.location.reload();
          } else {
            // Otherwise continue with updating state with latest comments, close modal etc.
            responseData.isModalOpen = data.hasOwnProperty("step") === true;
            dispatch(createSuccess(responseData));
            dispatch(reset("comment"));
            resolve(responseData);
          }
        } else if (responseData.error.message === "The account already exists") {
          dispatch(existingUser(data));
        } else {
          dispatch(createFailure(responseData.error));
        }
      })
      .catch((error) => {
        dispatch(createFailure(error));
        reject(error);
      });
  });
};

export const sendEmailLink = (data) => (dispatch) => {
  dispatch(link());

  const url = router("web.postLinkComment", { campaign: data.campaign_id });

  const req = request(url, "POST", csrf(document), data);

  return new Promise((resolve, reject) => {
    fetch(req)
      .then((response) => response.json())
      .then((json) => {
        const responseData = getResponseData(json);
        if (responseData.success === true) {
          dispatch(linkSuccess(responseData));
          dispatch(reset("comment"));
          resolve(responseData);
        } else {
          dispatch(linkFailure());
        }
      })
      .catch((error) => {
        dispatch(linkFailure(error));
        reject(error);
      });
  });
};

export const replyComment = (replyToId, replyToName) => (dispatch) => {
  dispatch(reply(replyToId, replyToName));
};

export const resendEmailLink = (data) => (dispatch) => {
  dispatch(resendLink());

  const url = router("web.postRelinkComment", { campaign: data.campaign_id });

  const req = request(url, "POST", csrf(document), data);

  return new Promise((resolve, reject) => {
    fetch(req)
      .then((response) => response.json())
      .then((json) => {
        const responseData = getResponseData(json);
        if (responseData.success === true) {
          dispatch(resendLinkSuccess());
          resolve(responseData);
        } else {
          dispatch(resendLinkFailure());
        }
      })
      .catch((error) => {
        dispatch(resendLinkFailure(error));
        reject(error);
      });
  });
};

export const setModalStatus = (isModalOpen) => (dispatch) => {
  dispatch(modal(isModalOpen));
};

export const setModalStep = (stepName) => (dispatch) => {
  dispatch(modalStep(stepName));
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        campaignId: action.data.campaignId,
        challengerId: action.data.challengerId,
        isLoading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        comments: action.data.comments,
        pendingComment: action.data.pending_comment,
      };
    case CREATE:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_SUCCESS:
      return {
        ...state,
        commentModalOpen: action.data.isModalOpen,
        commentModalStep: action.data.isModalOpen ? "thankyou" : "default",
        comments: action.data.comments,
        isCreating: false,
        pendingComment: action.data.pending_comment,
        replyToId: null,
        replyToName: null,
      };
    case CREATE_FAILURE:
      return {
        ...state,
        errorMessage: action.error.message,
        isCreating: false,
        isError: true,
      };
    case REPLY:
      return {
        ...state,
        replyToId: action.data.replyToId,
        replyToName: action.data.replyToName,
      };
    case RESENDLINK:
      return {
        ...state,
        isResendingLink: true,
      };
    case RESENDLINK_SUCCESS:
      return {
        ...state,
        isResendingLink: false,
        isResendLinkFail: false,
        isResendLinkSent: true,
      };
    case RESENDLINK_FAILURE:
      return {
        ...state,
        isResendingLink: false,
        isResendLinkFail: true,
        isResendLinkSent: false,
      };
    case LINK:
      return {
        ...state,
        isSendingLink: true,
      };
    case LINK_SUCCESS:
      return {
        ...state,
        commentModalOpen: true,
        commentModalStep: "thankyou",
        comments: action.data.comments,
        pendingComment: action.data.pending_comment,
        replyToId: null,
        replyToName: null,
      };
    case LINK_FAILURE:
      return {
        ...state,
        isSendingLink: false,
        isSendLinkFail: true,
        isSendLinkSent: false,
      };
    case MODAL:
      return {
        ...state,
        commentModalOpen: action.data,
        // Reset modal step back to default if dismissing modal
        commentModalStep: (action.data === false) ? "default" : state.commentModalStep,
        errorMessage: null,
        isError: false,
      };
    case MODAL_STEP:
      return {
        ...state,
        commentModalStep: action.data,
        errorMessage: null,
        isError: false,
      };
    case EXISTINGUSER:
      return {
        ...state,
        commentModalOpen: true,
        commentModalStep: "existing",
        isCreating: false,
        signupEmail: action.data.email,
        signupName: action.data.name,
      };
    default:
      return state;
  }
}
