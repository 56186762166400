import React from "react";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const ModalLoginContainer = ({
  type,
  modalTitle,
  isError,
  errorMessage,
  modalSubheading,
  switchLoginOrSignupHandler,
  children,
}) => {
  const { translate: t } = useLanguageProvider();
  const footerAction = (modalType) => {
    if (modalType === "signup") {
      return (
        <div className="comment-modal__footer-action">
          {t("Already have an account?")}
          &nbsp;
          <button
            type="button"
            className="comment-modal__footer-link"
            onClick={switchLoginOrSignupHandler}
          >
            {t("Login")}
          </button>
          {" "}
          {t("instead.")}
        </div>
      );
    }
    return (
      <div className="comment-modal__footer-action">
        {t("New to Chuffed?")}
        &nbsp;
        <button
          type="button"
          className="comment-modal__footer-link"
          onClick={switchLoginOrSignupHandler}
        >
          {t("Signup")}
        </button>
        {" "}
        {t("instead.")}
      </div>
    );
  };

  return (
    <div className="comment-modal__content">
      <div className="comment-modal__header">
        <div className="comment-modal__heading">{modalTitle}</div>
      </div>
      <div className="comment-modal__body">
        {(isError === true || errorMessage)
          && <div className="comment-modal__error-message">{errorMessage}</div>}
        <div className="comment-modal__action-text">{modalSubheading}</div>
        {
          children
        }
      </div>
      <div className="comment-modal__footer">
        {footerAction(type)}
      </div>
    </div>
  );
};

export default ModalLoginContainer;
