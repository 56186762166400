import React, { Component } from "react";
import PropTypes from "prop-types";

import ReactPlayer from "react-player";

import { getViewportType, imageProfileUrl } from "helpers/imageProfile";

import "./_style.scss";

class CampaignHero extends Component {
  static propTypes = {
    imageAltText: PropTypes.string,
    imageHref: PropTypes.string,
    videoHref: PropTypes.string,
  };

  state = {
    hasPlayedVideo: false,
  };

  handlePlay = () => {
    this.setState({
      hasPlayedVideo: true,
    });
  };

  render() {
    const {
      imageHref, imageAltText, videoHref, ...other
    } = this.props;

    const campaignHeroClass = "campaign-hero campaign-hero__v2";
    const campaignPlayClass = "campaign-hero__play campaign-hero__play__v2";

    const viewport = getViewportType(window.innerWidth);
    const imageUrl = imageProfileUrl(imageHref, `campaign.header_${viewport}`);

    return (
      <div className={campaignHeroClass} {...other}>
        <div className="campaign-hero__container">
          {videoHref ? (
            <div
              className="campaign-hero__image"
              style={{ backgroundImage: `url('${imageUrl}')` }}
              data-test="campaign-hero-video-image"
            >
              {this.state.hasPlayedVideo === false ? (
                <button
                  type="button"
                  className={campaignPlayClass}
                  onClick={this.handlePlay}
                  data-testid="campaign-video-play"
                >
                  Play
                  <span className="campaign-hero__play-icon" />
                </button>
              ) : (
                <div className="campaign-hero__video" data-testid="campaign-video-display">
                  <ReactPlayer
                    className="campaign-hero__video-tag"
                    controls
                    height="100%"
                    playing
                    width="100%"
                    url={videoHref}
                  />
                </div>
              )}
            </div>
          ) : (
            <div
              className="campaign-hero__image"
              style={{ backgroundImage: `url('${imageUrl}')` }}
              data-test="campaign-hero-image"
            >
              <img className="campaign-hero__image-tag" src={imageUrl} alt={imageAltText} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CampaignHero;
