import { modalStyleProp } from "helpers/modalStyles";
import ModalClose from "components/ModalClose";
import ModalContent from "components/ModalContent";
import ShareButtons from "components/ShareButtons";
import Modal from "components/Modal";
import PropTypes from "prop-types";
import React from "react";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const ShareModal = ({
  campaignId, challengerId, isOpen, shareAction,
}) => {
  const { translate: t } = useLanguageProvider();

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Content Label Modal"
      customStyle={modalStyleProp()}
      data-test="campaign-page-share-modal"
    >
      <ModalClose closeAction={shareAction} />
      <ModalContent
        body={(
          <div className="campaign-container__modal-share --top-bottom">
            <ShareButtons
              heading={t("Help us spread the word")}
              shareBarClose={shareAction}
              url={document.URL}
              emailShareText="Email"
              emailShareSubject="Have you heard about this?"
              emailShareBody="Hi! I thought you might be interested in this campaign -"
              messengerShareText="Messenger"
              whatsappShareText="WhatsApp"
              facebookShareText="Facebook Post"
              twitterShareText="Twitter"
              copyText="Copy Url"
              shareModalAction={shareAction}
              showHTMLEmbed
              campaignId={campaignId}
              challengerId={challengerId}
              trackingEvent="campaign_shared"
              trackingSource={challengerId ? "team_page_sharebar" : "campaign_page_sharebar"}
              type="full-width"
            />
          </div>
      )}
        fullWidth
      />
    </Modal>
  );
};

ShareModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  shareAction: PropTypes.func.isRequired,
};

export default ShareModal;
