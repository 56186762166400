import useGetCampaign from "hooks/useGetCampaign";
import useAuthViewer from "hooks/useAuthViewer";
import React, { useMemo, useState } from "react";
import "./style.scss";
import { IconFlag } from "components/Icon";
import ImageCarousel from "components/ImageCarousel";
import CampaignHero from "components/CampaignHero";
import { useQuery } from "@apollo/client";

import { GET_FUNDRAISER } from "queries/hooks";
import differenceDateTime from "helpers/dateTime";
import LinkButton from "components/LinkButton";
import CampaignStatus from "components/CampaignStatus";

import Perks from "widgets/Perks";
import ProgressMeter from "components/ProgressMeter/ProgressMeter";
import Comments from "widgets/Comments";
import MatchedGiving from "components/MatchedGiving";
import OnboardingCompleteMultiStepModal from "components/MultiStepModal/OnboardingComplete";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import useGetLocaleTimeUnits from "hooks/useGetLocaleTimeUnits";
import ViewMoreContentWrapper from "pages/campaign/components/ViewMoreContentWrapper/ViewMoreContentWrapper";
import CampaignUpdatesWrapper from "pages/campaign/components/CampaignUpdates/CampaignUpdatesWrapper";
import CampaignSkeleton from "./components/Skeleton";
import Leaderboard from "./components/Leaderboard";
import CampaignSideBar from "./components/CampaignSideBar";
import CampaignShareBar from "./components/ShareBar";
import InfoBar from "./components/InfoBar";
import ShareModal from "./components/ShareModal";
import { getEntity } from "./helpers";
import CampaignContent from "./components/CampaignContent";
import Supporters from "./components/Supporters";
import TeamListing from "./components/TeamListing";
import useGetShareCount from "./hooks/useGetShareCount";
import useGetSupporters from "./hooks/useGetSupporters";

const route = window.location.hash.substring(1);

const CampaignPage = (props) => {
  const {
    campaignId,
    challengerId,
    paymentUrl,
    reportUrl,
    teamsLeaderboard,
    fundraiserSignup,
    fundraisersLeaderboard,
    type,
    fundraiserUserImage,
    fundraiserId,
    isCommentsEnabled,
  } = props;

  const { loading, campaign } = useGetCampaign(campaignId);
  const { fundraiser, loading: fundraiserLoading } = useGetFundraiser(challengerId, type);
  const entity = useMemo(() => getEntity(type, campaign, fundraiser), [campaign, fundraiser, type]);
  const { viewer: user, loading: loadingAuthViewer } = useAuthViewer();
  const isAdmin = useGetIsAdmin(campaign, user);
  const { translate: t } = useLanguageProvider();
  const localeTimeUnits = useGetLocaleTimeUnits();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [displayPerksContainer, setDisplayPerks] = useState(false);
  const shareCount = useGetShareCount(campaignId);
  const {
    loading: loadGetSupporters,
    supporters,
    totalSupporters,
    viewMoreSupporters,
  } = useGetSupporters(campaignId, fundraiserId, type);

  const contentLoading = loading
    || (type === "fundraiser" && fundraiserLoading)
    || loadingAuthViewer
    || loadGetSupporters;

  if (!campaign || contentLoading) {
    return <CampaignSkeleton />;
  }

  const images = type === "campaign" ? campaign.assets : fundraiser?.assets ?? [];
  const { dateString: campaignEndsTime } = differenceDateTime(
    { targetDate: campaign.duration.finishAt, units: localeTimeUnits },
  );
  const shouldShowStatus = campaign.status && campaign.status !== "Live" && !campaign.duration.isInfinityMode && isAdmin;

  const hasMultipleImages = images && images.length >= 2;
  const matchedGivingLive = campaign.matchedGivings.length > 0 && campaign.matchedGivings[0].id;
  const campaignIsActive = campaign.status === "Live" || campaign.status === "Approved" || campaign.status === "Infinity";

  const toggleShareModal = () => setShareModalOpen((isOpen) => !isOpen);

  const shareButton = (value) => (
    <LinkButton
      className="campaign-container__share-button"
      onClick={toggleShareModal}
      look="secondary-outline"
      isBold
      size="normal"
      data-test="campaign-share-button"
    >
      {value}
    </LinkButton>
  );

  const reportButton = (value) => (
    <LinkButton
      className="campaign-container__report-button"
      href={reportUrl}
      look="icon-link"
      isIcon
      isBold
      size="normal"
      data-test="campaign-report-button"
    >
      <IconFlag height="2rem" className="campaign-report-icon" />
      {" "}
      {value}
    </LinkButton>
  );

  const handleDisplayPerks = (display) => {
    setDisplayPerks(display);
  };

  const showHero = !!((images && images.length <= 1) || (entity.video && images.length <= 1));

  const CommentData = (
    <div
      className="campaign-container__comments-container"
      data-test="campaign-comments-container"
    >
      <Comments
        campaignId={campaignId}
        campaignTitle={campaign.title}
        challengerId={challengerId}
        user={user}
        campOwner={campaign.ownerName}
      />
    </div>
  );

  return (
    <section>
      <div>
        {shouldShowStatus ? (
          <div className="campaign-container__status-container --mobile">
            <CampaignStatus
              isAdmin={isAdmin}
              campaignId={campaignId}
              campaignStatus={campaign.status}
              finishDate={campaign.duration.finishAt}
            />
          </div>
        ) : null}

        {hasMultipleImages && <ImageCarousel images={images} video={entity.video} />}

        <div className="campaign-container">
          <div className="campaign-container__content">
            {showHero && (
              <CampaignHero
                imageAltText="Banner image for the campaign"
                imageHref={images[0]?.publicUrl}
                videoHref={entity.video}
                data-test="campaign-hero"
              />
            )}
            <section
              className="campaign-container__information"
              data-testid="campaign-matched-giving"
            >
              {matchedGivingLive && (
                <div className="campaign-container__matched-giving">
                  <MatchedGiving campaignId={campaignId} />
                </div>
              )}
              <h1
                className={`campaign-container__title --page-title ${
                  hasMultipleImages && "--marginTopNone"
                }`}
                data-test="campaign-title"
              >
                {entity.title}
              </h1>
              <div
                className="campaign-container__sticky-donate --mobile"
                data-test="campaign-sticky-bar-mobile"
              >
                <div className="campaign-container__animated-total">
                  <div className="campaign-container__progress-bar">
                    <ProgressMeter
                      currency={campaign.target.currency}
                      currencySymbol={campaign.target.currencyNode.symbol}
                      raised={entity.raised || 0}
                      target={+entity.target}
                    />
                  </div>
                </div>
              </div>

              <InfoBar
                type={type}
                fundraiserUserImage={fundraiserUserImage}
                campaignId={campaignId}
              />

              <CampaignShareBar
                campaign={campaign}
                totalSupporters={totalSupporters}
                shareCount={shareCount}
                shareButton={shareButton(t("Share"))}
              />

              <CampaignContent campaignId={campaignId} fundraiserId={fundraiserId} type={type} />

              <ViewMoreContentWrapper
                content={<CampaignUpdatesWrapper campaignId={campaign.id} />}
                expandText={t("View more updates")}
              />

              {campaign.donationForm.mode === "Perk" && (
                <div
                  className={`campaign-container__perk-container ${
                    displayPerksContainer ? "--display" : ""
                  }`}
                >
                  <Perks
                    campaign={campaign}
                    hasPerks={handleDisplayPerks}
                    fundraiserId={type === "fundraiser" && challengerId ? challengerId : null}
                  />
                </div>
              )}

              <Supporters
                type={type}
                totalSupporters={totalSupporters}
                owner={campaign.ownerName}
                supporters={supporters}
                viewMoreSupporters={viewMoreSupporters}
              />

              {campaign && campaign.title && isCommentsEnabled
                && <ViewMoreContentWrapper content={CommentData} expandText="View all comments" />}

              <Leaderboard
                type={type}
                fundraiserSignup={fundraiserSignup}
                teamsLeaderboard={teamsLeaderboard}
                fundraisersLeaderboard={fundraisersLeaderboard}
                campaign={campaign}
              />

              <ShareModal
                campaignId={campaignId}
                challengerId={challengerId}
                isOpen={shareModalOpen}
                shareAction={toggleShareModal}
              />

              <OnboardingCompleteMultiStepModal
                campaignId={campaignId}
                isOpen={route === "prelaunch" && campaign.status === "Approved" && isAdmin}
              />
            </section>
          </div>

          <div className="campaign-container__status-container --desktop">
            {shouldShowStatus ? (
              <CampaignStatus
                isAdmin={isAdmin}
                campaignId={campaignId}
                campaignStatus={campaign.status}
                finishDate={campaign.duration.finishAt}
              />
            ) : null}
            <div
              className="campaign-container__sticky-donate --desktop"
              data-test="campaign-sticky-bar"
            >
              <CampaignSideBar
                campaign={campaign}
                entity={entity}
                viewport="desktop"
                campaignEndsTime={campaignEndsTime}
                paymentUrl={paymentUrl}
                matchedGivingLive={matchedGivingLive}
                ownerName={entity.ownerName}
              />
            </div>
          </div>
        </div>

        {type !== "fundraiser" && <TeamListing campaignId={campaignId} />}

        <div className="campaign-container">
          <div className="report-container">
            {reportButton(t("Report campaign"))}
          </div>
        </div>

        <div
          className={
            matchedGivingLive && !campaign.matchedGivings[0].hasMetTarget
              ? "campaign-container__sticky-bar-mobile --mobile --doubleSize"
              : "campaign-container__sticky-bar-mobile --mobile"
          }
        >
          {shareButton(t("Share"))}
          <LinkButton
            href={paymentUrl}
            look="primary"
            isBold
            isDisabled={!campaignIsActive}
            size="normal"
            data-test="campaign-donate-button"
            className="campaign-container__donate-button"
          >
            {campaignIsActive ? matchedGivingLive ? t("Double your donation") : t("Donate now") : t("Donations closed")}
          </LinkButton>
        </div>
      </div>
    </section>
  );
};

export default CampaignPage;

const useGetIsAdmin = (campaign, user) => {
  if (!campaign || !user) {
    return;
  }

  if (user && user.isAdmin) {
    return true;
  }

  const team = campaign.team;
  const status = campaign.status;
  const userId = user.id;
  const campaignStatuses = ["completed", "live"];

  if (status && campaignStatuses.includes(status.toLocaleLowerCase())) {
    return true;
  }

  if (userId) {
    return userId === campaign.owner.id;
  }

  if (team && team.length > 0) {
    const isTeamMember = team.find((i) => i.id === userId);
    return isTeamMember ? isTeamMember.is_editor : false;
  }

  return false;
};

const useGetFundraiser = (challengerId, type) => {
  const skip = type !== "fundraiser";

  const [fundraiserData, setFundraiserData] = useState(null);
  const { loading, error } = useQuery(GET_FUNDRAISER, {
    variables: {
      id: challengerId,
    },
    skip,
    onCompleted(data) {
      if (data.fundraiser) {
        setFundraiserData(data.fundraiser);
      }
    },
  });
  return {
    fundraiser: fundraiserData,
    loading,
    error,
  };
};
