import { wholeMoneyFormatter } from "helpers/formatCurrency";
import { fetch as router } from "helpers/router";
import request from "utils/request";

export const fetchSupporters = async (type, campaignId, fundraiserId, offset, limit) => {
  let supporters;

  const url = (type === "campaign"
    ? `${router("api.v2.campaigns.supporters.index", { campaign: campaignId })}?limit=${limit}&offset=${offset}`
    : `${router("api.v2.fundraisers.supporters.index", { fundraiser: fundraiserId })}?limit=${limit}&offset=${offset}`
  );

  await fetch(request(url))
    .then((res) => { supporters = res.json(); })
    .catch(() => {
      console.error("Unable to get supporters");
    });

  return supporters;
};

export const getEntity = (type, campaign, fundraiser) => {
  if (type === "campaign") {
    if (!campaign) {
      return {};
    }
    return {
      raised: wholeMoneyFormatter(campaign.collected.amount),
      target: wholeMoneyFormatter(campaign.target.amount),
      video: campaign?.video[0]?.publicUrl,
      title: campaign.title,
      content: campaign.content,
      ownerName: campaign.ownerName,
    };
  }
  if (type === "fundraiser") {
    if (!fundraiser) {
      return {};
    }
    return {
      raised: wholeMoneyFormatter(fundraiser.collected.amount),
      target: wholeMoneyFormatter(fundraiser.target.amount),
      video: fundraiser.video[0]?.publicUrl,
      title: fundraiser.fundraiserName,
      content: fundraiser.content,
      ownerName: fundraiser.fundraiserName,
    };
  }
};

const fetchShares = async (campaign) => {
  const url = router("api.v2.campaigns.statistics.share", { campaign });

  return fetch(request(url))
    .then((response) => {
      if (!response.status === 200) {
        throw new Error(response.status);
      }
      return response;
    })
    .then((response) => response.json());
};

export default fetchShares;
