import React from "react";
import PropTypes from "prop-types";
import Leaderboard from "components/Leaderboard";
import LinkButton from "components/LinkButton";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const fundraiserSignupLink = (campaignId, fundraiserSignup, linkCopy) => {
  if (fundraiserSignup != 1) {
    return;
  }
  return (
    <span className="campaign-container__fundraiser-signup-button --desktop">
      <LinkButton
        href={`/project/${campaignId}/signup`}
        look="secondary-outline"
        isBold
        size="normal"
      >
        {linkCopy}
      </LinkButton>
    </span>
  );
};

const CampaignLeaderboard = ({
  type,
  fundraiserSignup,
  teamsLeaderboard,
  fundraisersLeaderboard,
  campaign,
}) => {
  const { translate: t } = useLanguageProvider();
  const linkCopy = t("Sign up as a Fundraiser");
  return (
    <>
      {type !== "fundraiser"
      && fundraiserSignup == 1
      && teamsLeaderboard === null
      && fundraisersLeaderboard === null && (
        <div className="campaign-container__fundraiser-signup --spacing --emptyFundraiser">
          <h4 className="campaign-container__fundraiser-signup-title">
            {t("Want to help :campaign reach their goal", { campaign: campaign.title })}
          </h4>
          {fundraiserSignupLink(campaign.id, fundraiserSignup, linkCopy)}
        </div>
      )}

      {type !== "fundraiser"
      && ((teamsLeaderboard && teamsLeaderboard.length > 0)
        || (fundraisersLeaderboard
          && fundraisersLeaderboard.length > 0)) && (
          <div
            className="campaign-container__team-container"
            data-test="campaign-page-leaderboards"
          >
            <span className="campaign-container__fundraiser-signup">
              <h2 className="campaign-container__fundraiser-signup-title --large">
                {t("Leaderboard")}
              </h2>

              {fundraiserSignupLink(campaign.id, fundraiserSignup, linkCopy)}

            </span>
            {teamsLeaderboard && teamsLeaderboard.length > 0 && (
            <Leaderboard
              campaignId={campaign.id}
              data={teamsLeaderboard}
              dataType="teams"
              viewMoreCopy={t("View more teams")}
              heading={t("Teams")}
              isLinkable={false}
              v2
            />
            )}

            {fundraisersLeaderboard
            && fundraisersLeaderboard.length > 0 && (
              <Leaderboard
                data={fundraisersLeaderboard}
                dataType="fundraisers"
                viewMoreCopy={t("View more fundraisers")}
                heading={t("Fundraisers")}
                isLinkable
                data-test="campaign-page-fundraiserLeaderboard"
                v2
              />
            )}
          </div>
      )}

      {type !== "fundraiser"
      && teamsLeaderboard !== null
      && fundraisersLeaderboard !== null
      && fundraiserSignup === 1 && (
        <span className="campaign-container__fundraiser-signup-button --mobile">
          {fundraiserSignupLink(campaign.id, fundraiserSignup, linkCopy)}
        </span>
      )}
    </>
  );
};

CampaignLeaderboard.propTypes = {
  type: PropTypes.string.isRequired,
  fundraiserSignup: PropTypes.any,
  teamsLeaderboard: PropTypes.any,
  fundraisersLeaderboard: PropTypes.any,
  campaign: PropTypes.object.isRequired,
};

export default CampaignLeaderboard;
