import React, { Component } from "react";

import { Carousel } from "react-responsive-carousel";
import CampaignHero from "components/CampaignHero";
import Modal from "components/Modal";
import ModalClose from "components/ModalClose";

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { getViewportType } from "helpers/imageProfile";

import "./_style.scss";

class ImageCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselModal: false,
    };
  }

  loadSlides = () => {
    this.setState({
      carouselModal: !this.state.carouselModal,
    });
  };

  loadCarouselWithVideo = () => {
    const {
      images,
      video,
    } = this.props;

    const filteredImages = images.slice(1);
    return (
      <Carousel
        showArrows
        showIndicators={false}
        showThumbs={false}
        useKeyboardArrows={false}
        selectedItem={0}
        infiniteLoop
      >
        <CampaignHero
          imageHref={images[0].publicUrl ?? images[0].cdn_url ?? images[0].url}
          videoHref={video}
          imageAltText="Banner image for the campaign"
          data-testid="image-carousel-initial-hero"
        />

        {
          filteredImages.length > 1
            ? filteredImages.map((image, index) => <img key={index} src={image.publicUrl ?? images[0].cdn_url ?? images[0].url} alt="Banner image for the campaign" />)
            : <img src={filteredImages[0].publicUrl ?? filteredImages[0].cdn_url ?? filteredImages[0].url} alt="Banner image for the campaign" />
        }
      </Carousel>
    );
  };

  loadCarousel = (showArrows = false, showThumbs = false) => {
    const {
      images,
    } = this.props;

    return (
      <Carousel
        showArrows={showArrows}
        showIndicators={false}
        showThumbs={showThumbs}
        useKeyboardArrows={false}
        selectedItem={0}
        infiniteLoop
      >
        {
          images.length > 1 && images.map((image, index) => <img key={index} src={image.publicUrl ?? image.cdn_url ?? image.url} alt="Banner image for the campaign" />)
        }
      </Carousel>
    );
  };

  render() {
    const {
      images,
      video,
    } = this.props;

    const viewport = getViewportType(window.innerWidth);

    const modalStyles = {
      content: {
        background: "none",
        maxWidth: "900px",
        overflow: "auto",
        textAlign: "left",
        width: "95%",
      },
      overlay: {
        backgroundColor: "rgba(51,51,51,.8)",
        overflowY: "auto",
        transform: "translate3d(0, 0, 0)",
        zIndex: 210,
      },
    };

    const displayArrows = true;
    const displayThumbs = true;

    return (
      <div className="image-carousel" data-testid="image-carousel">
        {
          viewport === "mobile" || viewport === "tablet"
            ? video ? this.loadCarouselWithVideo() : this.loadCarousel()
            : (
              <div>
                {
                images.length === 2
                && (
                <div className="image-carousel__initial-display">
                  <div className="image-carousel__initial-hero">
                    <CampaignHero
                      imageHref={images[0].publicUrl ?? images[0].cdn_url ?? images[0].url}
                      videoHref={video}
                      imageAltText="Banner image for the campaign"
                      data-testid="image-carousel-initial-hero"
                    />
                  </div>

                  <div className="image-carousel__initial-additional-images --colorBackground">
                    <img data-testid="image-carousel-initial-additional" src={images[1].publicUrl ?? images[1].cdn_url ?? images[1].url} alt="Banner image for the campaign" />

                    <button type="button" data-testid="image-carousel-button" className="image-carousel__button" onClick={this.loadSlides}>
                      Show all photos
                      {" "}
                      <span className="image-carousel__labels">{images.length}</span>
                    </button>
                  </div>
                </div>
                )
              }

                {
                images.length >= 3
                && (
                <div className="image-carousel__initial-display">
                  <div className="image-carousel__initial-hero">
                    <CampaignHero
                      imageHref={images[0].publicUrl ?? images[0].cdn_url ?? images[0].url}
                      videoHref={video}
                      imageAltText="Banner image for the campaign"
                      data-testid="image-carousel-initial-hero"
                    />
                  </div>

                  <div className="image-carousel__initial-additional-images">
                    <img data-testid="image-carousel-initial-additional" src={images[1].publicUrl ?? images[1].cdn_url ?? images[1].url} alt="Banner image for the campaign" />
                    <img data-testid="image-carousel-initial-additional" src={images[2].publicUrl ?? images[2].cdn_url ?? images[2].url} alt="Banner image for the campaign" />

                    <button type="button" data-testid="image-carousel-button" className="image-carousel__button" onClick={this.loadSlides}>
                      Show all photos
                      {" "}
                      <span className="image-carousel__labels">{images.length}</span>
                    </button>
                  </div>
                </div>
                )
              }

                {
                this.state.carouselModal
                && (
                <Modal
                  isOpen={this.state.carouselModal}
                  contentLabel="Campaign Images"
                  customStyle={modalStyles}
                >
                  <div>
                    <ModalClose
                      closeAction={this.loadSlides}
                      display="--secondary"
                    />

                    { this.loadCarousel(displayArrows, displayThumbs) }
                  </div>
                </Modal>
                )
              }
              </div>
            )
        }
      </div>
    );
  }
}

export default ImageCarousel;
