import React from "react";
import { imageProfileUrl } from "helpers/imageProfile";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import LinkButton from "../LinkButton";

import "./_style.scss";

const placeholderImage = "https://prod-chuffedcontent.s3.amazonaws.com/images/cUQm3dzQwWqEaWchGneK_images/tPJfj9oaQsGyQj19T6NC_perks-placeholder.png";

const validCampaignStatuses = ["approved", "live", "draft"];

export const isPerkDonationDisabled = (perk, campaignStatus, isInfinity) => {
  if (isInfinity) {
    return false;
  }

  if (campaignStatus && !validCampaignStatuses.includes(campaignStatus.toLowerCase())) {
    return true;
  }

  return !perk.is_unlimited && perk.quantity_remaining === 0;
};

export const payUrl = (perkId, fundraiserId, campaignId) =>
  (fundraiserId !== null && fundraiserId
    ? `/pay/campaign/${campaignId}/?f=${fundraiserId}&perk_id=${perkId}`
    : `/pay/campaign/${campaignId}/?perk_id=${perkId}`);

const PerkCard = ({
  isInfinity, currencySymbol, perk, fundraiserId, campaignStatus,
}) => {
  const imageUrl = imageProfileUrl(perk.imageUrl, "campaign.perk");
  const { translate: t } = useLanguageProvider();
  const perkInfo = (
    <div className="perk-card__info-container">
      <p className="perk-card__additional-info --small" data-testid="perk-card-delivery">
        {t("Est. Delivery Date: :delivery", { delivery: perk.delivery })}
      </p>
      <p className="perk-card__additional-info --small" data-testid="perk-card-redeemed">
        {t(":qty claimed", { qty: perk.quantity_redeemed })}
      </p>
      <p className="perk-card__additional-info --small" data-testid="perk-card-remaining">
        {perk.is_unlimited ? t("Unlimited") : t(":qty left", { qty: perk.quantity_remaining })}
      </p>
    </div>
  );

  const donateButton = (
    <LinkButton
      isDisabled={isPerkDonationDisabled(perk, campaignStatus, isInfinity)}
      className="perk-card__donation-button"
      look={perk.quantity_remaining > 0 || perk.is_unlimited ? "primary-outline" : "plain"}
      isBold
      size="normal"
      data-test="perk-card__cost"
      href={
        perk.quantity_remaining > 0 || perk.is_unlimited
          ? payUrl(perk.id, fundraiserId, perk.campaign_id)
          : null
      }
    >
      {perk.quantity_remaining > 0 || perk.is_unlimited
        ? t("Donate :symbol:amount", { symbol: currencySymbol, amount: perk.amount })
        : t("Perk sold out")}
    </LinkButton>
  );

  const imgSrc = !perk.useImage ? placeholderImage : imageUrl !== "" && imageUrl !== null ? imageUrl : placeholderImage;

  return (
    <div className="perk-card-container">
      <div className="perk-card">
        <div className="perk-card__body-content">
          <div className="perk-card__additional-info-container --mobile">
            {perkInfo}

            <img
              className="perk-card__image"
              src={imgSrc}
              role="presentation"
              alt={`${perk.title}`}
              data-testid="perk-card-image"
            />
          </div>

          <div className="perk-card__information-container">
            <div className="perk-card__information">
              <h3 className="perk-card__title" data-testid="perk-card-title">
                {perk.title}
              </h3>
              <h4 className="perk-card__description" data-testid="perk-card-description">
                {perk.description}
              </h4>

              <div className="perk-card__additional-info-container --desktop">{perkInfo}</div>
            </div>
          </div>
        </div>

        <div className="perk-card__button-container" data-test="perk-pay-button">
          {donateButton}
        </div>
      </div>
    </div>
  );
};

export default PerkCard;
