import PropTypes from "prop-types";
import React from "react";

import CommentForm from "components/CommentForm";
import CommentItem from "components/CommentItem";

import "./_style.scss";

const CommentList = ({
  campaignId,
  campaignTitle,
  challengerId,
  commentModalStep,
  isCommentModalOpen,
  resendEmailAction,
  sendEmailAction,
  setModalStatusAction,
  setModalStepAction,
  submitAction,
  comments = [], isCreating = false, replyToId = null, replyToName = null, user = {}, replyAction,
}) => {
  const renderComment = (comment, index, isInThread = false, parentCommentId = null) => (
    <CommentItem
      commentText={comment.content}
      id={comment.id}
      image={comment.image}
      isAnonymous={!!comment.anonymous}
      isCampaignUpdate={comment.campaign_update}
      isDisabled={isCreating}
      isInThread={isInThread}
      name={comment.full_name}
      parentCommentId={parentCommentId}
      replyAction={replyAction}
      timestamp={comment.timestamp}
    />
  );

  const renderForm = (index, isInThread = false, parentCommentId = null) => (
    <CommentForm
      campaignId={campaignId}
      campaignTitle={campaignTitle}
      challengerId={challengerId}
      commentModalStep={commentModalStep}
      formKey={`temp-reply-${index}`}
      isCommentModalOpen={isCommentModalOpen}
      isInThread={isInThread}
      isPosting={isCreating}
      parentCommentId={parentCommentId}
      replyToName={replyToName}
      resendEmailAction={resendEmailAction}
      sendEmailAction={sendEmailAction}
      setModalStatusAction={setModalStatusAction}
      setModalStepAction={setModalStepAction}
      submitAction={submitAction}
      user={user}
    />
  );

  const renderWithThread = (comment, index) => {
    const inThread = true;
    const parentCommentId = comment.id;

    return (
      <div className="comment-list__item" key={index}>
        {renderComment(comment, index, !inThread, parentCommentId)}
        <div className="comment-list__thread">
          {(comment.hasOwnProperty("replies") === true)
            && comment.replies.map((reply, replyIndex) => (
              <div className="comment-list__item" key={`${index}-${replyIndex}`}>
                {renderComment(reply, replyIndex, inThread, parentCommentId)}
              </div>
            ))}
          {(replyToId === comment.id) ? renderForm(index, inThread, parentCommentId) : null}
        </div>
      </div>
    );
  };

  return (
    <div className="comment-list">
      {(comments.length > 0)
          && comments.map((comment, index) => {
            if (comment.hasOwnProperty("replies") === true) {
              return renderWithThread(comment, index);
            }

            if (replyToId === comment.id) {
              return (
                <div key={index}>
                  <div className="comment-list__item">
                    {renderComment(comment, index, false)}
                  </div>
                  {renderForm(index, false, comment.id)}
                </div>
              );
            }

            return (
              <div className="comment-list__item" key={index}>
                {renderComment(comment, index, false)}
              </div>
            );
          })}
    </div>
  );
};

CommentList.propTypes = {
  campaignId: PropTypes.number.isRequired,
  campaignTitle: PropTypes.string.isRequired,
  challengerId: PropTypes.number,
  commentModalStep: PropTypes.oneOf(["default", "signup", "login", "existing", "thankyou"]),
  comments: PropTypes.array.isRequired,
  isCommentModalOpen: PropTypes.bool,
  isCreating: PropTypes.bool,
  replyToId: PropTypes.number,
  replyToName: PropTypes.string,
  resendEmailAction: PropTypes.func.isRequired,
  sendEmailAction: PropTypes.func.isRequired,
  setModalStatusAction: PropTypes.func.isRequired,
  setModalStepAction: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default CommentList;
