import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { filterUrl } from "helpers/filterUrl";
import useSegmentTracking from "hooks/useSegmentTracking";
import PropTypes from "prop-types";
import mapSharingContext from "helpers/getSharingContext";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import "./_style.scss";
import { IconCopy } from "components/Icon/UI";
import CopyToClipboard from "react-copy-to-clipboard";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import useGetShareContext from "hooks/useGetShareContext";
import EmbedHTML from "./components/CampaignEmbed";

const ShareButtons = (props) => {
  const [TwitterShareUrl, setTwitterShareUrl] = useState();
  const [FacebookShareUrl, setFacebookShareUrl] = useState();
  const [shareUrlsSet, setShareUrlsSet] = useState(false);
  const [copied, setCopied] = useState(false);
  const {
    heading,
    shareShow = false,
    url,
    title,
    size = 32,
    round = true,
    facebookShareText,
    twitterShareText,
    messengerShare,
    messengerShareText,
    emailShare,
    emailShareSubject,
    emailShareBody,
    emailShareText,
    whatsappShare,
    whatsappShareText,
    showCopyButton = true,
    showHTMLEmbed = false,
    copyText,
    campaignId,
    challengerId,
    trackingEvent,
    trackingSource,
    type,
  } = props;

  const { getShareUrl } = useGetShareContext();
  const shareSource = useMemo(() => mapSharingContext(trackingSource), [trackingSource]);
  const filteredUrl = filterUrl(url);
  const { translate } = useLanguageProvider();

  const setSharingUrls = useCallback(async () => {
    if (!campaignId && !challengerId) {
      setTwitterShareUrl(filteredUrl);
      setFacebookShareUrl(filteredUrl);
    }
    const variables = {
      type: challengerId ? "fundraiser" : "campaign",
      context: {
        id: challengerId ?? campaignId,
        source: shareSource,
      },
    };

    await getShareUrl({
      ...variables,
      context: {
        ...variables.context,
        socialPlatform: "Twitter",
      },
    })
      .then((url) => {
        setTwitterShareUrl(url);
      })
      .then(() =>
        getShareUrl({
          ...variables,
          context: {
            ...variables.context,
            socialPlatform: "Facebook",
          },
        }).then((url) => setFacebookShareUrl(url)));
  }, [campaignId, challengerId, filteredUrl, getShareUrl, shareSource]);

  useEffect(() => {
    if (!shareUrlsSet) {
      setSharingUrls();
      setShareUrlsSet(true);
    }
  }, [setSharingUrls, shareUrlsSet]);

  const { track } = useSegmentTracking();
  const handleTracking = useCallback(
    (medium) => {
      track(trackingEvent, null, {
        campaignId,
        filteredUrl,
        medium,
        source: trackingSource,
      });
    },
    [campaignId, filteredUrl, track, trackingEvent, trackingSource],
  );

  const shareBarContainerStyles = type === "full-width" ? "shareBar__items shareBar__items--full-width" : "shareBar__items";
  const shareBarItemStyles = type === "full-width" ? "shareBar__item shareBar__item--full-width " : "shareBar__item";

  const handleCopy = () => {
    handleTracking("URL_copy");
    setCopied(true);
  };

  return (
    <section className="shareBar">
      {heading && <h1 className="shareBar__heading">{heading}</h1>}
      {shareShow && (
        <div className="shareBar__share-count">
          <FacebookShareCount url={filteredUrl} />
          {translate(<p>Total Shares</p>)}
        </div>
      )}
      <ul className={shareBarContainerStyles}>
        <li className={shareBarItemStyles} data-test="sharebar-facebook">
          <FacebookShareButton
            resetButtonStyle={false}
            url={FacebookShareUrl}
            className={`${facebookShareText ? "has-text " : ""} shareBar__button --facebook`}
            onClick={() => handleTracking("Facebook")}
          >
            <FacebookIcon size={size} round={round} />
            {facebookShareText && (
              <span className="shareBar__shareText --facebook">{translate(facebookShareText)}</span>
            )}
          </FacebookShareButton>
        </li>
        <li className={shareBarItemStyles} data-test="sharebar-twitter">
          <TwitterShareButton
            resetButtonStyle={false}
            url={TwitterShareUrl}
            title={title}
            className={`${twitterShareText ? "has-text" : ""} shareBar__button --twitter`}
            onClick={() => handleTracking("Twitter")}
          >
            <TwitterIcon size={size} round={round} className="shareBar__icon" />
            {twitterShareText && (
              <span className="shareBar__shareText --twitter">{translate(twitterShareText)}</span>
            )}
          </TwitterShareButton>
        </li>
        {(messengerShare || messengerShareText) && (
          <li className={shareBarItemStyles} data-test="sharebar-messenger">
            <FacebookMessengerShareButton
              resetButtonStyle={false}
              url={FacebookShareUrl}
              appId={window.FB_ID}
              title={title}
              className={`${messengerShareText ? "has-text" : ""} shareBar__button --messenger`}
              onClick={() => handleTracking("Messenger")}
            >
              <FacebookMessengerIcon size={size} round={round} className="shareBar__icon" />
              {messengerShareText && (
                <span className="shareBar__shareText --messenger">
                  {translate(messengerShareText)}
                </span>
              )}
            </FacebookMessengerShareButton>
          </li>
        )}
        {(emailShare || emailShareText) && (
          <li className={shareBarItemStyles} data-test="sharebar-email">
            <EmailShareButton
              resetButtonStyle={false}
              separator=" "
              subject={emailShareSubject ?? title}
              body={`${emailShareBody}`}
              className={`${emailShareText ? "has-text" : ""} shareBar__button --email`}
              url={url}
              onClick={(_, link) => {
                window.location.href = link;
                handleTracking("Email");
              }}
            >
              <EmailIcon size={size} round={round} className="shareBar__icon" />
              {emailShareText && (
                <span className="shareBar__shareText --email">{translate(emailShareText)}</span>
              )}
            </EmailShareButton>
          </li>
        )}
        {(whatsappShare || whatsappShareText) && (
          <li className={shareBarItemStyles} data-test="sharebar-whatsapp">
            <WhatsappShareButton
              resetButtonStyle={false}
              title={title}
              separator=" "
              url={url}
              className={`${whatsappShareText ? "has-text" : ""} shareBar__button --whatsapp`}
              onClick={() => handleTracking("Whatsapp")}
            >
              <WhatsappIcon size={size} round={round} className="shareBar__icon" />
              {whatsappShareText && (
                <span className="shareBar__shareText --whatsapp">
                  {translate(whatsappShareText)}
                </span>
              )}
            </WhatsappShareButton>
          </li>
        )}
        {(showCopyButton || copyText) && (
          <li className={shareBarItemStyles} data-testid="sharebar-copyurl">
            <CopyToClipboard onCopy={() => handleCopy()} text={url}>
              <span className={`${copyText ? "has-text" : ""} shareBar__button --copy-url`}>
                <IconCopy
                  className="copy-icon shareBar__icon"
                  fillRule="evenodd"
                  viewBox="0 0 100 100"
                  height={size}
                  width={size}
                />
                {copied ? translate("Copied!") : translate(copyText)}
              </span>
            </CopyToClipboard>
          </li>
        )}
        {showHTMLEmbed && (
          <li className={shareBarItemStyles} data-testid="sharebar-embedhtml">
            <EmbedHTML campaignId={campaignId} handleTracking={handleTracking} />
          </li>
        )}
      </ul>
    </section>
  );
};

ShareButtons.propTypes = {
  heading: PropTypes.string,
  shareShow: PropTypes.bool,
  url: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.number,
  round: PropTypes.bool,
  facebookShareText: PropTypes.string,
  twitterShareText: PropTypes.string,
  emailShareText: PropTypes.string,
  whatsappShareText: PropTypes.string,
  messengerShareText: PropTypes.string,
  messengerShare: PropTypes.bool,
  emailShare: PropTypes.bool,
  whatsappShare: PropTypes.bool,
  showCopyButton: PropTypes.bool,
  copyText: PropTypes.string,
  campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackingEvent: PropTypes.string,
  trackingSource: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["default", "full-width"]),
};

export default ShareButtons;
