import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconGive, IconSecure, IconSend } from 'components/Icon/UI';
import './style.scss';
import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
const SellingPointsWell = ({ ownerName }) => {
    const { translate: t } = useLanguageProvider();
    return (_jsxs("div", { className: "selling-points", children: [_jsxs("div", { className: "selling-points__selling-point", children: [_jsx(IconSecure, { className: "icon" }), t("We don't store your card details. All donations are processed securely by our PCI-compliant payment partners, Stripe and PayPal.")] }), _jsxs("div", { className: "selling-points__selling-point", children: [_jsx(IconSend, { className: "icon send" }), t(':ownername will have quick and easy access to your donation.', { ownername: ownerName })] }), _jsxs("div", { className: "selling-points__selling-point", children: [_jsx(IconGive, { className: "icon give" }), t('We\'ve helped empower over 15,000 campaigners like :ownername by helping them raise the funds they need.', { ownername: ownerName })] })] }));
};
SellingPointsWell.defaultProps = {
    ownerName: '',
};
export default SellingPointsWell;
