import PropTypes from "prop-types";
import React, { useState } from "react";
import { format as currencyFormat } from "currency-formatter";
import { wholeMoneyFormatter } from "helpers/formatCurrency";
import { DateToHumanReadable } from "helpers/dateHelpers";
import { useQuery } from "@apollo/client";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import GET_MATCHED_GIVING from "./queries/getMatchedGiving.gql";
import Avatar from "../Avatar";
import { IconDouble } from "../Icon";

import "./_style.scss";

const MatchedGiving = ({ campaignId }) => {
  const { loading, matchedGiving } = useGetMatchedGiving(campaignId);
  const { translate: t } = useLanguageProvider();

  if (!matchedGiving) {
    return null;
  }
  const image = matchedGiving.image?.publicUrl;
  const title = matchedGiving.title ?? "";
  const date = matchedGiving.finishAt ? DateToHumanReadable(matchedGiving.finishAt) : null;
  const hasMetTarget = matchedGiving.hasMetTarget;
  const currency = matchedGiving.target?.currency;
  const target = matchedGiving.target?.amount ? wholeMoneyFormatter(matchedGiving.target?.amount) : "";
  const maximum = currencyFormat(target, {
    code: currency,
    precision: 0,
  });

  if (loading || !matchedGiving) {
    return null;
  }

  return (
    <div className="matched-giving">
      <div
        className={
            image
              ? "matched-giving__image"
              : "matched-giving__image --noImage"
          }
        data-testid="matched-giving-image"
      >
        {
            image
            && (
            <Avatar
              image={image}
              size="small"
              altText={t("Matcher Image")}
              v2
            />
            )
          }
        <span
          className={
              image
                ? "matched-giving__image-double"
                : "matched-giving__image-double --noImage"
            }
        >
          <IconDouble />
        </span>
      </div>
      <div className="matched-giving__info">
        {
            !hasMetTarget
              ? (
                <div>
                  <p
                    className="matched-giving__info-title --notification"
                    data-testid="matched-giving-title"
                  >
                    { t("Donations are being matched by :title", { title: title || t("a generous supporter") }) }
                  </p>
                  <div className="matched-giving__info-additional">
                    <p
                      className="matched-giving__additional-info --notification"
                      data-testid="matched-giving-info"
                    >
                      { t("Every amount donated or spent will be matched.") }

                      {" "}
                      {
                        (date && (target !== 0 && target !== null && target !== "")) && t("Valid until :date, or :maximum is matched.", { date, maximum })
                      }
                      {" "}
                      { (date && !target) && t("Valid until :date", { date }) }
                      { (!date && target) && t("Valid until :maximum is matched.", { maximum }) }
                    </p>
                  </div>
                </div>
              )
              : (
                <p className="matched-giving__info-title --notification">
                  { t(target !== 0 || target !== null ? "Generous donors matched :maximum in donations." : "Every amount donated or spent was matched.", { maximum }) }
                </p>
              )
          }
      </div>
    </div>
  );
};

MatchedGiving.propTypes = {
  campaignId: PropTypes.number,
};

export default MatchedGiving;

const useGetMatchedGiving = (campaignId) => {
  const [matchedGiving, setMatchedGiving] = useState(null);

  const { loading } = useQuery(GET_MATCHED_GIVING, {
    variables: {
      campaignId,
    },
    onCompleted(data) {
      if (data.campaign.matchedGivings) {
        setMatchedGiving(data.campaign.matchedGivings[0]);
      }
    },
  });
  return {
    loading,
    matchedGiving,
  };
};
