export const formatAmount = (amount, currencySymbol, fixedAmount = 0) => {
  let displayAmount = 0;

  if (amount > 0) {
    displayAmount = Number.parseFloat(amount);
  }

  const ceilAmount = Math.ceil(amount);

  if (ceilAmount === amount) {
    return `${currencySymbol}${displayAmount.toFixed(0)}`;
  }

  return `${currencySymbol}${displayAmount.toFixed(fixedAmount)}`;
};

export const formatAmountStruct = (amount) => {
  const symbol = amount.currencyNode.symbol;
  return formatCurrency(amount.amount / 100, { currencySymbol: symbol }, true);
};

export const formatLocaleString = (locale, amount, currencySymbol) => amount && `${currencySymbol}${amount.toLocaleString(locale, { maximumFractionDigits: 2 })}`;

export const wholeMoneyFormatter = (amount) => (Math.floor(Number(amount) / 100));

// Given an amount and a fee structure, return a string representation of that amount
export const formatCurrency = (amount, feeStructure, conditionalDecimal = true) => {
  const {
    currencySymbol,
  } = feeStructure;

  let displayAmount = 0;

  if (amount > 0) {
    displayAmount = Number.parseFloat(amount);
  }

  if (conditionalDecimal === true) {
    const ceilAmount = Math.ceil(amount);

    if (ceilAmount === amount) {
      return `${currencySymbol}${displayAmount.toFixed(0)}`;
    }
  }

  return `${currencySymbol}${displayAmount.toFixed(2)}`;
};

export const formatCurrencySymbol = (amount, currencySymbol) => `${currencySymbol}${amount.toFixed()}`;

const currencySymbolMap = {
  AUD: "$",
  GBP: "£",
};

export const formatCurrencyAndSymbol = (amount, currency) => formatCurrency(amount / 100, { currencySymbol: currencySymbolMap[currency] ?? "$" }, true);

export const matchAmount = (amount, feeStructure) => {
  const {
    currencySymbol,
  } = feeStructure;

  const matchAmountDoubled = amount ? parseFloat(amount.slice(1)) * 2 : null;

  return `${currencySymbol}${matchAmountDoubled.toFixed(2)}`;
};

export const formatCurrencyWithoutDecimals = (value) => (typeof (value) !== "number" ? Math.floor(Number(value)) : Math.floor(value));
