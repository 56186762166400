import { createElement, useState } from 'react';
import Context from './context';
import stringInterpolator from './utils/stringInterpolator';
import pluralization from './utils/pluralization';
import getCookie from './utils/cookies';
const locales = ['en', 'de', 'fr', 'it'];
const LanguageProvider = ({ children, locale, fallbackLocale, translations, }) => {
    const cookieLocale = getCookie('language');
    const [options, setOptions] = useState({
        ...{ locale, fallbackLocale },
        ...{ locale: cookieLocale !== null && cookieLocale !== void 0 ? cookieLocale : locale, fallbackLocale },
    });
    const isLocale = (lang) => locales.includes(lang);
    const translationsMap = translations !== null && translations !== void 0 ? translations : new Map();
    function translate(key, replacements = {}) {
        var _a, _b, _c;
        const { locale: currentLocale, fallbackLocale: currentFallbackLocale } = options;
        let message = ((_a = translationsMap.get(currentFallbackLocale)) === null || _a === void 0 ? void 0 : _a[key])
            ? translationsMap.get(currentFallbackLocale)[key]
            : key;
        if (isLocale(currentLocale)) {
            if ((_b = translationsMap.get(currentLocale)) === null || _b === void 0 ? void 0 : _b[key]) {
                message = translationsMap.get(currentLocale)[key];
            }
            else if ((_c = translationsMap.get(currentFallbackLocale)) === null || _c === void 0 ? void 0 : _c[key]) {
                message = translationsMap.get(currentFallbackLocale)[key];
            }
        }
        return stringInterpolator(message, replacements);
    }
    function setLocale(key) {
        if (locales.includes(key)) {
            setOptions((previousOptions) => ({ locale: key, fallbackLocale: previousOptions.locale }));
        }
    }
    function translateChoice(key, count, replacements = {}) {
        const message = translate(key, replacements);
        const { locale: currentLocale, fallbackLocale: currentFallbackLocale } = options;
        let localeToTranslate = currentLocale;
        if (!isLocale(currentLocale) && currentFallbackLocale) {
            localeToTranslate = currentFallbackLocale;
        }
        return stringInterpolator(pluralization(message, count, localeToTranslate), {
            ...replacements,
            count: count.toString(),
        });
    }
    function getLocale() {
        return locale !== null && locale !== void 0 ? locale : fallbackLocale;
    }
    return createElement(Context.Provider, {
        value: {
            getLocale,
            setLocale,
            translate,
            translateChoice,
        },
    }, children);
};
export default LanguageProvider;
