import PropTypes from "prop-types";
import React from "react";

import "./_style.scss";

const InformationTab = ({
  children,
  text,
  type,
}) => (
  <div
    className="information-tab"
    data-test={`information-tab-${type}`}
  >
    { children }
    <p>{ text }</p>
  </div>
);

InformationTab.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  type: PropTypes.string,
};

export default InformationTab;
