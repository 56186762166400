import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactPlayer from 'react-player';
import './_style.scss';
import useGetVideoThumb from 'pages/campaign/components/CampaignUpdates/components/VideoAsset/useGetVideoThumb';
const VideoAsset = ({ videoHref }) => {
    const [hasPlayedVideo, setHasPlayedVideo] = React.useState(false);
    const thumbnail = useGetVideoThumb(videoHref);
    const handlePlay = () => {
        setHasPlayedVideo(true);
    };
    if (!thumbnail)
        return null;
    return (_jsx("div", { className: "campaign-hero__container video", children: _jsx("div", { className: "campaign-hero__image", "data-testid": "video-asset", style: { backgroundImage: `url(${thumbnail})` }, children: hasPlayedVideo === false ? (_jsxs("button", { type: "button", className: "campaign-hero__play campaign-hero__play__v2", onClick: handlePlay, "data-testid": "video-asset-play", children: ["Play", _jsx("span", { className: "campaign-hero__play-icon" })] })) : (_jsx("div", { className: "campaign-hero__video", "data-testid": "video-asset-display", children: _jsx(ReactPlayer, { className: "campaign-hero__video-tag", controls: true, height: "100%", playing: true, width: "100%", url: videoHref }) })) }) }));
};
export default VideoAsset;
