import React from "react";
import { getViewportType, imageProfileUrl } from "helpers/imageProfile";
import useGraphQLQuery from "hooks/useGraphQLQuery";
import ViewMoreContentWrapper from "pages/campaign/components/ViewMoreContentWrapper/ViewMoreContentWrapper";
import GET_CAMPAIGN_CONTENT from "./queries/getCampaignContent.gql";
import GET_FUNDRAISER_CONTENT from "./queries/getFundraiserContent.gql";

const CampaignContent = ({ campaignId, fundraiserId, type }) => {
  const typeVariations = {
    campaign: {
      query: GET_CAMPAIGN_CONTENT,
      id: campaignId,
      dataShape: { campaign: { content } },
    },
    fundraiser: {
      query: GET_FUNDRAISER_CONTENT,
      id: fundraiserId,
      dataShape: "fundraiser.content",
    },
  };
  const variation = typeVariations[type];

  const { loading, data } = useGraphQLQuery(variation.query, { id: variation.id });

  const content = data?.campaign?.content ?? data?.fundraiser?.content;

  const viewport = getViewportType(window.innerWidth);
  const storyImagesRegex = /<img.*?src="(.*?)\.(jpg|png|webp|svg)/g;
  const Story = content
    && content.replace(storyImagesRegex, (str) => imageProfileUrl(str, `campaign.story_${viewport}`));

  if (!data || loading) {
    return null;
  }

  return <ViewMoreContentWrapper content={Story} />;
};

export default CampaignContent;
