import PropTypes from "prop-types";
import React from "react";

import ReactModal from "react-modal";

const defaultStyles = {
  content: {
    position: "absolute",
    left: "50%",
    top: "50%",
    bottom: "initial",
    transform: "translate(-50%, -50%)",
    width: "30%",
    minWidth: "300px",
    border: "none",
    background: "hsla(0,0%,93%,.79)",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "40px 20px",
    textAlign: "center",
  },
  overlay: {
    zIndex: 100,
  },
};

const Modal = (props) => {
  const customStyle = props.customStyle || {};
  const stylesToUse = {
    content: {
      ...defaultStyles.content,
      ...customStyle.content,
    },
    overlay: {
      ...defaultStyles.overlay,
      ...customStyle.overlay,
    },
  };

  return (
    <ReactModal
      {...props}
      style={stylesToUse}
      ariaHideApp={false}
    >
      {props.children}
    </ReactModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  contentLabel: PropTypes.string,
  customStyle: PropTypes.shape({
    content: PropTypes.object,
    overlay: PropTypes.object,
  }),
};

export default Modal;
