import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import "./_style.scss";

const LinkButtonGroup = ({
  children,
  spacing,
  ...other
}) => (
  <div
    className={
        classNames({
          "link-button-group": true,
          [`--spacing-${spacing}`]: spacing,
        })
      }
    {...other}
  >
    {React.Children.map(children, (child) => (
      <div className="link-button-group__item">
        {child}
      </div>
    ))}
  </div>
);

LinkButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  spacing: PropTypes.oneOf(["small", "large"]),
};

export default LinkButtonGroup;
