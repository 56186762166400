import { fetch as router } from "helpers/router";
import request from "utils/request";

export const FETCH_PERKS = "perks/LOAD";
const fetchPerks = () => ({
  type: FETCH_PERKS,
});

export const FETCH_PERKS_SUCCESS = "perks/FETCH_PERKS_SUCCESS";
const fetchPerksSuccess = (data) => ({
  type: FETCH_PERKS_SUCCESS,
  data,
});

export const FETCH_PERKS_FAILURE = "perks/FETCH_PERKS_FAILURE";
const fetchPerksFailure = (error) => ({
  type: FETCH_PERKS_FAILURE,
  error,
});

export const fetchPerksCall = (campaignId) => (dispatch) => {
  dispatch(fetchPerks(campaignId));

  const url = router("api.v2.campaigns.perks.index", { campaign: campaignId });
  return fetch(request(url))
    .then((response) => {
      if (!response.status === 200) {
        throw new Error(response.status);
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      dispatch(fetchPerksSuccess(json));
    })
    .catch(() => {
      dispatch(fetchPerksFailure("Unable to get campaign perks"));
    });
};

const initialState = {
  error: false,
  errorMessage: "",
  isLoading: false,
  perksData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PERKS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PERKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        perksData: action.data.data,
      };
    case FETCH_PERKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.data,
      };
    default:
      return state;
  }
}
