const trimUrl = (url) => (url.endsWith("/") ? url.slice(0, -1) : url);

export const filterUrl = (url) => {
  if (!url.includes("?")) {
    return trimUrl(url);
  }

  const splitUrl = url.split("?");
  const filteredUrl = splitUrl[0];

  return trimUrl(filteredUrl);
};
