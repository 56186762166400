import { useMemo, useState } from "react";
import { fetchSupporters } from "../helpers";

const useGetSupporters = (campaignId, fundraiserId, type) => {
  const [supporters, setSupporters] = useState([]);
  const [supportersMeta, setSupportersMeta] = useState([]);
  const [supportersOffset, setSupportersOffset] = useState(0);
  useMemo(
    () =>
      fetchSupporters(type, campaignId, fundraiserId, supportersOffset, 20)
        .then(
          (data) => {
            if (data.data) {
              setSupporters((previous) => ([...previous, ...data.data]));
            }
            setSupportersMeta(data.meta);
            setSupportersOffset(data.meta.offset);
          },
        ),
    [campaignId, fundraiserId, supportersOffset, type],
  );

  const viewMoreSupporters = () => {
    setSupportersOffset((currentOffset) => currentOffset + 20);
  };

  const totalSupporters = supportersMeta.total ?? 0;

  return {
    supporters,
    totalSupporters,
    viewMoreSupporters,
  };
};

export default useGetSupporters;
