import React from "react";
import PropTypes from "prop-types";

import "./_style.scss";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const errorMessage = (alwaysShowErrors, field, customMsg) => {
  if (alwaysShowErrors && customMsg) {
    return customMsg;
  }

  if (field === undefined) {
    return "";
  }

  // only show if field has been filled out with invalid data
  if (alwaysShowErrors || (typeof field === "object" && field.touched && (field.value || field.value === "") && field.invalid)) {
    return Array.isArray(field.error) ? field.error[0] : field.error;
  }
  return "";
};

const ErrorDisplay = ({
  alwaysShowErrors,
  children,
  field,
  customMsg,
  errorKey,
  ...other
}) => {
  const errorMsg = errorMessage(alwaysShowErrors, field, customMsg);
  const { translate } = useLanguageProvider();
  const append = children.type === "div" ? {} : { isInvalid: !!errorMsg };
  return (
    <div className="error-display">
      {
        React.cloneElement(children, { ...other, append })
       }
      {errorMsg
        && (
        <div className="error-display__pop-up" data-test={errorKey || "error-display"}>
          {translate(errorMsg)}
        </div>
        )}
    </div>
  );
};

ErrorDisplay.propTypes = {
  alwaysShowErrors: PropTypes.bool,
  children: PropTypes.node.isRequired,
  field: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default ErrorDisplay;
