import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import classNames from "classnames";

import { imageProfileUrl } from "helpers/imageProfile";

import { IconTeam, IconUser } from "components/Icon";
import Image from "components/Image";

import "./_style.scss";
import Skeleton from "react-loading-skeleton";

const Avatar = ({
  altText, href, image, isTeam, size, v2, ...other
}) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const Element = href ? "a" : "div";

  const imageParsed = image ? image.trim() : "";
  const hasImage = imageParsed !== "";
  const iconColor = v2 ? "#93D1EF" : "#FFF";

  const imageUrl = useMemo(() => imageProfileUrl(imageParsed, "campaign.avatar"), [imageParsed]);

  useEffect(() => {
    !hasImage && setImgLoaded(true);
  }, [hasImage]);

  return (
    <Element
      className={classNames("avatar", {
        "--no-image": !hasImage,
        "--is-team": isTeam,
        [`--size-${size}`]: size,
        "--v2": v2,
      })}
      href={href}
      style={{
        backgroundImage: hasImage ? `url("${imageUrl}")` : "",
      }}
      {...other}
    >
      {!imgLoaded && <Skeleton count={1} height="42px" circle />}
      {hasImage ? (
        <Image
          className="avatar__image"
          src={imageUrl}
          alt={altText}
          onLoad={() => setImgLoaded(true)}
        />
      ) : isTeam ? (
        <IconTeam fill={iconColor} label={altText} />
      ) : (
        <IconUser fill={iconColor} label={altText} />
      )}
    </Element>
  );
};

Avatar.propTypes = {
  altText: PropTypes.string,
  href: PropTypes.string,
  image: PropTypes.string,
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  v2: PropTypes.bool,
  isTeam: PropTypes.bool,
};

export default Avatar;
