import React from "react";
import PropTypes from "prop-types";

import "./_style.scss";

const NumberedSteps = ({
  currentStep, steps, orientation, stepCount,
}) => {
  if (!steps && stepCount) {
    steps = [...Array(stepCount).values()];
  }
  return (
    <div className={`numbered-steps ${orientation ?? "portrait"}`} role="list">
      {steps.map((stepText, i) => (
        <div className="numbered-steps__item" key={i} role="listitem">
          <div className="numbered-steps__item-container">
            <span className={`numbered-steps__item-number ${i < (currentStep - 1) ? "complete" : ""} ${i === (currentStep - 1) ? "current" : ""}`}>{++i}</span>
            <span className="numbered-steps__number-connector" />
          </div>
          <p className="numbered-steps__item-text">{stepText}</p>
        </div>
      ))}
    </div>
  );
};

NumberedSteps.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.array,
  orientation: PropTypes.string,
  stepCount: PropTypes.number,
};

export default NumberedSteps;
