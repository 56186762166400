import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import Tooltip from "react-tooltip";
import { FaInfoCircle } from "@react-icons/all-files/fa/FaInfoCircle";

import "./_style.scss";

const toolTipEvent = "ontouchstart" in document.documentElement ? "click" : "";

const InfoHelper = ({
  children, name, shouldPulse, isSecondary, ...other
}) => (
  <span
    data-tip
    data-test="info-helper"
    data-multiline
    data-for={name}
    data-event={toolTipEvent}
    data-place="top"
    style={{
      cursor: "help",
      ...other.style,
    }}
    className={
      classNames(
        "info-helper",
        other.className,
      )
    }
  >
    {shouldPulse
      && <span className="info-helper__pulse" />}
    <span>
      {
        isSecondary
          ? (
            <FaInfoCircle
              style={{ verticalAlign: "inherit", color: "#D8D8D8" }}
              size={other.size}
              color={other.color}
            />
          )
          : (
            <FaInfoCircle
              style={{ verticalAlign: "inherit" }}
              size={other.size}
              color={other.color}
            />
          )
      }
    </span>
    <Tooltip id={name} type="info" effect={toolTipEvent === "click" ? "solid" : "float"}>
      {children}
    </Tooltip>
  </span>
);

InfoHelper.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  shouldPulse: PropTypes.bool,
};

export default InfoHelper;
