import PropTypes from "prop-types";
import React from "react";

import "./_style.scss";

const ModalContent = ({
  action,
  actionText,
  body,
  errorMessage,
  heading,
  isError,
  fullWidth,
}) => (
  <div className={fullWidth ? "modal-content__full-width" : "modal-content"}>
    {heading
        && (
        <div className="modal-content__header">
          <div className="modal-content__heading">{heading}</div>
        </div>
        )}
    <div className="modal-content__body" data-test="modal-content-body">
      {(isError === true)
          && <div className="modal-content__error-message">{errorMessage}</div>}
      {body}
      {actionText
          && <div className="modal-content__action-text">{actionText}</div>}
      {action
          && (
          <div className="modal-content__action-buttons" data-test="modal-content-action-buttons">
            {action}
          </div>
          )}
    </div>
  </div>
);

ModalContent.propTypes = {
  action: PropTypes.node,
  actionText: PropTypes.string,
  body: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
  heading: PropTypes.node,
  isError: PropTypes.bool,
};

export default ModalContent;
