import { useState } from "react";

import { useQuery } from "@apollo/client";
import FETCH_VIEWER from "./queries/viewer.gql";

const useAuthViewer = () => {
  const [viewer, setViewer] = useState(null);
  const { error, loading } = useQuery(
    FETCH_VIEWER,
    {
      onCompleted(data) {
        if (data?.viewer) {
          const user = { ...data.viewer };
          user.name = `${data?.viewer.first_name} ${data.viewer?.last_name}`;
          setViewer(user);
        }
      },
    },
  );

  return { error, loading, viewer };
};

export default useAuthViewer;
