const trackingSourceToSource = {
    storybook_page_sharebuttons: 'Campaign',
    campaign_page_sharebar: 'Campaign',
    landing_page_sharebar: 'Campaign',
    onboarding_complete_share_modal: 'Campaign',
    editor_post_launch_share_modal: 'Campaign',
    editor_launch_share_modal: 'Campaign',
    donation_success_page: 'Success',
    team_page_sharebar: 'Fundraiser',
};
const mapSharingContext = (trackingSource) => {
    const source = trackingSourceToSource[`${trackingSource}`];
    if (!source) {
        throw new Error(`no source found for trackingSource "${trackingSource}"`);
    }
    return source;
};
export default mapSharingContext;
