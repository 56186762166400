import { ApolloLink, ApolloClient, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import fetch from "cross-fetch";

import { BatchHttpLink } from "@apollo/client/link/batch-http";

import { fetchAbsolute as router } from "helpers/router";

const errorLink = onError(({
  graphQLErrors, networkError, operation, forward,
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => console.error(new Error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)));
  }

  if (networkError) {
    console.error(new Error(`[Network error]: ${networkError}`));
  }

  // this will continue to load the component
  forward(operation);
});

const httpLink = new BatchHttpLink({
  uri: router("graphql"),
  fetch,
  credentials: "include",
  headers: {
    accept: "application/json",
    "content-type": "application/json",
  },
  batchMax: 5,
  batchInterval: 20,
});

const link = ApolloLink.from([
  errorLink,
  httpLink,
]);

export const clientMemoryConfig = (memoryConfig) => new ApolloClient({
  link,
  cache: new InMemoryCache(memoryConfig),
});

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
