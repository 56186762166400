import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IconPencil } from 'components/Icon';
import { DateTime } from 'luxon';
import ImageAsset from 'pages/campaign/components/CampaignUpdates/components/ImageAsset/ImageAsset';
import VideoAsset from 'pages/campaign/components/CampaignUpdates/components/VideoAsset/VideoAsset';
import { Fade } from 'react-awesome-reveal';
const TimelineUpdate = ({ update }) => {
    const { assets } = update;
    const type = update.updateType.toLowerCase();
    const updateDate = DateTime.fromISO(update.createdAt).toLocaleString({ weekday: 'short', month: 'short', day: '2-digit' });
    let renderAssets = null;
    if (type === 'image') {
        renderAssets = _jsx(ImageAsset, { images: assets });
    }
    if (type === 'video') {
        renderAssets = _jsx(VideoAsset, { videoHref: assets[0].publicUrl });
    }
    return (_jsx(Fade, { direction: "up", delay: 100, duration: 400, triggerOnce: true, children: _jsx("div", { className: "timeline__item", children: _jsxs("div", { className: "timeline-story__inner", children: [_jsx("div", { className: "timeline-story__icon", children: _jsx(IconPencil, {}) }), _jsx("div", { className: "timeline-story__date", children: updateDate }), _jsx("div", { className: "timeline-story__heading", children: update.title }), renderAssets, _jsx("div", { className: "timeline-story__content", 
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML: { __html: update.content } })] }) }) }));
};
export default TimelineUpdate;
