export function getPluralIndex(locale, number) {
    let localeToIndex = locale.replace('-', '_');
    if (localeToIndex.length > 3) {
        localeToIndex = locale.substring(0, locale.lastIndexOf('_'));
    }
    if (localeToIndex === 'fr') {
        return number === 0 || number === 1 ? 0 : 1;
    }
    return number === 1 ? 0 : 1;
}
export default getPluralIndex;
