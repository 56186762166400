import PropTypes from "prop-types";
import React, { Component } from "react";

import classNames from "classnames";

import { formatAmount } from "helpers/formatCurrency";
import Avatar from "components/Avatar";
import Button from "components/Button";

import "./_style.scss";

class Leaderboard extends Component {
  // Highlight view and ranked view for display on Team page
  static propTypes = {
    data: PropTypes.array,
    dataType: PropTypes.oneOf(["fundraisers", "teams"]),
    heading: PropTypes.node,
    isLinkable: PropTypes.bool,
  };

  state = {
    isShowingAll: this.props.data.length <= 5,
    viewableData: this.props.data.slice(0, 5),
  };

  onShowAll = () => {
    this.setState({
      isShowingAll: true,
      viewableData: this.props.data,
    });
  };

  render() {
    const {
      data,
      dataType,
      viewMoreCopy,
      campaignId,
      heading,
      isLinkable,
    } = this.props;

    const ItemElement = (isLinkable === true) ? "a" : "div";
    const twoDigitPos = data.length >= 10 && data.length <= 99;
    const threeDigitPos = data.length >= 100;

    const urlBase = dataType === "fundraisers" ? "/fundraiser/" : `${window.location.pathname}/`;

    return (
      <div
        className={
          classNames({
            leaderboard: true,
            "--is-linkable": isLinkable,
            "--2-digit-pos": twoDigitPos,
            "--3-digit-pos": threeDigitPos,
            "--v2": true,
          })
        }
      >
        {heading
          && (
          <div className="leaderboard__header --top-bottom">
            <h3 className="leaderboard__heading">{heading}</h3>
          </div>
          )}
        <div className="leaderboard__body">
          {(data && data.length === 0)
            && (
            <p className="leaderboard__empty-message">
              {dataType === "teams" ? "No teams to display. " : null}
              Sign up to become the first fundraiser for this campaign.
            </p>
            )}
          {(data && data.length > 0)
            && (
            <ol className="leaderboard__list">
              {this.state.viewableData.map((item, index) => {
                let raisedPercent = item.amountRaised / item.target;
                raisedPercent = raisedPercent > 1 ? 100 : raisedPercent * 100;

                return (
                  <li
                    className="leaderboard__list-item"
                    key={index}
                    data-test={`leaderboard-list-item-${index}`}
                  >
                    <ItemElement className="leaderboard__item-elem" href={isLinkable ? `${urlBase}${item.slug}` : null}>
                      <div className="leaderboard__item-pos">
                        {index + 1}
                      </div>
                      {
                        this.props.dataType === "teams"
                          ? (
                            <a href={`/project/${campaignId}/${item.slug}`}>
                              <div className="leaderboard__item-avatar">
                                <Avatar
                                  altText={`Photo for ${item.title}`}
                                  image={item.image}
                                  size="small"
                                  v2
                                />
                              </div>
                            </a>
                          )
                          : (
                            <div className="leaderboard__item-avatar">
                              <Avatar
                                altText={`Photo for ${item.title}`}
                                image={item.image}
                                size="small"
                                v2
                              />
                            </div>
                          )
                      }
                      <div className="leaderboard__item-body">
                        {
                          this.props.dataType === "teams"
                            ? (
                              <a href={`/project/${campaignId}/${item.slug}`}>
                                <p className="leaderboard__item-title">{item.title}</p>
                              </a>
                            )
                            : <p className="leaderboard__item-title">{item.title}</p>
                        }
                        <div className="leaderboard__item-meter">
                          <div
                            className="leaderboard__item-meter-fill"
                            style={{ width: `${raisedPercent}%` }}
                          />
                        </div>
                      </div>
                      <p className="leaderboard__item-amount">
                        {formatAmount(item.amountRaised, item.currencySymbol)}
                      </p>
                    </ItemElement>
                  </li>
                );
              })}
            </ol>
            )}
          {
            (this.state.isShowingAll === false)
            && (
            <div className="leaderboard__action --v2">
              <Button
                onClick={this.onShowAll}
                className="link-button"
                look="normal"
                isRounded
              >
                {viewMoreCopy}
              </Button>
            </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default Leaderboard;
