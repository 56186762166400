import { jsx as _jsx } from "react/jsx-runtime";
import ErrorFallback from 'components/ErrorFallback/ErrorFallback';
import useGetCampaignUpdates from 'pages/campaign/hooks/useGetCampaignUpdates';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import CampaignUpdates from './components/CampaignUpdates/CampaignUpdates';
const QUERY_FIRST = 3;
const CampaignUpdatesWrapper = ({ campaignId }) => {
    var _a, _b;
    const { metaData, updates, fetchMore, loading, error, } = useGetCampaignUpdates({
        campaignId,
    });
    const totalUpdates = metaData === null || metaData === void 0 ? void 0 : metaData.totalCount;
    const [offSet, setOffset] = useState((_a = metaData === null || metaData === void 0 ? void 0 : metaData.pageInfo) === null || _a === void 0 ? void 0 : _a.endCursor);
    const [content, setContent] = useState(updates);
    const [hasNextPage, setHasNextPage] = useState((_b = metaData === null || metaData === void 0 ? void 0 : metaData.pageInfo) === null || _b === void 0 ? void 0 : _b.hasNextPage);
    const handleLoadMore = () => {
        fetchMore({
            variables: {
                first: QUERY_FIRST,
                before: offSet,
                sort: [
                    {
                        field: 'Recent',
                        direction: 'Descending',
                    },
                ],
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                var _a;
                setOffset((_a = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.campaignUpdates.pageInfo) === null || _a === void 0 ? void 0 : _a.endCursor);
                setContent([...content, ...fetchMoreResult.campaignUpdates.edges]);
                setHasNextPage(fetchMoreResult.campaignUpdates.pageInfo.hasNextPage);
            },
        });
    };
    useEffect(() => {
        var _a, _b;
        setContent(updates);
        setHasNextPage((_a = metaData === null || metaData === void 0 ? void 0 : metaData.pageInfo) === null || _a === void 0 ? void 0 : _a.hasNextPage);
        setOffset((_b = metaData === null || metaData === void 0 ? void 0 : metaData.pageInfo) === null || _b === void 0 ? void 0 : _b.endCursor);
    }, [updates, metaData]);
    return (_jsx(ErrorBoundary, { fallback: _jsx(ErrorFallback, { error: error }), children: _jsx(CampaignUpdates, { totalUpdates: totalUpdates, updates: content, fetchMore: handleLoadMore, loading: loading, hasNextPage: hasNextPage }) }));
};
export default CampaignUpdatesWrapper;
