import PropTypes from "prop-types";
import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import "./_style.scss";

const TextareaAutosize = (props) => {
  const {
    append,
    disabled,
    rows,
    name,
    onBlur,
    className,
    placeholder,
    valid,
  } = props;
  const textareaRef = useRef(null);

  const [value, setValue] = useState();
  const textAreaChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = "0px";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [value]);

  /* eslint react/no-unknown-property: "off" */
  return (
    <textarea
      ref={textareaRef}
      append={append}
      disabled={disabled}
      rows={rows}
      name={name}
      onBlur={onBlur}
      onChange={textAreaChange}
      placeholder={placeholder}
      valid={valid}
      className={classNames(className, "textareaautosize")}
    >
      {value}
    </textarea>
  );
};

TextareaAutosize.propTypes = {
  onBlur: PropTypes.func.isRequired,
};

export default TextareaAutosize;
