import LinkButton from "components/LinkButton";
import React from "react";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const Existing = ({ isSendingLink, sendEmailHandler, handleSwitch }) => {
  const { translate: t } = useLanguageProvider();
  return (
    <div className="comment-modal__content">
      <div className="comment-modal__header">
        <div className="comment-modal__heading">{t("Hi there!")}</div>
      </div>
      <div className="comment-modal__body">
        <h3 className="text-center mb-1">{t("It looks like you're already a part of the Chuffed community.")}</h3>
        <h4>
          {t("To publish your comment you can")}
          :
        </h4>
        <div className="comment-modal__form">
          <div className="comment-modal__form-row">
            <div className="comment-modal__form-button">
              <LinkButton
                isBold
                isFullWidth
                isLoading={isSendingLink}
                look="primary"
                onClick={sendEmailHandler}
              >
                {t("Request an email link")}
              </LinkButton>
            </div>
            <div className="comment-modal__form-button">
              <LinkButton
                isBold
                isFullWidth
                look="secondary-outline"
                onClick={() => handleSwitch("login")}
              >
                {t("Login")}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Existing;
