import _ from 'lodash';
export default function stringInterpolator(message, replacements) {
    let updatedString = message;
    Object.entries(replacements || []).forEach(([key, value]) => {
        var _a, _b, _c;
        const stringValue = (_a = value === null || value === void 0 ? void 0 : value.toString()) !== null && _a !== void 0 ? _a : '';
        updatedString = (_c = updatedString
            .replaceAll(`:${key}`, stringValue)
            .replaceAll(`:${key.toUpperCase()}`, (_b = stringValue.toUpperCase()) !== null && _b !== void 0 ? _b : '')
            .replaceAll(`:${_.capitalize(key)}`, _.capitalize(stringValue))) !== null && _c !== void 0 ? _c : '';
    });
    return updatedString;
}
