import UserListing from "components/UserListing";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import GET_TEAM_MEMBERS from "./queries/getTeamMembers.gql";

const TeamListing = ({ campaignId }) => {
  const { loading, members } = useGetTeamMembers(campaignId);
  const { translate: t } = useLanguageProvider();
  if (loading || !members) {
    return null;
  }

  return (
    <div className="campaign-container__team-members campaign-container__separator --top-only">
      <h2 className="--large" data-testid="team-title">{t("Team")}</h2>
      <div className="campaign-container__team-members-item" data-testid="campaign-page-team-item">
        {members
          && members.map((member, i) => (
            <UserListing
              key={`${i}${member.name}`}
              profileImage={member.image?.publicUrl ?? null}
              fullName={member.name}
            />
          ))}
      </div>
    </div>
  );
};

TeamListing.propTypes = {
  campaignId: PropTypes.number.isRequired,
};

export default TeamListing;

const useGetTeamMembers = (campaignId) => {
  const [members, setTeamMembers] = useState(null);

  const { loading } = useQuery(GET_TEAM_MEMBERS, {
    variables: {
      campaignId,
    },
    onCompleted(data) {
      if (data.campaign.teamMembers) {
        setTeamMembers(data.campaign.teamMembers);
      }
    },
  });
  return {
    loading,
    members,
  };
};
