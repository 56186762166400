import React from "react";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const Thankyou = ({
  thankYouProps: {
    isResendingLink,
    isResendLinkFail,
    isResendLinkSent,
    pendingComment,
    resendEmailHandler,
  },
}) => {
  const { translate: t } = useLanguageProvider();
  return (
    <div className="comment-modal__content">
      <div className="comment-modal__header">
        <div className="comment-modal__heading">{t("Almost done!")}</div>
      </div>
      <div className="comment-modal__body">
        <p>
          {t("Please go to your email account and click on the link in the email. Once you do this your comment will be published for everyone to read.")}
        </p>
      </div>
      <div className="comment-modal__footer">
        {isResendingLink === false && isResendLinkFail === false && isResendLinkSent === false && (
        <div className="comment-modal__footer-action">
          {t("Didn't get the link?")}
&nbsp;
          <button
            type="button"
            className="comment-modal__footer-link"
            disabled={isResendingLink}
            onClick={() => resendEmailHandler(pendingComment.id)}
          >
            {t("Resend")}
          </button>
          &nbsp;
          {t("the email.")}
        </div>
        )}
        {isResendingLink === true && (
        <div className="comment-modal__footer-action">{t("Resending the email...")}</div>
        )}
        {isResendingLink === false && isResendLinkSent === true && (
        <div className="comment-modal__footer-action">
          {t("Email with a link to publish your comment has been resent.")}
        </div>
        )}
        {isResendingLink === false && isResendLinkFail === true && (
        <div className="comment-modal__footer-action">
          {t("There was a problem resending the email. Please reload the page and try again.")}
        </div>
        )}
      </div>
    </div>
  );
};

export default Thankyou;
