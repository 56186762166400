import { useState } from "react";
import USER_AUTH from "queries/userAuth.gql";
import LOGIN from "queries/login.gql";
import LOGOUT from "queries/logout.gql";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";

const useAuth = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUserState] = useState();
  const [error, setError] = useState();
  const { translate: t } = useLanguageProvider();

  useQuery(USER_AUTH, {
    onCompleted(completedData) {
      if (completedData?.viewer) {
        setUserState(completedData.viewer);
      } else {
        setUserState(null);
      }
      setLoading(false);
    },
  });

  const [check, { data: checkData }] = useLazyQuery(USER_AUTH, {
    onCompleted() {
      if (checkData?.viewer) {
        setUserState(checkData.viewer);
      }
      setLoading(false);
    },
  });

  const [login, { data: loginData }] = useMutation(LOGIN, {
    onCompleted() {
      if (loginData?.login) {
        setUserState(loginData.login.user);
      }
      setLoading(false);
    },
    onError(error) {
      setError(t(error.message === "validation" ? "Invalid login credentials" : "An error has occurred"));

      setLoading(false);
    },
    refetchQueries: [{
      query: USER_AUTH,
    }],
  });

  const [logout] = useMutation(LOGOUT, {
    refetchQueries: [{
      query: USER_AUTH,
    }],
  });

  return {
    checkUser: () =>
      new Promise((resolve) => {
        resolve(check());
      }),
    login: (variables) => {
      login({ variables });
    },
    signup: () => {},
    logout,
    user,
    error,
    loading,
  };
};

export default useAuth;
