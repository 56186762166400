import Avatar from "components/Avatar";
import { FaHeart } from "@react-icons/all-files/fa/FaHeart";
import { IconDeductible, IconLocationCampaign } from "components/Icon";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useGraphQLQuery from "hooks/useGraphQLQuery";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import GET_CAMPAIGN from "./queries/getInfoBarCampaignData.gql";

const InfoBar = ({ campaignId, type, fundraiserUserImage }) => {
  const { translate: t } = useLanguageProvider();
  const [campaign, setCampaign] = useState({});
  const { loading, data } = useGraphQLQuery(GET_CAMPAIGN, { id: campaignId });

  useEffect(() => {
    if (data?.campaign) {
      setCampaign(data.campaign);
    }
  }, [data]);

  if (!campaign || loading) {
    return null;
  }

  const campaignUserImage = campaign.userImage;
  const locationParts = [campaign.address?.locality ?? "", campaign.address?.region ?? "", campaign.address?.country?.name];
  const campaignLocation = locationParts.filter((part) => part !== "").join(", ");

  return (
    <div
      className="campaign-container__pitch campaign-container__separator --top"
      data-test="campaign-pitch-information"
    >
      <div className="campaign-container__pitch-owner">
        <div
          className="campaign-container__pitch-owner-avatar"
          data-test="campaign-owner-avatar"
        >
          <Avatar
            image={
            type === "fundraiser"
            && fundraiserUserImage !== null
              ? fundraiserUserImage
              : campaignUserImage
              && !campaignUserImage.includes("fadelogo.png")
                ? campaignUserImage
                : null
          }
            size="small"
            altText="User Icon"
            v2
          />
        </div>

        <div className="campaign-container__camp-owner-container">
          {type === "fundraiser" ? (
            <div className="campaign-container__camp-owner-additional-info-text">
              <FaHeart />
              {" "}
              <h5
                className="campaign-container__camp-owner --fundraiser"
                data-test="campaign-owner-name"
              >
                {t("Raising funds for")}
                {" "}
                <a href={`/project/${campaign.id}`}>
                  {campaign.title}
                </a>
              </h5>
            </div>
          ) : (
            <h5
              className="campaign-container__camp-owner"
              data-test="campaign-owner-name"
            >
              {campaign.ownerName && t("By :name", { name: campaign.ownerName })}
            </h5>
          )}

          <div className="campaign-container__camp-owner-additional-info">
            <div
              className="campaign-container__camp-owner-additional-info-text"
              data-test="campaign-owner-location"
            >
              <IconLocationCampaign />
              <p className="--notification">
                {campaignLocation}
              </p>
            </div>
            {campaign.tax && (
            <div
              className="campaign-container__camp-owner-additional-info-text"
              data-test="campaign-owner-tax-deductible"
            >
              <IconDeductible />
              <p className="--notification">
                {campaignLocation?.includes("United Kingdom")
                  ? "Donations are eligible for Gift Aid"
                  : "Donations are 100% tax deductible"}
              </p>
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

InfoBar.propTypes = {
  type: PropTypes.any,
  fundraiserUserImage: PropTypes.any,
  campaignId: PropTypes.number,
};

export default InfoBar;
