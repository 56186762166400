import { jsx as _jsx } from "react/jsx-runtime";
import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
import ViewMore from 'components/ViewMore/ViewMore';
import { useCallback, useEffect, useRef, useState, } from 'react';
import './_style.scss';
import useGetContent from 'pages/campaign/components/ViewMoreContentWrapper/useGetContent';
import sluggedText from 'pages/campaign/components/ViewMoreContentWrapper/helpers';
const defaultHeight = 700;
const ViewMoreContentWrapper = ({ content, expandText, initialHeight, }) => {
    const { translate: t } = useLanguageProvider();
    const storyInnerRef = useRef(null);
    const [showReadMore, setShowReadMore] = useState(false);
    const [storyHeightStyle, setStoryHeightStyle] = useState(initialHeight !== null && initialHeight !== void 0 ? initialHeight : 'auto');
    const parsedContent = useGetContent({
        content, expandText, height: storyHeightStyle, ref: storyInnerRef,
    });
    const handleViewMore = () => {
        setStoryHeightStyle('100%');
        setShowReadMore(false);
    };
    const handleResize = useCallback(() => {
        if (storyInnerRef.current) {
            const { scrollHeight } = storyInnerRef.current;
            if (scrollHeight > defaultHeight) {
                setStoryHeightStyle(defaultHeight);
                setShowReadMore(true);
            }
            else {
                setStoryHeightStyle('auto');
                setShowReadMore(false);
            }
        }
    }, []);
    useEffect(() => {
        setTimeout(handleResize, 500);
    }, [content, handleResize]);
    return (_jsx(ViewMore, { display: showReadMore, onClick: handleViewMore, expandText: t(expandText !== null && expandText !== void 0 ? expandText : 'Read more'), sluggedText: sluggedText(expandText !== null && expandText !== void 0 ? expandText : 'Read more'), 
        // eslint-disable-next-line react/no-children-prop
        children: (_jsx("div", { className: "campaign-container__story-container", children: parsedContent })) }));
};
export default ViewMoreContentWrapper;
