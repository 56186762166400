import moment from 'moment';
const defaultUnits = {
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    weeks: 'weeks',
    months: 'months',
    years: 'years',
};
const differenceDateTime = ({ targetDate, startDate, units = defaultUnits, }) => {
    const combinedUnits = {
        ...defaultUnits,
        ...units,
    };
    if (!targetDate)
        throw new Error('Target date is required');
    const currentDate = startDate !== null && startDate !== void 0 ? startDate : moment(new Date());
    const parsedDate = moment(targetDate);
    const isFutureDate = moment(currentDate).isBefore(moment(parsedDate));
    const minutes = Math.abs(moment(currentDate).diff(moment(parsedDate), 'minutes'));
    const years = Math.abs(moment(currentDate).diff(moment(parsedDate), 'years'));
    const weeks = Math.abs(moment(currentDate).diff(moment(parsedDate), 'weeks'));
    const months = Math.abs(moment(currentDate).diff(moment(parsedDate), 'months'));
    const days = Math.abs(moment(currentDate).diff(moment(parsedDate), 'days'));
    const hours = Math.abs(moment(currentDate).diff(moment(parsedDate), 'hours'));
    if (Number.isNaN(days) || Number.isNaN(hours) || Number.isNaN(minutes)) {
        return {
            dateString: '',
            dateCount: 0,
            dateUnit: 'days',
            isFutureDate: false,
        };
    }
    const suffixDisplay = [
        {
            time: (minuteUnit) => minuteUnit < 60,
            suffix: combinedUnits.minutes,
            display: minutes,
        },
        {
            time: (minuteUnit) => minuteUnit > 60 && minutes <= 1440,
            suffix: combinedUnits.hours,
            display: hours,
        },
        {
            time: (minuteUnit) => minuteUnit > 1440 && minutes < 43200,
            suffix: combinedUnits.days,
            display: days,
        },
        {
            time: (minuteUnit) => minuteUnit > 43200 && minutes < 86400,
            suffix: combinedUnits.weeks,
            display: weeks,
        },
        {
            time: (minuteUnit) => minuteUnit > 86400 && minutes < 525600,
            suffix: combinedUnits.months,
            display: months,
        },
        {
            time: (minuteUnit) => minuteUnit > 525600,
            suffix: combinedUnits.years,
            display: years,
        },
    ];
    const timeSuffix = suffixDisplay.filter((suf) => suf.time(minutes));
    return {
        dateString: `${timeSuffix[0].display} ${timeSuffix[0].suffix}`,
        dateCount: timeSuffix[0].display,
        dateUnit: timeSuffix[0].suffix,
        isFutureDate,
    };
};
export default differenceDateTime;
