import { modalStyleProp } from "helpers/modalStyles";
import ModalClose from "components/ModalClose";
import ModalContent from "components/ModalContent";
import LinkButton from "components/LinkButton";
import Modal from "components/Modal";
import React from "react";
import PropTypes from "prop-types";
import NumberedSteps from "components/NumberedSteps";

const PrelaunchModal = ({
  isOpen, handleClose, nextStep, currentStep, stepCount,
}) => (
  <Modal
    isOpen={isOpen}
    contentLabel="Content Label Modal"
    customStyle={modalStyleProp()}
    data-test="campaign-page-share-modal"
  >
    <ModalClose closeAction={handleClose} />
    <ModalContent
      body={(
        <>
          <NumberedSteps
            orientation="landscape"
            stepCount={stepCount}
            currentStep={currentStep}
          />
          <div className="campaign-container__modal-share --top-bottom">
            <h1>You're almost there!</h1>
            <h4 className="text-center">
              Congratulations, your campaign is ready to accept donations!
            </h4>
            <p>
              You will need to get your first donation before you can make your
              campaign publicly visible on Chuffed.org.
              {" "}
              <br />
              You can share your campaign now with your core supporters to get
              your first donation. Don't worry, you'll still be able to edit
              your campaign and take advantage of our feature-packed platform
              before you go public.
            </p>
          </div>
        </>
      )}
      fullWidth
      action={(
        <>
          <LinkButton
            href="#"
            look="primary"
            isBold
            onClick={() => nextStep(currentStep)}
          >
            Share
          </LinkButton>
          <LinkButton
            look="secondary-outline"
            isBold
            onClick={() => nextStep(currentStep + 1)}
          >
            I'll share later
          </LinkButton>
        </>
      )}
    />
  </Modal>
);

PrelaunchModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  nextStep: PropTypes.func,
  currentStep: PropTypes.number,
  stepCount: PropTypes.number,
};

export default PrelaunchModal;
