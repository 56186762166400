import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { reduxForm } from "redux-form";

import classNames from "classnames";

import Avatar from "components/Avatar";
import CommentModal from "components/CommentModal";
import ErrorDisplay from "components/ErrorDisplay";
import LinkButton from "components/LinkButton";
import TextareaAutosize from "components/TextareaAutosize";

import "./_style.scss";
import _ from "lodash";
import omit from "lodash/omit";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import Checkbox from "../Checkbox";

export const fields = ["anonymous", "comment"];

const validate = (values) => {
  const errors = {};
  // Validate comment field
  if (values.comment === undefined || values.comment.trim().length === 0) {
    errors.comment = "Comment cannot be empty";
  }

  return errors;
};

const CommentForm = ({
  isInThread = false,
  isPosting = false,
  parentCommentId = null,
  user = {},
  campaignId,
  challengerId,
  setModalStatusAction,
  campaignTitle,
  commentModalStep,
  errorMessage,
  fields: { comment, anonymous },
  handleSubmit,
  isCommentModalOpen,
  isError,
  resendEmailAction,
  sendEmailAction,
  setModalStepAction,
  submitAction,
}) => {
  const { translate: t } = useLanguageProvider();
  const onSubmit = (data) => {
    if (getUserLoggedInStatus() === true) {
      // User is logged in, submit comment
      const formData = {};
      formData.campaign_id = campaignId;
      formData.challenger_id = challengerId;
      formData.content = data.comment;
      formData.anonymous = data.anonymous;

      if (parentCommentId !== null) {
        formData.reply_to_id = parentCommentId;
      }

      submitAction(formData);
    } else {
      // Do authentication
      setModalStatusAction(true);
    }
  };

  const getUserLoggedInStatus = useCallback(() => {
    // For now, just check if name prop exists on user
    if (user !== null) {
      return user.hasOwnProperty("name");
    }
    return false;
  }, [user]);

  const getUserImage = useCallback(() => {
    if (user !== null && user.hasOwnProperty("image") === true) {
      if (user.image !== null) {
        return user.image;
      }
    }

    return "";
  }, [user]);

  const getUserName = useCallback(() => {
    if (user !== null && user.hasOwnProperty("name") === true) {
      if (user.name !== null) {
        return user.name;
      }
    }

    return "Guest";
  }, [user]);

  const userImage = getUserImage(user);
  const userName = getUserName(user);

  const commentClean = omit(
    comment,
    "autofill",
    "autofilled",
    "visited",
    "touched",
    "active",
    "pristine",
    "initialValue",
    "onUpdate",
    "valid",
    "invalid",
    "dirty",
  );

  const commentInput = _.mapValues(commentClean, (v) =>
    (v === true ? "true" : v === false ? "false" : v));
  const anonymousCommentCopy = t("Post comment anonymously");
  return (
    <div
      className={classNames({
        "comment-form": true,
        "--reply-mode": parentCommentId !== null,
        "--in-thread": isInThread === true,
        "--v2": true,
      })}
    >
      <form className="comment-form__comment" onSubmit={handleSubmit(onSubmit)}>
        <div className="comment-form__comment-media">
          <Avatar
            altText={`Commenting as ${userName}`}
            image={userImage}
            size="small"
            v2
          />
        </div>

        <div className="comment-form__comment-body">
          <ErrorDisplay
            alwaysShowErrors={
              comment && comment.touched === true && comment.invalid === true
            }
            field={commentInput}
          >
            <TextareaAutosize
              {...commentInput}
              className="comment-form__textarea"
            />
          </ErrorDisplay>
          <div className="comment-form__post-actions">
            <Checkbox
              checkedLabel={anonymousCommentCopy}
              disabled={isPosting}
              uncheckedLabel={anonymousCommentCopy}
              labelSize="regular"
              {...anonymous}
            />
            <div className="comment-form__action">
              <LinkButton
                isBold
                isLoading={isPosting}
                isUppercase
                look="primary"
                onClick={handleSubmit(onSubmit)}
                size="normal"
              >
                {parentCommentId !== null ? t("Post") : t("Post Comment")}
              </LinkButton>
            </div>
          </div>
          {isError && (<div className="comment-form__error">Unable to post your comment at this time.</div>)}
        </div>
      </form>
      <CommentModal
        campaignId={campaignId}
        challengerId={challengerId}
        campaignTitle={campaignTitle}
        commentText={comment && comment.value}
        createComment={submitAction}
        errorMessage={errorMessage}
        isError={isError}
        isPosting={isPosting}
        isModalOpen={isCommentModalOpen}
        parentCommentId={parentCommentId}
        resendEmailAction={resendEmailAction}
        sendEmailAction={sendEmailAction}
        setModalStatus={setModalStatusAction}
        setModalStep={setModalStepAction}
        step={commentModalStep}
      />
    </div>
  );
};

CommentForm.propTypes = {
  campaignId: PropTypes.number.isRequired,
  campaignTitle: PropTypes.string.isRequired,
  challengerId: PropTypes.number,
  commentModalStep: PropTypes.oneOf([
    "default",
    "signup",
    "login",
    "existing",
    "thankyou",
  ]),
  errorMessage: PropTypes.string,
  isCommentModalOpen: PropTypes.bool,
  isError: PropTypes.bool,
  isInThread: PropTypes.bool,
  isPosting: PropTypes.bool,
  parentCommentId: PropTypes.number, // Used when posting comment to API
  replyToName: PropTypes.string,
  resendEmailAction: PropTypes.func.isRequired,
  sendEmailAction: PropTypes.func.isRequired,
  setModalStatusAction: PropTypes.func.isRequired,
  setModalStepAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default reduxForm(
  {
    form: "comment",
    fields,
    validate,
  },
  (state, ownProps) => {
    if (
      ownProps.hasOwnProperty("parentCommentId") === true
      && ownProps.parentCommentId !== null
    ) {
      return {
        initialValues: {
          comment: `@${ownProps.replyToName} `,
        },
      };
    }
    return {
      initialValues: ownProps.initialValues,
    };
  },
)(CommentForm);
