import moment from 'moment';
export const DateDiffInDays = (a, b) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
};
export default DateDiffInDays;
export const DateToHumanReadable = (date) => {
    const momentObj = moment(date);
    return momentObj.format('ddd, MMM DD, YYYY h:mm A');
};
