import * as yup from "yup";

export const signupSchema = yup.object().shape({
  name: yup.string().required("Please enter your name"),
  email: yup.string().email("Please enter a valid email address").required("Please enter your email address"),
  password: yup.string().required("Please enter a password").min(6, "Password must be at least 6 characters long"),
  password_confirmation: yup.string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const loginSchema = yup.object().shape({
  email: yup.string().email("Please enter a valid email address").required("Please enter your email address"),
  password: yup.string().required("Please enter a password"),
});

export default loginSchema;
