// hook to return a video thumb from a vimeo url
import { useEffect, useState } from 'react';
const useGetVideoThumb = (videoHref) => {
    const [thumbnail, setThumbnail] = useState('');
    useEffect(() => {
        var _a;
        if (videoHref.includes('youtube') || videoHref.includes('youtu.be')) {
            const youtubeVideoId = getYoutubeVideoId(videoHref);
            setThumbnail(`https://img.youtube.com/vi/${youtubeVideoId}/hqdefault.jpg`);
        }
        if (videoHref.includes('vimeo')) {
            getVimeoThumb((_a = videoHref.split('/').pop()) !== null && _a !== void 0 ? _a : '').then((thumb) => {
                setThumbnail(thumb);
            });
        }
    }, [videoHref]);
    return thumbnail;
};
export default useGetVideoThumb;
async function getVimeoThumb(id) {
    const url = `https://vimeo.com/api/v2/video/${id}.json`;
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data[0].thumbnail_large;
    }
    catch (error) {
        console.error('Error fetching Vimeo thumbnail:', error);
        throw error;
    }
}
function getYoutubeVideoId(url) {
    const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    const urlMatch = regex.exec(url);
    if (!urlMatch) {
        return '';
    }
    return urlMatch[3];
}
